import { isDev } from '@respell/utils';

export default defineNuxtPlugin((nuxtApp) => {
  if (!isDev) {
    const { $pinia } = nuxtApp;

    const userStore = useUserStore($pinia);
    const { user } = storeToRefs(userStore);

    watch(
      user,
      () => {
        if (user.value?.email && window.$unthread) {
          window.$unthread.inAppChat('start', {
            user: {
              name: user.value?.profile.fullName,
              email: user.value?.email,
            },
          });
        }
      },
      { immediate: true },
    );
  }
});
