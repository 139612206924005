import { StepDefinition } from '../../types';

export default {
  key: 'create_article',
  name: 'Create Article',
  description: 'Creates an article.',
  icon: 'i-logos-intercom-icon',
  //documentationLink: 'https://docs.respell.ai/linear',
  category: 'integrations',
  services: ['intercom'],
  options: {
    title: {
      key: 'title',
      name: 'Title',
      description: "The article's title.",
      type: 'text/plain',
      isOptional: false,
      metadata: {
        icon: 'i-ph-subtitles-bold',
      },
      listDepth: 0,
      value: null,
    },
    description: {
      key: 'description',
      name: 'Description',
      description: 'A description of the article.',
      type: 'text/plain',
      isOptional: true,
      listDepth: 0,
      metadata: {
        icon: 'i-ph-text-align-left',
      },
      value: null,
    },
    body: {
      key: 'body',
      name: 'Body',
      description: 'The body of the article.',
      type: 'text/plain',
      isOptional: true,
      listDepth: 0,
      metadata: {
        icon: 'i-ph-text-align-left',
      },
      value: null,
    },
    author_id: {
      key: 'author_id',
      name: 'Author',
      description: 'The author of the article.',
      type: 'text/plain',
      isOptional: true,
      listDepth: 0,
      metadata: {
        options: {
          reference: {
            namespace: 'intercom',
            member: 'list_authors',
          },
        },
        icon: 'i-ph-text-align-left',
      },
      value: null,
    },
    state: {
      key: 'state',
      name: 'State',
      description: 'The state of the article.',
      type: 'text/plain',
      isOptional: true,
      listDepth: 0,
      metadata: {
        icon: 'i-ph-text-align-left',
        options: [
          { key: 'draft', name: 'Draft', id: 'draft' },
          { key: 'published', name: 'Published', id: 'published' },
        ],
      },
      value: null,
    },
  },
  outputs: {},
} satisfies StepDefinition;
