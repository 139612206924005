import { StepDefinition } from '../../types';

export default {
  key: 'reply_ticket',
  name: 'Reply Ticket',
  description: 'Replies to a ticket.',
  icon: 'i-logos-intercom-icon',
  //documentationLink: 'https://docs.respell.ai/intercom',
  category: 'integrations',
  services: ['intercom'],
  options: {
    ticket_id: {
      key: 'ticket_id',
      name: 'Ticket Id',
      description: 'The id of the ticket to reply to.',
      type: 'text/plain',
      isOptional: false,
      metadata: {
        icon: 'i-ph-text-align-left',
        options: {
          reference: {
            namespace: 'intercom',
            member: 'list_tickets',
          },
        },
      },
      listDepth: 0,
      value: null,
    },
    body: {
      key: 'body',
      name: 'Body',
      description: 'The body of the ticket response.',
      type: 'text/plain',
      isOptional: false,
      metadata: {
        icon: 'i-ph-text-align-left',
      },
      listDepth: 0,
      value: null,
    },
  },
  outputs: {},
} satisfies StepDefinition;
