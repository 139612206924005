import { StepDefinition } from '../../types';

export default {
  key: 'send_email',
  name: 'Send Email',
  description: 'Sends an email from your Gmail account.',
  icon: 'i-respell-google',
  category: 'integrations',
  services: ['gmail'],
  options: {
    to: {
      key: 'to',
      name: 'Recipient',
      description:
        'The email address of the recipient you want to send the email to.',
      type: 'text/email',
      isOptional: false,
      listDepth: 0,
      metadata: {
        icon: 'i-ph-user',
      },
      value: null,
    },
    subject: {
      key: 'subject',
      name: 'Subject',
      description: 'The subject of the email.',
      type: 'text/plain',
      isOptional: false,
      listDepth: 0,
      metadata: {
        icon: 'i-ph-text-align-left',
      },
      value: null,
    },
    body: {
      key: 'body',
      name: 'Body',
      description: 'The body (message) of the email.',
      type: 'text/plain',
      isOptional: true,
      listDepth: 0,
      metadata: {
        icon: 'i-ph-text-align-left',
      },
      value: null,
    },
    draft: {
      key: 'draft',
      name: 'Draft',
      description: 'Whether the email should be a draft rather then sent.',
      type: 'boolean',
      isOptional: false,
      listDepth: 0,
      metadata: {
        icon: 'i-ph-user',
      },
      value: true,
    },
    cc: {
      key: 'cc',
      name: 'CC Recipients',
      description:
        'The email(s) of the recipient(s) you want to CC the email to. Enter at least one.',
      type: 'text/email',
      isOptional: true,
      listDepth: 1,
      metadata: {
        icon: 'i-ph-user',
      },
      value: [],
    },
    attachments: {
      key: 'attachments',
      name: 'Attachments',
      description: 'Any documents that you would like to add to the email',
      type: 'file/document',
      isOptional: true,
      listDepth: 1,
      metadata: {
        icon: 'i-ph-user',
      },
      value: [],
    },
  },
  outputs: {
    details: {
      key: 'details',
      name: 'Sent Email Details',
      description: 'Details of the email that was created.',
      type: 'object',
      isOptional: false,
      listDepth: 0,
      metadata: {
        icon: 'i-ph-info',
        schema: {
          id: {
            key: 'id',
            name: 'Message ID',
            description: 'The ID of the email.',
            type: 'text/plain',
            isOptional: false,
            listDepth: 0,
            value: null,
          },
          subject: {
            key: 'subject',
            name: 'Subject',
            description: 'The subject of the email.',
            type: 'text/plain',
            isOptional: false,
            listDepth: 0,
            value: null,
          },
          body: {
            key: 'body',
            name: 'Email Body',
            description: 'The body content of the email.',
            type: 'text/plain',
            isOptional: false,
            listDepth: 0,
            value: null,
          },
        },
      },
      value: null,
    },
  },
} satisfies StepDefinition;
