import { StepDefinition } from '../../types';

export default {
  key: 'skyvern_run_task',
  name: 'Run Task',
  description: 'Runs a task on Skyvern',
  icon: 'i-respell-skyvern',
  category: 'integrations',
  services: ['skyvern'],
  options: {
    url: {
      key: 'url',
      name: 'Url',
      description: 'The url to the website on which this task should be run',
      type: 'text/url',
      isOptional: false,
      metadata: {
        icon: 'i-ph-subtitles-bold',
      },
      listDepth: 0,
      value: null,
    },
    navigation_goal: {
      key: 'navigation_goal',
      name: 'Navigation Goal',
      description:
        'The prompt that tells the agent what the user-facing goal is. This is the guiding light for the LLM as it navigates a particular website / sitemap to achieve this specified goal',
      type: 'text/plain',
      isOptional: false,
      metadata: {
        icon: 'i-ph-subtitles-bold',
      },
      listDepth: 0,
      value: null,
    },
    data_extraction_goal: {
      key: 'data_extraction_goal',
      name: 'Data Extraction Goal',
      description:
        'The prompt that instructs the agent to extract information once the agent has achieved its user_goal',
      type: 'text/plain',
      isOptional: true,
      metadata: {
        icon: 'i-ph-subtitles-bold',
      },
      listDepth: 0,
      value: null,
    },
    // TODO:This has user-defined schema
    navigation_payload: {
      key: 'navigation_payload',
      name: 'Navigation Payload',
      description:
        'Payload with any “facts” or information that would help the agent perform its job.',
      type: 'text/plain',
      isOptional: true,
      listDepth: 0,
      value: null,
    },
  },
  outputs: {
    details: {
      key: 'details',
      name: 'Details',
      description:
        'The details of the task (https://docs.skyvern.com/running-tasks/api-spec#response-task-details-get)',
      type: 'object',
      listDepth: 0,
      isOptional: false,
      value: null,
    },
  },
} satisfies StepDefinition;
