import { createReleaseHash } from '@respell/utils';
import { isProd, isStaging } from '@respell/utils/config';
import * as Sentry from '@sentry/vue';
import { defineNuxtPlugin } from 'nuxt/app';

export default defineNuxtPlugin((nuxtApp) => {
  if (!process.env.CLIENT_SENTRY_DSN) {
    console.log('NO DSN ', process.env);
    return;
  }

  const version = createReleaseHash('nuxt-client');
  const toast = useToast();
  const tracesSampleRate = isProd ? 0.2 : 1.0;
  const { vueApp } = nuxtApp;
  if (isProd || isStaging) {
    Sentry.init({
      app: vueApp,
      dsn: process.env.CLIENT_SENTRY_DSN,
      environment: process.env.RESPELL_ENV,
      release: version,
      maxBreadcrumbs: 50,
      integrations: [Sentry.browserTracingIntegration()],
      tracesSampleRate,
      tracePropagationTargets: [
        'https://app.respell.ai',
        'https://staging.respell.ai',
        'https://v2.respell.ai',
        'https://staging-v2.respell.ai',
      ],
      debug: isStaging,
    });
  }

  nuxtApp.vueApp.config.errorHandler = (error: any, instance, info) => {
    console.error('Error from errorHandler', error, instance, info);
    const errorResult = Sentry.captureException(error);
    if (!isProd) {
      toast.add({
        id: error.message,
        title: error.message,
        description: `ID: ${errorResult}`,
        icon: 'i-ph-warning-circle-fill',
        timeout: 0,
        closeButton: {
          icon: 'i-ph-x-bold',
          color: 'black',
          variant: undefined,
          padded: false,
          size: 'xs',
        },
        color: 'red',
      });
    }
  };

  nuxtApp.hook('vue:error', (error: any, instance, info) => {
    console.log('vue:error', error, instance, info);
    const errorResult = Sentry.captureException(error);
    if (!isProd) {
      toast.add({
        id: error.message,
        title: error.message,
        description: `ID: ${errorResult}`,
        icon: 'i-ph-warning-circle-fill',
        timeout: 0,
        closeButton: {
          icon: 'i-ph-x-bold',
          color: 'black',
          variant: undefined,
          padded: false,
          size: 'xs',
        },
        color: 'red',
      });
    }
  });
});
