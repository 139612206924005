import { StepDefinition } from '../types';

export default {
  key: 'run_spell',
  name: 'Run a Spell',
  description: 'Cast one of your spells',
  icon: 'i-respell-run-spell',
  documentationLink:
    'https://docs.respell.ai/steps-reference/flow-tools/display',
  category: 'flow',
  options: {
    childSpell: {
      key: 'childSpell',
      name: 'Spell',
      description: 'Select the spell to run',
      isOptional: false,
      type: 'text/plain',
      listDepth: 0,
      metadata: {
        isSearchable: true,
        preventInject: true,
        options: {
          reference: {
            member: 'get_manual_spells',
          },
        },
      },
      value: null,
    },
    childInputs: {
      key: 'childInputs',
      name: 'Inputs',
      description: 'Inputs of called spell',
      type: 'object',
      conditions: [
        {
          reference: 'childSpell',
          type: 'not',
          value: null,
        },
      ],
      metadata: {
        schema: {
          reference: {
            member: 'get_spell_inputs',
            arguments: [
              {
                namespace: 'options',
                member: 'childSpell',
                parameter: 'childSpell',
              },
            ],
          },
        },
      },
      listDepth: 0,
      isOptional: true,
      value: null,
    },
  },
  outputs: {
    graph: {
      key: 'graph',
      name: 'Graph',
      description: 'Graph being run',
      type: 'object',
      listDepth: 0,
      isOptional: false,
      value: null,
      isHidden: true,
    },
    run: {
      key: 'run',
      name: 'Run',
      description: 'Run generated',
      type: 'object',
      listDepth: 0,
      isOptional: false,
      value: null,
      isHidden: true,
    },
    childOutputs: {
      key: 'childOutputs',
      name: ' Outputs',
      description: 'Outputs of called spell',
      type: 'object',
      metadata: {
        schema: {
          reference: {
            member: 'get_spell_outputs',
            arguments: [
              {
                namespace: 'options',
                member: 'childSpell',
                parameter: 'childSpell',
              },
            ],
          },
        },
      },
      listDepth: 0,
      isOptional: false,
      value: null,
    },
  },
} satisfies StepDefinition;
