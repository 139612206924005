import { Integration } from '../definition';

export default {
  key: 'peopledatalabs',
  name: 'People Data Labs',
  icon: 'i-respell-peopledatalabs',
  options: [
    {
      key: 'apiKey',
      name: 'API Key',
      isOptional: false,
      type: 'text/plain',
      listDepth: 0,
      metadata: {
        mapping: 'credentials.apiKey',
      },
      value: null,
    },
  ],
  postAuthOptions: [],
} satisfies Integration;
