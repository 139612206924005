import { StepDefinition } from '../../types';

export default {
  key: 'single_send',
  name: 'Create Single Send',
  description:
    'A Single Send is a one-time nonautomated email message delivered to a list or segment of your audience. ',
  icon: 'i-respell-sendgrid',
  category: 'integrations',
  services: ['sendgrid'],
  options: {
    name: {
      key: 'name',
      name: 'Name',
      description: 'The name of the Single Send.',
      type: 'text/plain',
      isOptional: false,
      listDepth: 0,
      metadata: {
        icon: 'i-ph-info',
      },
      value: null,
    },
    list_ids: {
      key: 'list_ids',
      name: 'List IDs',
      description: 'The list IDs to send the email to.',
      type: 'text/plain',
      isOptional: true,
      listDepth: 0,
      metadata: {
        icon: 'i-ph-info',
        options: {
          reference: {
            namespace: 'sendgrid',
            member: 'get_lists',
          },
        },
      },
      conditions: [
        {
          reference: 'all',
          type: 'equals',
          value: false,
        },
      ],
      value: null,
    },
    all: {
      key: 'all',
      name: 'Send to All',
      description: 'Send the email to all contacts.',
      type: 'boolean',
      isOptional: false,
      listDepth: 0,
      metadata: {
        icon: 'i-ph-info',
      },
      value: false,
    },
    send_at: {
      key: 'send_at',
      name: 'Send At',
      description: 'The date and time to send the email.',
      type: 'datetime',
      isOptional: false,
      listDepth: 0,
      metadata: {
        icon: 'i-ph-info',
      },
      value: null,
    },
    html_content: {
      key: 'html_content',
      name: 'Content',
      description: 'The content of the email, formatted with HTML.',
      type: 'text/plain',
      isOptional: true,
      listDepth: 0,
      metadata: {
        icon: 'i-ph-info',
      },
      conditions: [
        {
          reference: 'design_id',
          type: 'equals',
          value: null,
        },
      ],
      value: null,
    },
    design_id: {
      key: 'design_id',
      name: 'Design',
      description:
        'The design to use when sending an email, all designs must have a subject set.',
      type: 'text/plain',
      isOptional: true,
      listDepth: 0,
      metadata: {
        icon: 'i-ph-info',
        options: {
          reference: {
            namespace: 'sendgrid',
            member: 'list_designs',
          },
        },
      },
      value: null,
    },
    suppression_group_id: {
      key: 'suppression_group_id',
      name: 'Suppression Group',
      description:
        'The Suppression Group used to allow recipients to unsubscribe.',
      type: 'number/integer',
      isOptional: true,
      listDepth: 0,
      metadata: {
        icon: 'i-ph-info',
        options: {
          reference: {
            namespace: 'sendgrid',
            member: 'list_supressions',
          },
        },
      },
      value: null,
    },
    subject: {
      key: 'subject',
      name: 'Subject',
      description: 'The subject of the email.',
      type: 'text/plain',
      isOptional: true,
      listDepth: 0,
      metadata: {
        icon: 'i-ph-info',
      },
      conditions: [
        {
          reference: 'design_id',
          type: 'equals',
          value: null,
        },
      ],
      value: null,
    },
    sender_id: {
      key: 'sender_id',
      name: 'Sender ID',
      description: 'The sender ID of the email.',
      type: 'number/integer',
      isOptional: false,
      listDepth: 0,
      metadata: {
        icon: 'i-ph-info',
        options: {
          reference: {
            namespace: 'sendgrid',
            member: 'list_senders',
          },
        },
      },
      value: null,
    },
  },
  outputs: {
    details: {
      key: 'details',
      name: 'Sent Email Details',
      description: ' Details of the email that was sent.',
      type: 'object',
      isOptional: false,
      listDepth: 0,
      metadata: {
        icon: 'i-ph-info',
      },
      value: null,
    },
  },
} satisfies StepDefinition;
