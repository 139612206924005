import { TriggerDefinition } from '../../../definition';

export default {
  name: 'New Company',
  key: 'new_hubspot_company',
  description: 'A new company is created in HubSpot.',
  icon: 'i-respell-hubspot',
  options: {},
  outputs: {
    company: {
      key: 'company',
      name: 'HubSpot Company',
      type: 'object',
      isOptional: false,
      listDepth: 0,
      metadata: {
        schema: {
          reference: {
            namespace: 'hubspot',
            member: 'get_properties_company_hook',
          },
        },
      },
      value: null,
    },
  },
} satisfies TriggerDefinition;
