import { StepDefinition } from '../../types';

export default {
  key: 'display',
  name: 'Display Output',
  description:
    "Display a variable's value on the spell page after running this spell.",
  icon: 'i-respell-display',
  documentationLink:
    'https://docs.respell.ai/steps-reference/flow-tools/display',
  category: 'flow',
  options: {
    references: {
      key: 'references',
      name: 'References',
      description:
        'List of the keys of outputs that should be shown from this step.',
      listDepth: 1,
      type: 'text/plain',
      isOptional: false,
      value: [],
    },
  },
  outputs: {},
} satisfies StepDefinition;
