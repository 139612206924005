<script setup lang="ts">
import type { GraphRun } from '@respell/database';
import type { Json } from '@respell/utils';
import { formatDistanceToNow } from 'date-fns';
import { createOptionSchema } from '~/util/validation';
import AppInput from './AppInput.vue';

const props = defineProps<{
  item: GraphRun;
  type: 'todo' | 'completed';
  handleReject: (id: string) => void;
  handleResume: (graphRun: GraphRun) => void;
  closeModal?: () => void;
}>();

const isApproving = ref(false);
const isRejecting = ref(false);

const approveItem = async () => {
  isApproving.value = true;
  await props.handleResume(props.item);
  isApproving.value = false;
};

const rejectItem = async () => {
  isRejecting.value = true;
  await props.handleReject(props.item.id);
  isRejecting.value = false;
};
</script>
<template>
  <UCard
    class="w-full max-w-5xl"
    :ui="{
      rounded: 'rounded-2xl',
      divide: 'divide-y-0',
      width: 'max-w-5xl',
      header: {
        base: 'flex flex-col items-start justify-between',
        padding: '!pb-0',
      },
      body: {
        base: 'flex flex-col gap-8 items-start',
      },
      footer: {
        base: 'w-full flex justify-end gap-4 !pt-0',
      },
    }"
  >
    <template #header>
      <div v-if="closeModal" class="flex justify-between w-full">
        <p class="title capitalize">For Review:</p>
        <UButton
          :padded="false"
          color="gray"
          variant="link"
          icon="i-ph-x"
          @click="closeModal"
        />
      </div>
      <div v-else class="flex justify-between w-full">
        <span class="flex gap-2">
          <AppSpellIcon :spell="item.spell" />
          <p class="title truncate">{{ item.spell?.name }}</p>
          <UBadge
            v-if="type === 'completed'"
            variant="subtle"
            :color="item.page.status === 'approved' ? 'primary' : 'red'"
          >
            <p class="capitalize">
              {{ item.page.status }}
            </p>
          </UBadge>
        </span>
        <span class="flex gap-2">
          <UIcon name="i-ph-clock" class="dimmed" />
          <p class="dimmed">{{ formatDistanceToNow(item.updatedAt) }} ago</p>
        </span>
      </div>
    </template>

    <p v-if="item.page?.instruction" class="body dimmed">
      {{ item.page.instruction }}
    </p>

    <!-- REVIEW ITEMS -->
    <UFormGroup
      v-for="reference in item.page?.outputs"
      :key="reference"
      :description="item.graph.outputs[reference].description"
      class="w-full"
      size="xl"
      :ui="{
        inner: 'mb-4',
      }"
    >
      <template #label>
        <span class="flex gap-2">
          <span class="bg-primary-200 rounded-full flex p-1.5">
            <UIcon
              name="i-ph-check-circle-fill"
              class="text-primary-500 text-large"
            />
          </span>
          {{ item.graph.outputs[reference]?.name }}
        </span>
      </template>
      <AppInput
        :model-value="item.outputs[reference]"
        :option="item.graph.outputs[reference]"
        owner-type="GraphRun"
        :owner-id="item.id"
        readonly
        hide-label
      />
    </UFormGroup>
    <!-- ADDITIONAL INFO -->
    <div v-if="type === 'completed'" class="contents">
      <!-- READONLY -->
      <UFormGroup
        v-for="reference in item.page?.inputs"
        :key="reference"
        :description="item.graph?.inputs[reference].description"
        class="w-full"
        size="xl"
        :ui="{
          inner: 'mb-4',
        }"
      >
        <template #label>
          <span class="flex gap-2">
            <span class="bg-primary-200 rounded-full flex p-1.5">
              <UIcon
                name="i-ph-check-circle-fill"
                class="text-primary-500 text-large"
              />
            </span>
            {{ item.graph?.inputs[reference]?.name }}
          </span>
        </template>
        <AppInput
          :model-value="item.inputs[reference]"
          :option="item.graph?.inputs[reference]"
          owner-type="GraphRun"
          :owner-id="item.id"
          readonly
          hide-label
        />
      </UFormGroup>
    </div>
    <!-- EDITABLE -->
    <UForm
      v-else
      :schema="
        createOptionSchema(
          Object.fromEntries(
            Object.entries(item.graph?.inputs).filter(([key]) =>
              item.page?.inputs.includes(key),
            ),
          ) as Json,
        )
      "
      :state="item.outputs"
      class="contents"
    >
      <AppInput
        v-for="reference in item.page?.inputs"
        :key="reference"
        v-model="item.outputs[reference]"
        owner-type="GraphRun"
        :owner-id="item.id"
        :option="item.graph?.inputs[reference]"
      >
        <template #label>
          <span class="flex gap-2">
            <span class="bg-primary-200 rounded-full flex p-1.5">
              <UIcon
                name="i-ph-pencil-simple-line-fill"
                class="text-primary-500 text-large"
              />
            </span>
            {{ item.graph.inputs[reference]?.name }}
          </span>
        </template>
      </AppInput>
    </UForm>

    <template v-if="type === 'todo'" #footer>
      <UButton
        color="white"
        variant="solid"
        size="md"
        :loading="isRejecting"
        label="Reject"
        icon="i-ph-x-circle"
        @click="rejectItem"
      />
      <UButton
        color="primary"
        variant="solid"
        size="md"
        :loading="isApproving"
        label="Accept"
        icon="i-ph-play-circle-bold"
        @click="approveItem"
      />
    </template>
  </UCard>
</template>
