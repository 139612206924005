import { TicketModel } from '@respell/integrations/zendesk/models';

import { StepDefinition } from '../../types';
export default {
  key: 'reply_ticket_zendesk',
  name: 'Reply To Ticket',
  description: 'Replies to a Zendesk ticket.',
  icon: 'i-respell-zendesk',
  //documentationLink: 'https://docs.respell.ai/apollo',
  category: 'integrations',
  services: ['zendesk'],
  options: {
    ticketId: {
      key: 'ticketId',
      name: 'Ticket Id',
      description: 'The id of the ticket you want to reply to. ',
      type: 'number/integer',
      listDepth: 0,
      value: null,
      isOptional: false,
    },
    body: {
      key: 'body',
      name: 'Body',
      description: 'The body of the reply to send.',
      type: 'text/plain',
      metadata: {
        isMultiline: true,
      },
      listDepth: 0,
      value: null,
      isOptional: false,
    },
  },
  outputs: {
    ticket: {
      key: 'ticket',
      name: 'Ticket Details',
      description: 'Details of the ticket.',
      type: 'object',
      isOptional: false,
      listDepth: 0,
      metadata: {
        schema: TicketModel.schema,
      },
      value: null,
    },
  },
} satisfies StepDefinition;
