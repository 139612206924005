import { StepDefinition } from '../../types';

export default {
  key: 'search_calendar',
  name: 'Search Calendar',
  description: 'Searches your Google Calendar for events.',
  icon: 'i-respell-google',
  category: 'integrations',
  services: ['google-calendar'],
  options: {
    query: {
      key: 'query',
      name: 'Query',
      description:
        'Search term to query events for. Will search for events with titles, descriptions, or invitees matching the term. Functions the same as the search bar in the Google Calendar web application.',
      isOptional: false,
      type: 'text/plain',
      listDepth: 0,
      value: null,
    },
  },
  outputs: {
    event_results: {
      key: 'event_results',
      name: 'Event Results',
      description: 'Details of the calendar events matching the query.',
      isOptional: false,
      type: 'object',
      listDepth: 0,
      value: null,
    },
  },
} satisfies StepDefinition;
