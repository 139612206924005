import { StepDefinition } from '../../types';

export default {
  key: 'create_ticket_zendesk',
  name: 'Create Ticket',
  description: 'Creates a Zendesk ticket.',
  icon: 'i-respell-zendesk',
  //documentationLink: 'https://docs.respell.ai/apollo',
  category: 'integrations',
  services: ['zendesk'],
  options: {
    assignee_email: {
      key: 'assignee_email',
      name: 'Assignee Email',
      description: 'The email address of the agent to assign the ticket to',
      type: 'text/plain',
      listDepth: 0,
      value: null,
      isOptional: true,
    },
    description: {
      key: 'description',
      name: 'Comment',
      description: 'The comment (description) of the ticket.',
      type: 'text/plain',
      listDepth: 0,
      value: null,
      isOptional: false,
    },
    due_at: {
      key: 'due_at',
      name: 'Due At',
      description: 'If this is a ticket of type "task" it has a due date.',
      type: 'date',
      listDepth: 0,
      conditions: [
        {
          reference: 'type',
          type: 'equals',
          value: 'task',
        },
      ],
      value: null,
      isOptional: true,
    },
    priority: {
      key: 'priority',
      name: 'Priority',
      description: 'The priority level of the ticket.',
      type: 'text/plain',
      metadata: {
        options: [
          {
            key: 'urgent',
            id: 'urgent',
            name: 'Urgent',
          },
          {
            key: 'high',
            id: 'high',
            name: 'High',
          },
          {
            key: 'normal',
            id: 'normal',
            name: 'Normal',
          },
          {
            id: 'low',
            key: 'low',
            name: 'Low',
          },
        ],
      },
      listDepth: 0,
      value: null,
      isOptional: true,
    },
    subject: {
      key: 'subject',
      name: 'Subject',
      description: 'The subject (title) of the ticket.',
      type: 'text/plain',
      listDepth: 0,
      value: null,
      isOptional: true,
    },
    type: {
      key: 'type',
      name: 'Type',
      description: 'The type of this ticket.',
      type: 'text/plain',
      listDepth: 0,
      metadata: {
        options: [
          {
            id: 'problem',
            key: 'problem',
            name: 'Problem',
          },
          {
            id: 'incident',
            key: 'incident',
            name: 'Incident',
          },
          {
            id: 'question',
            key: 'question',
            name: 'Question',
          },
          {
            id: 'task',
            key: 'task',
            name: 'Task',
          },
        ],
      },
      value: null,
      isOptional: true,
    },
  },
  outputs: {},
} satisfies StepDefinition;
