import { StepDefinition } from '../../types';

export default {
  key: 'add_label',
  name: 'Add Label',
  description: 'Adds a label to an email in your Gmail account.',
  icon: 'i-respell-google',
  category: 'integrations',
  services: ['gmail'],
  options: {
    label: {
      key: 'label',
      name: 'Label',
      description: 'The label you want to add to the email.',
      type: 'text/plain',
      isOptional: false,
      listDepth: 0,
      metadata: {
        icon: 'i-ph-text-align-left',
        options: {
          reference: {
            namespace: 'gmail',
            member: 'list_labels',
          },
        },
      },
      value: null,
    },
    to: {
      key: 'to',
      name: 'Message Id',
      description: 'The message Id you want to add the label to.',
      type: 'text/plain',
      isOptional: false,
      listDepth: 0,
      metadata: {
        icon: 'i-ph-user',
      },
      value: null,
    },
  },
  outputs: {},
} satisfies StepDefinition;
