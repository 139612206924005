<template>
  <node-view-wrapper class="relative">
    <USelectMenu
      v-model="selectedLanguage"
      :options="languages"
      class="select"
      size="2xs"
      :ui="{ wrapper: 'absolute bottom-2 right-1', base: 'w-24' }"
    />
    <pre><code><node-view-content /></code></pre>
  </node-view-wrapper>
</template>

<script setup>
import { NodeViewContent, NodeViewWrapper, nodeViewProps } from '@tiptap/vue-3';

const props = defineProps(nodeViewProps);

const languages = ref(props.extension.options.lowlight.listLanguages());

const selectedLanguage = computed({
  get() {
    return props.node.attrs.language;
  },
  set(language) {
    props.updateAttributes({ language });
  },
});
</script>
