import type { StepDefinition } from '@respell/steps/types';
import type { Json } from '@respell/utils';
import { isValid } from 'date-fns';
import {
  ParseError,
  parsePhoneNumberWithError,
  type PhoneNumber,
} from 'libphonenumber-js';
import { z } from 'zod';

const phoneErrorMessages: Record<string, string> = {
  NOT_A_NUMBER: 'Not a number',
  INVALID_COUNTRY: 'Not a valid country code',
  TOO_SHORT: 'Input too short',
  TOO_LONG: 'Input too long',
};

const phoneSchema = z.string().transform((value, ctx) => {
  let phoneNumber: PhoneNumber;

  try {
    phoneNumber = parsePhoneNumberWithError(value, {
      /**
       * @defaultCountry: string
       * Default country for parsing numbers written in non-international form (without a + sign).
       * Will be ignored when parsing numbers written in international form (with a + sign).
       */
      defaultCountry: 'US',
    });
  } catch (error) {
    if (error instanceof ParseError) {
      // Not a phone number, non-existent country, etc.
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: phoneErrorMessages[error.message] || 'Invalid input.',
      });

      return z.NEVER;
    } else {
      throw error;
    }
  }

  if (!phoneNumber?.isValid()) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'Invalid phone number',
    });
    return z.NEVER;
  }

  return phoneNumber.number; // +15551234567
});

const typeSchemas = {
  'text/plain': z.string({ message: 'Invalid string input' }),
  'text/email': z.string().email({ message: 'Invalid email address' }),
  'text/phone-number': phoneSchema,
  'text/url': z
    .string()
    .regex(
      /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/,
      {
        message: 'Invalid url',
      },
    ),
  code: z.object({
    language: z.string({ message: 'A language must be set' }),
    content: z.string(),
  }),
  'number/integer': z.number().int({ message: 'Invalid integer' }),
  'number/decimal': z.number({ message: 'Invalid decimal' }),
  boolean: z.boolean({ message: 'Invalid boolean' }),
  datetime: z.any().refine((value) => isValid(value), {
    message: 'Invalid datetime',
  }),
  date: z.any().refine((value) => isValid(value), {
    message: 'Invalid date',
  }),
  time: z.any().refine((value) => isValid(value), {
    message: 'Invalid time',
  }),
  'file/document': z.any(),
  'file/spreadsheet': z.any(),
  'file/audio': z.any(),
  'file/image': z.any(),
  'file/video': z.any(),
  condition: z.any(),
};

const validateVariable = (schema: z.Schema) => {
  return schema.superRefine((val, ctx) => {
    if (variableRegex.test(val)) {
      const canvasStore = useCanvasStore();
      const { variable } = canvasStore.parseVariableId(val);
      if (variable?.key === 'missing') {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Invalid variable',
        });
      }
    }
  });
};

// Export a function to create a schema based on options
export const createOptionSchema = (options: Json | StepDefinition) => {
  const form: Record<string, z.Schema> = {};

  if (options) {
    Object.entries(options).forEach(([key, value]) => {
      let schema;

      if (value.metadata?.schema && value.metadata?.options) {
        // TODO: Handle lookup schemas
        schema = z.any().optional();
      } else if (value.type === 'object') {
        if (value.metadata?.schema) {
          if ('reference' in value.metadata.schema) {
            // TODO: Handle dynamic schemas
            schema = z.any().optional();
          } else {
            schema = createOptionSchema(value.metadata.schema);
          }
        } else {
          // If no schema, only permit injected variable
          schema = z.string().regex(globalVariableRegex);
        }
      } else {
        // Create a base schema that checks for variable or applies type-specific validation
        schema = z.union([
          validateVariable(z.string().regex(globalVariableRegex)),
          typeSchemas[value.type] || z.any(),
        ]);
      }

      // Wrap the schema to enforce required fields
      if (value.isOptional || value.type === 'boolean') {
        schema = schema.optional().nullable();
      } else {
        schema = schema.refine(
          (val) => val !== null && val !== undefined && val !== '',
          {
            message: 'This field is required',
          },
        );
      }

      // Handle list depth
      if (value.listDepth > 0) {
        let arraySchema = schema;
        for (let i = 0; i < value.listDepth; i++) {
          arraySchema = arraySchema.array();
          if (!value.isOptional) {
            arraySchema = arraySchema.nonempty({
              message: 'List cannot be empty',
            });
          } else {
            arraySchema = arraySchema.optional().nullable();
          }
        }

        // Create a union of the array schema and the variable regex
        schema = z.union([
          arraySchema,
          validateVariable(z.string().regex(globalVariableRegex)),
        ]);
      }

      form[key] = schema;
    });
  }
  return z.object(form);
};

export const validateOptions = (options: JSON | StepDefinition, data: JSON) => {
  const schema = createOptionSchema(options);
  return schema.safeParse(data);
};
