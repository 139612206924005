import { StepDefinition } from '../../types';

export default {
  key: 'send_mail_mailgun',
  name: 'Send Mail',
  description: 'Sends an email from your Mailgun account.',
  icon: 'i-respell-mailgun',
  category: 'integrations',
  services: ['mailgun'],
  options: {
    domain: {
      key: 'domain',
      name: 'Domain',
      description: 'The domain of the article.',
      type: 'text/plain',
      isOptional: true,
      listDepth: 0,
      metadata: {
        icon: 'i-ph-text-align-left',
        options: {
          reference: {
            namespace: 'mailgun',
            member: 'list_domains',
          },
        },
      },
      value: null,
    },
    body: {
      key: 'body',
      name: 'Body',
      description: 'The body (message) of the email.',
      type: 'text/plain',
      isOptional: true,
      listDepth: 0,
      metadata: {
        icon: 'i-ph-text-align-left',
      },
      value: null,
    },
    from_name: {
      key: 'from_name',
      name: 'From Name',
      description: 'The name of the sender of the email.',
      type: 'text/plain',
      isOptional: false,
      listDepth: 0,
      value: null,
    },
    from_email: {
      key: 'from_email',
      name: 'From Email',
      description: 'The email of the sender of the email.',
      type: 'text/email',
      isOptional: false,
      listDepth: 0,
      value: null,
    },
    subject: {
      key: 'subject',
      name: 'Subject',
      description: 'The subject of the email.',
      type: 'text/plain',
      isOptional: false,
      listDepth: 0,
      metadata: {
        icon: 'i-ph-text-align-left',
      },
      value: null,
    },
    to: {
      key: 'to',
      name: 'Recipient',
      description: 'The email of the recipient you want to send the email to.',
      type: 'text/email',
      isOptional: false,
      listDepth: 0,
      metadata: {
        icon: 'i-ph-user',
      },
      value: null,
    },
    cc: {
      key: 'cc',
      name: 'CC Recipients',
      description:
        'The email(s) of the recipient(s) you want to CC the email to. Enter at least one.',
      type: 'text/email',
      isOptional: true,
      listDepth: 1,
      metadata: {
        icon: 'i-ph-user',
      },
      value: [],
    },
  },
  outputs: {
    details: {
      key: 'details',
      name: 'Sent Email Details',
      description: ' Details of the email that was sent.',
      type: 'object',
      isOptional: false,
      listDepth: 0,
      metadata: {
        icon: 'i-ph-info',
      },
      value: null,
    },
  },
} satisfies StepDefinition;
