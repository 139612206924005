import { StepDefinition } from '../../types';

export default {
  key: 'add_contact_mailgun',
  name: 'Add Contact To Campaign',
  description: 'Adds a contact to a Mailgun list',
  icon: 'i-respell-mailgun',
  category: 'integrations',
  services: ['mailgun'],
  options: {
    // domain: {
    //   key: 'domain',
    //   name: 'Domain',
    //   description: "The domain of the article.",
    //   type: 'text/plain',
    //   isOptional: true,
    //   listDepth: 0,
    //   metadata: {
    //     icon: 'i-ph-text-align-left',
    //     options: {
    //       reference: {
    //         namespace: 'mailgun',
    //         member: 'list_domains'
    //       }
    //     }
    //   },
    //   value: null,
    // },
    campaign_id: {
      key: 'campaign_id',
      name: 'List Name',
      description: 'The name of the list you want to add this email to.',
      type: 'text/plain',
      isOptional: true,
      listDepth: 0,
      metadata: {
        icon: 'i-ph-text-align-left',
        options: {
          reference: {
            namespace: 'mailgun',
            member: 'list_lists',
          },
        },
      },
      value: null,
    },

    name: {
      key: 'name',
      name: 'Name',
      description: 'The name of contact to be added to the list.',
      type: 'text/plain',
      isOptional: false,
      listDepth: 0,
      value: null,
    },
    email: {
      key: 'email',
      name: 'Email',
      description: 'The email of the contact to be added to the list.',
      type: 'text/email',
      isOptional: false,
      listDepth: 0,
      value: null,
    },
  },
  outputs: {},
} satisfies StepDefinition;
