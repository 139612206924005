import { StepDefinition } from '../../types';

export default {
  key: 'update_contact_highlevel',
  name: 'Update Contact',
  description: 'Updates a GoHighLevel Contact',
  icon: 'i-respell-highlevel',
  category: 'integrations',
  services: ['highlevel'],
  options: {
    contactId: {
      name: 'Contact ID',
      type: 'text/plain',
      listDepth: 0,
      isOptional: false,
      value: null,
      key: 'contactId',
    },
    firstName: {
      name: 'First Name',
      type: 'text/plain',
      listDepth: 0,
      isOptional: true,
      value: null,
      key: 'firstName',
    },
    lastName: {
      name: 'Last Name',
      type: 'text/plain',
      listDepth: 0,
      isOptional: true,
      value: null,
      key: 'lastName',
    },
    email: {
      name: 'Email',
      type: 'text/plain',
      listDepth: 0,
      isOptional: true,
      value: null,
      key: 'email',
    },
    locationId: {
      name: 'Location ID',
      type: 'text/plain',
      listDepth: 0,
      metadata: {
        isSearchable: true,
        options: {
          reference: {
            namespace: 'highlevel',
            member: 'search_locations',
            arguments: [
              {
                namespace: 'options',
                member: 'query',
                parameter: 'query',
              },
            ],
          },
        },
      },
      isOptional: false,
      value: null,
      key: 'locationId',
    },
    gender: {
      name: 'Gender',
      type: 'text/plain',
      listDepth: 0,
      isOptional: true,
      value: null,
      key: 'gender',
    },
    phone: {
      name: 'Phone',
      type: 'text/plain',
      listDepth: 0,
      isOptional: false,
      value: null,
      key: 'phone',
    },
    address1: {
      name: 'Address',
      type: 'text/plain',
      listDepth: 0,
      isOptional: true,
      value: null,
      key: 'address1',
    },
    city: {
      name: 'City',
      type: 'text/plain',
      listDepth: 0,
      isOptional: true,
      value: null,
      key: 'city',
    },
    state: {
      name: 'State',
      type: 'text/plain',
      listDepth: 0,
      isOptional: true,
      value: null,
      key: 'state',
    },
    postalCode: {
      name: 'Postal Code',
      type: 'text/plain',
      listDepth: 0,
      isOptional: true,
      value: null,
      key: 'postalCode',
    },
    website: {
      name: 'Website',
      type: 'text/plain',
      listDepth: 0,
      isOptional: true,
      value: null,
      key: 'website',
    },
    timezone: {
      name: 'Timezone',
      type: 'text/plain',
      listDepth: 0,
      isOptional: true,
      value: null,
      key: 'timezone',
    },
    tags: {
      name: 'Tags',
      type: 'text/plain',
      listDepth: 1,
      isOptional: true,
      value: null,
      key: 'tags',
    },
    customField: {
      name: 'Custom Field',
      type: 'object',
      listDepth: 0,
      conditions: [
        {
          reference: 'locationId',
          type: 'not',
          value: null,
        },
      ],
      isOptional: true,
      metadata: {
        schema: {
          reference: {
            namespace: 'highlevel',
            member: 'get_custom_fields_contact',
            arguments: [
              {
                namespace: 'options',
                member: 'locationId',
                parameter: 'location_id',
              },
            ],
          },
        },
      },
      value: {},
      key: 'customField',
    },
    source: {
      name: 'Source',
      type: 'text/plain',
      listDepth: 0,
      isOptional: true,
      value: null,
      key: 'source',
    },
    country: {
      name: 'Country',
      type: 'text/plain',
      listDepth: 0,
      isOptional: true,
      value: null,
      key: 'country',
    },
    companyName: {
      name: 'Company Name',
      type: 'text/plain',
      listDepth: 0,
      isOptional: true,
      value: null,
      key: 'companyName',
    },
  },
  outputs: {},
} satisfies StepDefinition;
