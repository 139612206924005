import { type GraphRun, type RunGroup } from '@respell/database';
import { PostHogEvents } from '@respell/utils/tracking/types';
import { useEventSource } from '@vueuse/core';

interface StreamInfo {
  close: () => void;
}

export const useStreamStore = defineStore('stream', () => {
  const streams = ref<Record<string, StreamInfo>>({});
  const { $clientPosthog } = useNuxtApp();

  const spellStore = useSpellsStore();
  const agentStore = useAgentsStore();

  const { groupRuns, run, bulkGroups } = storeToRefs(spellStore);
  const { campaigns } = storeToRefs(agentStore);

  const startStream = async ({
    spellId,
    runId,
    type,
  }: {
    spellId: string;
    runId: string;
    type?: 'test' | 'agent' | 'live' | 'bulk';
  }) => {
    if (streams.value[runId]) {
      console.warn(`Stream ${runId} is already running`);
      return;
    }

    // Initialize the stream entry to reduce latency in streaming UI
    streams.value[runId] = {};

    const { data, error, close } = useEventSource(
      `/api/spells/${spellId}/stream?${type === 'bulk' ? `groupId=${runId}` : `runId=${runId}`}`,
    );

    streams.value[runId] = { close };

    watch(data, (newData) => {
      if (newData === 'end') {
        if (type === 'bulk') {
          spellStore.loadBulkGroups();
        } else {
          $clientPosthog?.capture(PostHogEvents.SpellRunSucceeded, {
            spellId,
            runId,
            type,
          });
        }
        stopStream(runId);
      } else if (newData) {
        try {
          const parsedData = JSON.parse(newData);
          if (parsedData && parsedData.graph && parsedData.steps) {
            updateStoreData(parsedData, type, runId);
          }
        } catch (parseError) {
          console.warn('Received incomplete or invalid JSON data:', parseError);
        }
      }
    });

    watch(error, (newError) => {
      if (newError) {
        console.error('EventSource error:', newError);
        stopStream(runId);
      }
    });

    return runId;
  };

  const stopStream = (runId: string) => {
    if (streams.value[runId]) {
      streams.value[runId].close();
      delete streams.value[runId];
    }
  };

  const stopAllStreams = () => {
    Object.keys(streams.value).forEach(stopStream);
  };

  // Helper function to update store data based on stream type
  const updateStoreData = (parsedData: any, type?: string, runId: string) => {
    if (type === 'bulk') {
      groupRuns.value = groupRuns.value?.map((run: GraphRun) =>
        run.id === parsedData.graph.id
          ? { ...parsedData.graph, steps: parsedData.steps }
          : run,
      );
      bulkGroups.value = bulkGroups.value?.map((group: RunGroup) => {
        if (group.id === runId) {
          return {
            ...group,
            runs: group.runs?.map((run: GraphRun) =>
              run.id === parsedData.graph.id
                ? { ...parsedData.graph, steps: parsedData.steps }
                : run,
            ),
          };
        } else {
          return group;
        }
      });
    } else if (type === 'agent') {
      campaigns.value = campaigns.value?.map((campaign: GraphRun) =>
        campaign.id === parsedData.graph.id
          ? {
              ...parsedData.graph,
              steps: Object.values(parsedData.steps),
            }
          : campaign,
      );
    } else {
      run.value = {
        ...parsedData.graph,
        steps: Object.values(parsedData.steps),
      };
    }
  };

  return {
    startStream,
    stopStream,
    streams,
    stopAllStreams,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useStreamStore, import.meta.hot));
}
