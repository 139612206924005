import { TriggerDefinition } from '../../../definition';

export default {
  name: 'New Opportunity',
  key: 'new_highlevel_opportunity',
  description: 'A new opportunity is created in GoHighLevel.',
  icon: 'i-respell-highlevel',
  options: {
    location_id: {
      name: 'Location ID',
      type: 'text/plain',
      listDepth: 0,
      isOptional: false,
      value: null,
      metadata: {
        isSearchable: true,
        options: {
          reference: {
            namespace: 'highlevel',
            member: 'search_locations',
            arguments: [
              {
                namespace: 'options',
                member: 'query',
                parameter: 'query',
              },
            ],
          },
        },
      },
      key: 'location_id',
    },
  },
  outputs: {
    opportunity: {
      metadata: {
        schema: {
          opportunityId: {
            name: 'Opportunity ID',
            type: 'text/plain',
            listDepth: 0,
            isOptional: false,
            value: null,
            key: 'opportunityId',
          },
          location_id: {
            name: 'Location ID',
            type: 'text/plain',
            listDepth: 0,
            isOptional: false,
            value: null,
            // metadata: {
            //   isSearchable: true,
            //   options: {
            //     reference: {
            //       namespace: 'highlevel',
            //       member: 'search_locations',
            //       arguments: [
            //         {
            //           namespace: 'options',
            //           member: 'query',
            //           parameter: 'query',
            //         }
            //       ]
            //     }
            //   }
            // },
            key: 'location_id',
          },
          assignedTo: {
            name: 'Assigned To',
            type: 'text/plain',
            listDepth: 0,
            isOptional: true,
            value: null,
            key: 'assignedTo',
          },
          name: {
            name: 'Name',
            type: 'text/plain',
            listDepth: 0,
            isOptional: false,
            value: null,
            key: 'name',
          },
          contact_id: {
            name: 'Assigned Contact',
            type: 'text/plain',
            listDepth: 0,
            isOptional: false,
            metadata: {
              options: {
                reference: {
                  namespace: 'highlevel',
                  member: 'list_contacts',
                  arguments: [
                    {
                      namespace: 'options',
                      member: 'location_id',
                      parameter: 'location_id',
                    },
                  ],
                },
              },
            },
            value: null,
            key: 'contact_id',
          },
          monetaryValue: {
            name: 'Value',
            type: 'number/integer',
            listDepth: 0,
            isOptional: true,
            value: null,
            key: 'monetaryValue',
          },
          pipeline_id: {
            name: 'Pipline',
            type: 'text/plain',
            listDepth: 0,
            isOptional: false,
            value: null,
            key: 'pipeline_id',
          },
          pipeline_stage_id: {
            name: 'Stage',
            type: 'text/plain',
            listDepth: 0,
            isOptional: true,
            value: null,
            key: 'pipeline_stage_id',
          },
          // customFields: {
          //   name: 'Custom Fields',
          //   type: 'object',
          //   listDepth: 0,
          //   isOptional: true,
          //   value: {},
          //   conditions: [
          //     {
          //       reference: 'location_id',
          //       type: 'not',
          //       value: null
          //     }
          //   ],
          //   key: 'customFields',
          //   metadata: {
          //     schema: {
          //       reference: {
          //         namespace: 'highlevel',
          //         member: 'get_custom_fields_opportunity',
          //         arguments: [
          //           {
          //             namespace: 'options',
          //             member: 'location_id',
          //             parameter: 'location_id',
          //           }
          //         ]
          //       }
          //     }
          //   }
          // },
          status: {
            name: 'Status',
            type: 'text/plain',
            listDepth: 0,
            isOptional: false,
            value: null,
            key: 'status',
            metadata: {
              options: [
                {
                  id: 'open',
                  name: 'Open',
                },
                {
                  id: 'won',
                  name: 'Won',
                },
                {
                  id: 'lost',
                  name: 'Lost',
                },
                {
                  id: 'abandoned',
                  name: 'Abandoned',
                },
              ],
              // values: ['open', 'won', 'lost', 'abandoned']
            },
          },
        },
      },
      name: 'Opportunity',
      type: 'object',
      listDepth: 0,
      isOptional: false,
      value: null,
      key: 'opportunity',
    },
  },
} satisfies TriggerDefinition;
