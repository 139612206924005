import { StepDefinition } from '../../types';

export default {
  key: 'schedule_interview_greenhouse',
  name: 'Schedule Interview',
  description: 'Schedules an interview for an applicant.',
  icon: 'i-respell-greenhouse',
  category: 'integrations',
  services: ['greenhouse'],
  options: {
    job_id: {
      key: 'job_id',
      name: 'Job ID',
      description: 'The ID of the job.',
      isOptional: false,
      type: 'number/integer',
      metadata: {
        options: {
          reference: {
            namespace: 'greenhouse',
            member: 'list_jobs',
          },
        },
      },
      listDepth: 0,
      value: null,
    },
    application_id: {
      key: 'application_id',
      name: 'Application ID',
      description: 'The ID of the application.',
      isOptional: false,
      type: 'number/integer',
      metadata: {
        options: {
          reference: {
            namespace: 'greenhouse',
            member: 'list_applications',
            arguments: [
              {
                namespace: 'options',
                member: 'job_id',
                parameter: 'job_id',
              },
            ],
          },
        },
      },
      listDepth: 0,
      value: null,
    },
    interview_id: {
      key: 'interview_id',
      name: 'Interview Type',
      description: 'The type of interview.',
      isOptional: false,
      type: 'text/plain',
      metadata: {
        options: {
          reference: {
            namespace: 'greenhouse',
            member: 'list_interview_types',
            arguments: [
              {
                namespace: 'options',
                member: 'application_id',
                parameter: 'jobId',
              },
            ],
          },
        },
      },
      listDepth: 0,
      value: null,
    },
    interviewers: {
      key: 'interviewers',
      name: 'Interviewers',
      description: 'List of interviewers.',
      isOptional: false,
      type: 'object',
      metadata: {
        schema: {
          email: {
            key: 'email',
            name: 'Email',
            description: 'The email of the interviewer.',
            type: 'text/email',
            isOptional: true,
            listDepth: 0,
            value: null,
          },
          feedbackRequired: {
            key: 'feedbackRequired',
            name: 'Is Feedback Required',
            description: 'Whether feedback is required from the interviewer.',
            type: 'boolean',
            isOptional: true,
            listDepth: 0,
            value: null,
          },
        },
      },
      listDepth: 0,
      value: null,
    },
    startTime: {
      key: 'startTime',
      name: 'Start Time',
      description: 'The start time of the interview.',
      isOptional: false,
      type: 'datetime',
      listDepth: 0,
      value: null,
    },
    endTime: {
      key: 'endTime',
      name: 'End Time',
      description: 'The end time of the interview.',
      isOptional: false,
      type: 'datetime',
      listDepth: 0,
      value: null,
    },
  },
  outputs: {
    id: {
      key: 'id',
      name: 'ID',
      description: 'The ID of the scheduled interview.',
      isOptional: false,
      type: 'text/plain',
      listDepth: 0,
      value: null,
    },
    meetingLink: {
      key: 'meetingLink',
      name: 'Meeting Link',
      description: 'The link to the scheduled interview.',
      isOptional: false,
      type: 'text/url',
      listDepth: 0,
      value: null,
    },
    location: {
      key: 'location',
      name: 'Location',
      description: 'The location of the scheduled interview.',
      isOptional: false,
      type: 'text/plain',
      listDepth: 0,
      value: null,
    },
  },
} satisfies StepDefinition;
