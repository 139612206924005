import { StepDefinition } from '../../types';

export default {
  key: 'box_search_content',
  name: 'Search Content',
  description: 'Performs a search query in your box drive',
  icon: 'i-respell-box',
  category: 'integrations',
  services: ['box'],
  options: {
    search: {
      key: 'search',
      name: 'Search',
      type: 'text/plain',
      description:
        'The text that you would like to search up (https://developer.box.com/reference/get-search/#param-query) for more info',
      isOptional: false,
      listDepth: 0,
      value: null,
    },
  },
  outputs: {
    files: {
      key: 'files',
      name: 'Files',
      type: 'object',
      description: 'A list of all the files returned by the search query',
      isOptional: false,
      listDepth: 1,
      value: null,
    },
  },
} satisfies StepDefinition;
