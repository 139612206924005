import { Integration } from '../definition.js';

export default {
  key: 'mailgun',
  name: 'Mailgun',
  icon: 'i-respell-mailgun',
  options: [
    {
      key: 'password',
      name: 'API Key',
      listDepth: 0,
      type: 'text/plain',
      isOptional: false,
      value: null,
    },
    {
      key: 'region',
      name: 'Region',
      listDepth: 0,
      type: 'text/plain',
      isOptional: false,
      value: null,
      metadata: {
        options: [
          {
            key: 'api',
            name: 'US Endpoint',
          },
          // {
          //   key: 'api.eu',
          //   name: 'EU Endpoint',
          // },
        ],
      },
    },
  ],
} satisfies Integration;
