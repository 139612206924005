import { StepDefinition } from '../../types';

export default {
  key: 'add_note',
  name: 'Add Note',
  description: 'Add a note to a candidate in Ashby.',
  icon: 'i-respell-ashby',
  category: 'integrations',
  services: ['ashby'],
  options: {
    candidateId: {
      key: 'candidateId',
      name: 'Candidate ID',
      description: 'The ID of the candidate you want to add a note to.',
      isOptional: false,
      type: 'text/plain',
      listDepth: 0,
      metadata: {
        options: {
          reference: {
            namespace: 'ashby',
            member: 'list_canididates',
          },
        },
      },
      value: null,
    },
    note: {
      key: 'note',
      name: 'Note',
      description: 'The note you want to add to the candidate.',
      isOptional: false,
      type: 'text/plain',
      metadata: {
        isMultiline: true,
      },
      listDepth: 0,
      value: null,
    },
  },
  outputs: {},
} satisfies StepDefinition;
