import type { NitroFetchOptions, NitroFetchRequest } from 'nitropack';

export function $api<T = void>(
  req: NitroFetchRequest,
  options?: NitroFetchOptions<T>,
) {
  const nuxtApp = useNuxtApp();

  const { $csrfFetch } = useNuxtApp();

  return $csrfFetch(req, options);
}
