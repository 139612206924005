import { StepDefinition } from '../../types';

export default {
  key: 'search_messages',
  name: 'Search Messages',
  description: 'Searches your Slack workspace for messages matching a query.',
  icon: 'i-respell-slack',
  //documentationLink: 'https://docs.respell.ai/slack',
  category: 'integrations',
  services: ['slack'],
  options: {
    query: {
      key: 'query',
      name: 'Query',
      description:
        'The search term used to find messages. Functions the same as the Slack search bar.',
      type: 'text/plain',
      isOptional: false,
      metadata: {
        icon: 'i-ph-subtitles-bold',
      },
      listDepth: 0,
      value: null,
    },
    timeframe: {
      key: 'timeframe',
      name: 'Timeframe',
      description:
        'The time period during which the messages were sent. For example, "today", "July", "2023", or "yesterday".',
      type: 'text/plain',
      isOptional: true,
      metadata: {
        icon: 'i-ph-clock',
      },
      listDepth: 0,
      value: null,
    },
  },
  outputs: {
    messages: {
      key: 'messages',
      name: 'Message Results',
      description: 'Details of the messages matching the query.',
      type: 'object',
      isOptional: false,
      listDepth: 1,
      metadata: {
        icon: 'i-ph-subtitles-bold',
        schema: {
          text: {
            key: 'text',
            name: 'Text',
            description: 'The text of the message.',
            type: 'text/plain',
            isOptional: false,
            listDepth: 0,
            value: null,
          },
          username: {
            key: 'username',
            name: 'Username',
            description: 'The username of the sender.',
            type: 'text/plain',
            isOptional: false,
            listDepth: 0,
            value: null,
          },
        },
      },
      value: null,
    },
  },
} satisfies StepDefinition;
