import { Integration } from '../definition.js';

export default {
  key: 'bland',
  name: 'Bland.ai',
  icon: 'i-respell-bland',
  marketingHtml: `
    <p>Let's put your calls on autopilot with <b>Bland.ai</b> and Respell. Start for free and tell Bland your use case. Whether it's for sales, customer support, operations, or creating a product, Respell and Bland.ai work together to automate your workflows.</p> 
    <img src="https://cdn.prod.website-files.com/66cddff3a7bb08f0bc015747/66cf0e70c73894c845367e32_how-it-works-p-500.png" alt="Bland.ai and Respell integration">
    <p>Choose from a wide range of <i>voices or languages</i>, and let Bland handle your phone calls in the way you prefer, complete with emotional nuances. Whether it's for sending out automated calls or receiving them, Bland offers a versatile solution tailored to your needs.</p>
    <p>Teach Bland like an employee. Create a pathway to handle any conversation just like a human. With our guardrails, Bland can respond to anything intelligently, without hallucination. Want to automatically transfer to a human? Book a meeting? Send AI texts? Update a CRM? Simply drop your preferred tool into Bland, and we’ll handle the integration seamlessly. Respell and Bland.ai are here to revolutionize your communication workflows.</p>
  `,
  options: [
    {
      key: 'apiKey',
      name: 'API Key',
      description: 'Bland API Key',
      isOptional: false,
      type: 'text/plain',
      listDepth: 0,
      value: null,
    },
  ],
  postAuthOptions: [],
} satisfies Integration;
