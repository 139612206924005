<script setup lang="ts">
const props = defineProps({
  text: {
    type: String,
    default: '',
  },
});

const { copy } = useClipboard();

const copySuccessful = ref(false);

const handleCopy = () => {
  copy(props.text);
  copySuccessful.value = true;
  setTimeout(() => {
    copySuccessful.value = false;
  }, 2000);
};
</script>
<template>
  <UButton
    :icon="copySuccessful ? 'i-ph-check-circle' : 'i-ph-clipboard'"
    color="gray"
    variant="solid"
    size="xs"
    @click="handleCopy"
  />
</template>
