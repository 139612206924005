import { StepDefinition } from '../../types';

export default {
  key: 'add_file',
  name: 'Add Workspace File',
  description: 'Adds a file to your Data Sources.',
  category: 'file',
  icon: 'i-respell-file-to-workspace',
  options: {
    folderId: {
      key: 'folderId',
      name: 'Folder',
      listDepth: 0,
      description: 'Choose the destination folder from your Data Sources.',
      type: 'text/plain',
      metadata: {
        isSearchable: true,
        options: {
          reference: {
            member: 'get_folders',
            arguments: [
              {
                namespace: 'options',
                member: 'query',
                parameter: 'query',
              },
            ],
          },
        },
      },
      isOptional: false,
      value: 'all',
    },
    file: {
      key: 'file',
      name: 'File',
      listDepth: 0,
      description: 'The file to be added to your Data Sources.',
      type: 'file/document',
      conditions: [
        {
          reference: 'folderId',
          type: 'not',
          value: null,
        },
      ],
      isOptional: false,
      value: null,
    },
  },
  outputs: {},
} satisfies StepDefinition;
