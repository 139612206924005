<script setup lang="ts">
import {
  type Graph,
  type GraphRun,
  type Step,
  type StepRun,
} from '@respell/database';
import definitions from '@respell/steps';
import StepRunDetail from './StepRunDetail.vue';

const isTriggered = computed(() => props.graph?.inputType === 'trigger');

const props = defineProps<{
  run: GraphRun;
  graph: Graph;
  size: 'sm' | 'md';
}>();

const stepRuns = computed(() => {
  let formattedRuns = [];

  formattedRuns = props.run.steps
    .map((stepRun: StepRun) => {
      const graphStep = props.graph.steps.find(
        (step: Step) => step.slug === stepRun.slug,
      );

      const stepDefinition = definitions[graphStep?.key ?? ''];

      let outputs = {};

      if (graphStep?.key === 'review') {
        outputs = Object.entries(stepRun.outputs || {}).reduce(
          (acc, [key, value]) => {
            acc[key] = {
              ...props.graph.inputs[key],
              value,
            };
            return acc;
          },
          {},
        );
      } else {
        outputs = Object.entries(stepDefinition?.outputs || {}).reduce(
          (acc, [key, value]) => {
            acc[key] = {
              ...value,
              value: stepRun?.outputs[key],
            };
            return acc;
          },
          {},
        );
      }

      const options = Object.entries(stepDefinition?.options || {}).reduce(
        (acc, [key, value]) => {
          acc[key] = {
            ...value,
            value: stepRun?.options[key],
          };
          return acc;
        },
        {},
      );

      return {
        ...stepRun,
        ...stepDefinition,
        options,
        outputs,
        disabled: stepRun.state === 'inProgress',
        label: graphStep?.label,
      };
    })
    .sort(
      (a: StepRun, b: StepRun) =>
        new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
    );

  if (props.run.state === 'canceled') {
    formattedRuns.push({
      id: 'canceled',
      key: 'canceled',
      state: 'canceled',
    });
  } else if (props.run.state === 'paused') {
    const reviewingStep = formattedRuns.pop();
    formattedRuns.push({
      ...reviewingStep,
      state: 'paused',
    });
  }

  formattedRuns = formattedRuns
    .map((stepRun: StepRun) => {
      if (stepRun.key === 'loop') {
        const nestedRuns = stepRun.options?.sequence?.value
          ?.map((slug: string) =>
            formattedRuns.filter((run: StepRun) => run.slug === slug),
          )
          .flat();

        const groupedNestedRuns = nestedRuns.reduce(
          (acc, run) => {
            const iteration = run.iteration || 0;
            if (!acc[iteration]) {
              acc[iteration] = [];
            }
            acc[iteration].push(run);
            return acc;
          },
          {} as Record<number, StepRun[]>,
        );

        const sortedGroupedNestedRuns = Object.entries(groupedNestedRuns)
          .sort(([a], [b]) => Number(a) - Number(b))
          .map(([_, runs]) => runs);

        const endedAt = nestedRuns
          .filter((run) => run.endedAt)
          .map((run) => new Date(run.endedAt).getTime())
          .reduce((max, current) => (current > max ? current : max), 0);

        const state = nestedRuns.every((run) => run.state === 'success')
          ? 'success'
          : 'inProgress';

        return {
          ...stepRun,
          iterations: sortedGroupedNestedRuns,
          endedAt: endedAt ? new Date(endedAt).toISOString() : null,
          state,
        };
      }
      return stepRun;
    })
    .filter(
      (stepRun: StepRun) =>
        stepRun.iteration === undefined || stepRun.iteration === null,
    );

  return formattedRuns;
});
</script>
<template>
  <div
    v-for="stepRun in stepRuns"
    :key="stepRun.id"
    class="grid grid-cols-[1rem,minmax(0,1fr)] w-full gap-2 max-w-full"
  >
    <div class="flex flex-col w-full justify-start">
      <UIcon
        :name="
          stepRun.state === 'success'
            ? 'i-ph-check-circle-fill'
            : stepRun.state === 'inProgress'
              ? 'i-ph-circle-dashed-bold'
              : stepRun.state === 'paused'
                ? 'i-ph-pause-circle-fill'
                : 'i-ph-x-circle-fill'
        "
        class="w-4 h-4"
        :class="[
          { 'animate-spin': stepRun.state === 'inProgress' },
          ['error', 'canceled'].includes(stepRun.state)
            ? 'text-red-500'
            : ['skipped', 'paused'].includes(stepRun.state)
              ? 'text-gray-400'
              : 'text-primary-500',
        ]"
      />
      <div class="w-0.5 h-full bg-gray-300" />
    </div>
    <StepRunDetail
      :step-run="stepRun"
      :is-triggered="isTriggered"
      :run-id="run.id"
    />
  </div>
</template>
<style lang="scss">
// Target accordion item
[id^='headlessui-disclosure-button-'] + * {
  width: 100%;
}
</style>
