import { StepDefinition } from '../../types';

export default {
  key: 'outlook_send_reply',
  name: 'Send Reply',
  description: 'Sends a reply to an email from your Outlook account.',
  icon: 'i-respell-outlook',
  category: 'integrations',
  services: ['outlook'],
  options: {
    body: {
      key: 'body',
      name: 'Body',
      description: 'The body of the reply.',
      type: 'text/plain',
      isOptional: true,
      listDepth: 0,
      metadata: {
        icon: 'i-ph-text-align-left',
      },
      value: null,
    },
    draft: {
      key: 'draft',
      name: 'Draft',
      description: 'Whether the email should be a draft rather then sent.',
      type: 'boolean',
      isOptional: false,
      listDepth: 0,
      metadata: {
        icon: 'i-ph-user',
      },
      value: true,
    },
    messageId: {
      key: 'messageId',
      name: 'Message ID',
      description: 'The id of the email.',
      type: 'text/plain',
      isOptional: false,
      listDepth: 0,
      metadata: {
        icon: 'i-ph-text-align-left',
      },
      value: null,
    },
  },
  outputs: {},
} satisfies StepDefinition;
