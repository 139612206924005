import { StepDefinition } from '../../types';

export default {
  key: 'onedrive_create_file',
  name: 'Create File',
  description: "Creates a file in the user's OneDrive.",
  icon: 'i-respell-one-drive',
  category: 'integrations',
  services: ['one-drive'],
  options: {
    name: {
      key: 'name',
      name: 'Name',
      description:
        "The full file path including it's name. (e.g. 'Documents/test.txt')",
      type: 'text/plain',
      isOptional: false,
      metadata: {
        icon: 'i-ph-subtitles-bold',
      },
      listDepth: 0,
      value: null,
    },
    content: {
      key: 'content',
      name: 'Content',
      description: 'The content that should be in the file.',
      type: 'text/plain',
      isOptional: false,
      metadata: {
        icon: 'i-ph-subtitles-bold',
      },
      listDepth: 0,
      value: null,
    },
  },
  outputs: {},
} satisfies StepDefinition;
