import { CommentModel } from '@respell/integrations/zendesk/models';
import { StepDefinition } from '../../types';

export default {
  key: 'get_ticket_comments',
  name: 'Get Ticket Comments',
  description: 'Retrieves all comments associated with a Zendesk ticket.',
  icon: 'i-respell-zendesk',
  //documentationLink: 'https://docs.respell.ai/apollo',
  category: 'integrations',
  services: ['zendesk'],
  options: {
    ticketId: {
      key: 'ticketId',
      name: 'Ticket ID',
      description: 'The ID of the ticket you want to retrieve comments from.',
      type: 'number/integer',
      listDepth: 0,
      metadata: {
        options: {
          reference: {
            namespace: 'zendesk',
            member: 'get_tickets',
          },
        },
      },
      value: null,
      isOptional: true,
    },
  },
  outputs: {
    comments: {
      key: 'comments',
      name: 'Ticket Comments',
      description: 'List of comments associated with the ticket.',
      type: 'object',
      isOptional: false,
      listDepth: 1,
      metadata: {
        icon: 'i-ph-subtitles-bold',
        schema: CommentModel.schema,
      },
      value: null,
    },
  },
} satisfies StepDefinition;
