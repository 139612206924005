import { StepDefinition } from '../../types';

export default {
  key: 'get_apollo_opportunity',
  name: 'Get Opportunity',
  description:
    'Retrieves a single Apollo opportunity from a unique identifier.',
  icon: 'i-respell-apollo',
  category: 'integrations',
  services: ['apollo'],
  options: {
    opportunity: {
      key: 'opportunity',
      name: 'Opportunity',
      description: 'The opportunity you want to retrieve.',
      type: 'text/plain',
      isOptional: false,
      metadata: {
        options: {
          reference: {
            namespace: 'apollo',
            member: 'list_opportunities',
          },
        },
      },
      listDepth: 0,
      value: null,
    },
  },
  outputs: {
    opportunity: {
      key: 'opportunity',
      name: 'Opportunity',
      type: 'object',
      isOptional: false,
      value: null,
      listDepth: 0,
      description: 'The retrieved opportunity.',
      metadata: {
        schema: {
          owner_id: {
            key: 'owner_id',
            name: 'Owner ID',
            description: "The owner's ID.",
            type: 'text/plain',
            isOptional: false,
            metadata: {
              icon: 'i-ph-id-card',
              options: {
                reference: {
                  namespace: 'apollo',
                  member: 'get_users',
                },
              },
            },
            listDepth: 0,
            value: null,
          },
          description: {
            key: 'description',
            name: 'Description',
            description: 'The description of the opportunity.',
            type: 'text/plain',
            isOptional: true,
            metadata: {
              icon: 'i-ph-text-align-left',
            },
            listDepth: 0,
            value: null,
          },
          name: {
            key: 'name',
            name: 'Name',
            description: "The opportunity's name.",
            type: 'text/plain',
            isOptional: false,
            metadata: {
              icon: 'i-ph-subtitles-bold',
            },
            listDepth: 0,
            value: null,
          },
          amount: {
            key: 'amount',
            name: 'Amount',
            description:
              'The amount of money involved in the opportunity/ deal.',
            type: 'number/integer',
            isOptional: false,
            metadata: {
              icon: 'i-ph-dollar-sign',
            },
            listDepth: 0,
            value: null,
          },
          stage_name: {
            key: 'stage_name',
            name: 'Opportunity Stage',
            description: 'The stage of the opportunity.',
            type: 'text/plain',
            isOptional: false,
            metadata: {
              icon: 'i-ph-stage',
              options: {
                reference: {
                  namespace: 'apollo',
                  member: 'get_stages',
                },
              },
            },
            listDepth: 0,
            value: null,
          },
          closed_date: {
            key: 'closed_date',
            name: 'Closed Date',
            description: 'The date when the opportunity was closed.',
            type: 'date',
            isOptional: true,
            metadata: {
              icon: 'i-ph-calendar',
            },
            listDepth: 0,
            value: null,
          },
          account_id: {
            key: 'account_id',
            name: 'Account ID',
            description: 'The ID of the account.',
            type: 'text/plain',
            isOptional: false,
            metadata: {
              icon: 'i-ph-id-card',
              options: {
                reference: {
                  namespace: 'apollo',
                  member: 'get_accounts',
                },
              },
            },
            listDepth: 0,
            value: null,
          },
        },
      },
    },
  },
} satisfies StepDefinition;
