import { StepDefinition } from '../../types';

export default {
  key: 'search',
  name: 'Search Exa',
  description: 'Will search the Exa search engine.',
  icon: 'i-respell-exa',
  //documentationLink: 'https://docs.respell.ai/exa',
  category: 'integrations',
  services: ['exa'],
  options: {
    query: {
      type: 'text/plain',
      isOptional: false,
      description: 'The query string. Required.',
      name: 'Query',
      listDepth: 0,
      value: null,
      key: 'query',
    },
  },
  outputs: {
    result: {
      type: 'object',
      isOptional: false,
      description: 'The results of the search.',
      name: 'Result',
      listDepth: 1,
      metadata: {
        schema: {
          score: {
            type: 'number',
            key: 'score',
            name: 'Relevance Score',
            description: 'The relevance score of the search result.',
            isOptional: false,
            listDepth: 0,
            value: null,
          },
          title: {
            type: 'text/plain',
            key: 'title',
            name: 'Title',
            description: 'The title of the search result.',
            isOptional: false,
            listDepth: 0,
            value: null,
          },
          id: {
            type: 'text/url',
            key: 'id',
            name: 'ID',
            description: 'The unique identifier of the search result.',
            isOptional: false,
            listDepth: 0,
            value: null,
          },
          url: {
            type: 'text/url',
            key: 'url',
            name: 'URL',
            description: 'The URL of the search result.',
            isOptional: false,
            listDepth: 0,
            value: null,
          },
          publishedDate: {
            type: 'text/plain',
            key: 'publishedDate',
            name: 'Published Date',
            description: 'The publication date of the search result.',
            isOptional: false,
            listDepth: 0,
            value: null,
          },
          // author: {
          //   type: 'text/plain',
          //   key: 'author',
          //   name: 'Author',
          //   description: 'The author of the search result.',
          //   isOptional: false,
          //   listDepth: 0,
          //   value: null,
          // },
          text: {
            type: 'text/plain',
            key: 'text',
            name: 'Snippet',
            description: 'A snippet or summary of the search result.',
            isOptional: false,
            listDepth: 0,
            value: null,
          },
        },
      },
      value: null,
      key: 'result',
    },
  },
} satisfies StepDefinition;
