import { TriggerDefinition } from '../../../definition';

export default {
  key: 'ashby-jobs',
  name: 'New Job',
  description: 'A job is created in Ashby.',
  options: {},
  icon: 'i-respell-ashby',
  outputs: {
    job: {
      metadata: {
        schema: {
          id: {
            key: 'id',
            name: 'ID',
            isOptional: false,
            type: 'text/plain',
            listDepth: 0,
            value: '',
          },
          title: {
            key: 'title',
            name: 'Title',
            isOptional: false,
            type: 'text/plain',
            listDepth: 0,
            value: '',
          },
          confidential: {
            key: 'confidential',
            name: 'Confidential',
            isOptional: false,
            type: 'boolean',
            listDepth: 0,
            value: '',
          },
          status: {
            key: 'status',
            name: 'Status',
            isOptional: false,
            type: 'text/plain',
            listDepth: 0,
            value: '',
          },
          employmentType: {
            key: 'employmentType',
            name: 'Employment Type',
            isOptional: false,
            type: 'text/plain',
            listDepth: 0,
            value: '',
          },
          locationId: {
            key: 'locationId',
            name: 'Location ID',
            isOptional: false,
            type: 'text/plain',
            listDepth: 0,
            value: '',
          },
          departmentId: {
            key: 'departmentId',
            name: 'Department ID',
            isOptional: false,
            type: 'text/plain',
            listDepth: 0,
            value: '',
          },
          updatedAt: {
            key: 'updatedAt',
            name: 'Updated At',
            isOptional: false,
            type: 'date',
            listDepth: 0,
            value: '',
          },
        },
      },
      name: 'Job',
      type: 'object',
      listDepth: 0,
      isOptional: false,
      value: {},
      key: 'job',
    },
  },
} satisfies TriggerDefinition;
