import { Integration } from '../definition';

// There's a gotcha here, api key most be passed in the header as Authorization: `Basic ${btoa(connection.accessToken + ":")}`
export default {
  key: 'ashby',
  name: 'Ashby',
  icon: 'i-respell-ashby',
  options: [
    {
      key: 'username',
      name: 'API Key',
      type: 'text/plain',
      isOptional: false,
      listDepth: 0,
      value: null,
    },
  ],
  preAuthCaption: ``,
  postAuthOptions: [],
} satisfies Integration;
