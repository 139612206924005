<script setup lang="ts">
import { typeMap } from '@respell/utils';
import { differenceInMilliseconds, parseISO } from 'date-fns';
import AppInput from '~/components/app/AppInput.vue';
import AppStepLabel from '~/components/app/AppStepLabel.vue';
import SpellRunDetails from '~/components/spell/SpellRunDetails.vue';

defineProps<{
  stepRun: StepRun;
  isTriggered?: boolean;
  runId: string;
}>();

function formatDuration(start: string, end: string): string {
  const startDate = parseISO(start);
  const endDate = parseISO(end);
  const diffInMs = differenceInMilliseconds(endDate, startDate);
  const seconds = (diffInMs / 1000).toFixed(2);
  return `${seconds} seconds`;
}
</script>

<template>
  <UButton
    v-if="stepRun?.key === 'start'"
    :icon="isTriggered ? 'i-ph-lightning-bold' : 'i-ph-play-circle-bold'"
    :label="`Spell run ${isTriggered ? 'triggered' : 'started'}`"
    variant="soft"
    class="w-fit"
    :ui="{
      variant: {
        soft: 'bg-primary-100 border border-primary-200',
      },
    }"
  />
  <UButton
    v-else-if="stepRun?.key === 'display'"
    icon="i-ph-eye-bold"
    :label="
      stepRun?.state === 'skipped' ? 'Display skipped' : 'Output displayed'
    "
    :color="stepRun?.state === 'skipped' ? 'gray' : 'green'"
    variant="soft"
    class="w-fit"
    :ui="{
      variant: {
        soft:
          stepRun?.state === 'skipped'
            ? 'bg-gray-50 border border-gray-200'
            : 'bg-green-50 border border-green-200',
      },
    }"
  />
  <UButton
    v-else-if="stepRun?.key === 'canceled'"
    icon="i-ph-x-circle-fill"
    label="Run Canceled"
    color="red"
    variant="soft"
    class="w-fit"
    :ui="{
      variant: {
        soft: 'bg-red-50 border border-red-200',
      },
    }"
  />
  <UAccordion
    v-else
    :items="[stepRun]"
    class="w-full border bg-white p-4 rounded-md"
    :class="stepRun.error ? 'border-red-200' : 'border-gray-200'"
    :ui="{
      item: {
        base: 'w-full',
        padding: '!p-0',
      },
    }"
  >
    <template #default="{ open }">
      <div
        class="flex cursor-pointer gap-2 w-full max-w-full justify-start items-baseline"
      >
        <UIcon
          :name="stepRun.icon"
          class="rounded-full border-gray-50 text-2xl outline outline-gray-200 border self-center shrink-0"
        />

        <AppStepLabel
          :label="stepRun.label"
          :step-name="stepRun.name"
          size="sm"
          class="shrink-0"
        />

        <p v-if="stepRun.logs.length" class="caption ms-s truncate">
          {{ stepRun.logs[stepRun.logs.length - 1].replace(/^"|"$/g, '') }}
        </p>

        <UBadge
          v-else-if="stepRun.state === 'skipped'"
          color="gray"
          variant="soft"
          :label="stepRun?.key === 'review' ? 'Rejected' : 'Skipped'"
        />

        <span
          v-if="stepRun.state !== 'inProgress'"
          class="flex gap-2 shrink-0 ml-auto"
        >
          <p
            v-if="stepRun.endedAt"
            class="dimmed"
            :class="size === 'sm' ? 'body-sm' : 'body'"
          >
            {{
              stepRun.key === 'wait'
                ? stepRun.outputs.waitTime.value
                : formatDuration(stepRun.createdAt, stepRun.endedAt)
            }}
          </p>
          <UIcon
            name="i-ph-caret-right-bold"
            class="w-4 h-4 text-gray-400 transform transition-transform duration-200"
            :class="[open && 'rotate-90']"
          />
        </span>
      </div>
    </template>
    <template #item>
      <UAlert
        v-if="stepRun.error"
        icon="i-ph-warning-circle"
        color="red"
        variant="soft"
        :title="stepRun?.error?.key + ':'"
        :description="stepRun?.error?.message"
        class="w-full mt-3"
        :ui="{
          title: 'font-bold',
        }"
      />

      <UAlert
        v-else-if="stepRun?.key === 'review' && stepRun?.state === 'paused'"
        icon="i-ph-warning-circle"
        color="gray"
        variant="soft"
        title="Awaiting review"
        description="This review step has paused the spell run."
        class="w-full mt-3"
        :ui="{
          title: 'font-bold',
        }"
      />

      <UAlert
        v-else-if="
          !Object.keys(stepRun?.outputs || {}).length ||
          stepRun.state === 'skipped'
        "
        icon="i-ph-warning-circle"
        color="gray"
        variant="soft"
        :title="stepRun?.key !== 'condition' ? 'No outputs' : 'Condition'"
        :description="
          stepRun?.key !== 'condition'
            ? 'This step didn\'t produce any outputs'
            : `Selected path${'s'}: ${stepRun?.options.conditions?.value
                ?.filter((v) => v.value)
                .map((v) => v.path)
                .join(', ')}`
        "
        class="w-full mt-3"
        :ui="{
          title: 'font-bold',
        }"
      />

      <div v-else-if="stepRun.key === 'loop'" class="flex flex-col gap-3 py-3">
        <div
          v-for="(iteration, index) in stepRun.iterations"
          :key="index"
          class="border-dashed border border-gray-200 rounded-lg p-3 gap-2 flex flex-col items-start w-full"
        >
          <p class="caption truncate line-clamp-1">
            {{ index + 1 }}.
            {{ stepRun.options.iterable.value?.[index] ?? index }}
          </p>

          <StepRunDetail
            v-for="nestedRun in iteration"
            :key="nestedRun.id"
            :step-run="nestedRun"
            :run-id="runId"
          />
        </div>
      </div>

      <div
        v-else-if="stepRun.key === 'run_spell'"
        class="flex flex-col gap-3 py-3"
      >
        <SpellRunDetails
          v-if="stepRun.outputs.run.value && stepRun.outputs.graph.value"
          :run="stepRun.outputs.run.value"
          :graph="stepRun.outputs.graph.value"
          size="sm"
        />
      </div>

      <div
        v-for="(output, key) in stepRun.outputs"
        v-else
        :key="key"
        class="bg-primary-50 border border-gray-200 rounded-lg p-4 mt-3 flex flex-col items-start w-full"
      >
        <span class="flex gap-2 max-w-full">
          <UIcon
            :name="typeMap[output.type].icon"
            :class="size === 'sm' ? 'text-lg' : 'text-2xl'"
          />
          <p class="shrink-0" :class="size === 'sm' ? 'body-sm' : 'body'">
            {{ output.name }}
          </p>
          <p class="caption truncate">{{ output.description }}</p>
        </span>

        <AppInput
          v-if="output.value"
          :model-value="output.value"
          :option="output"
          owner-type="GraphRun"
          :owner-id="runId"
          readonly
          hide-label
        />
      </div>
    </template>
  </UAccordion>
</template>
