import { TicketModel } from '@respell/integrations/zendesk/models';
import { StepDefinition } from '../../types';

export default {
  key: 'list_assigned_tickets',
  name: 'List Assigned Tickets',
  description: 'Retrieves all Zendesk tickets assigned to a user.',
  icon: 'i-respell-zendesk',
  //documentationLink: 'https://docs.respell.ai/apollo',
  category: 'integrations',
  services: ['zendesk'],
  options: {
    userId: {
      key: 'userId',
      name: 'User ID',
      description: 'The ID of the user you want to find assigned tickets for.',
      type: 'text/plain',
      listDepth: 0,
      metadata: {
        options: {
          reference: {
            namespace: 'zendesk',
            member: 'get_users',
          },
        },
      },
      value: null,
      isOptional: true,
    },
  },
  outputs: {
    tickets: {
      key: 'tickets',
      name: 'Assigned Tickets',
      description: 'Details of the tickets assigned to the specified user.',
      type: 'text/plain',
      isOptional: false,
      listDepth: 1,
      metadata: {
        icon: 'i-ph-subtitles-bold',
        schema: TicketModel.schema,
      },
      value: null,
    },
  },
} satisfies StepDefinition;
