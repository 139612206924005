import { Integration } from '../definition';

export const API_URL = 'https://graph.microsoft.com/v1.0';

export default {
  key: 'one-drive',
  icon: 'i-respell-one-drive',
  name: 'OneDrive',
  options: [],
  postAuthOptions: [],
} satisfies Integration;
