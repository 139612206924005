// Define the getCachedData function
const getCachedData = (key: string, nuxtApp: NuxtApp) =>
  nuxtApp.payload.data[key] || nuxtApp.static.data[key];

// Define the useCache composable
function useCache<DataT, ErrorT>(
  url: string | Request | Ref<string | Request> | (() => string | Request),
  options?: UseFetchOptions<DataT> & { lazy?: boolean },
): Promise<AsyncData<DataT, ErrorT>> {
  // Extract the lazy option and remove it from the options to pass to useApi or useLazyApi
  const { lazy, ...fetchOptions } = options || {};
  const enhancedOptions: UseFetchOptions<DataT> = {
    ...fetchOptions,
    getCachedData: getCachedData,
  };

  // Decide whether to use useApi or useLazyApi based on the lazy parameter
  if (lazy) {
    return useLazyApi<DataT, ErrorT>(url, enhancedOptions);
  } else {
    return useApi<DataT, ErrorT>(url, enhancedOptions);
  }
}

export default useCache;
