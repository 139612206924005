import { StepDefinition } from '../../types';

export default {
  key: 'document_to_text',
  name: 'Document to Text',
  description:
    'Parses the text from a file using AI. Works for PDFs, spreadsheets, word documents, and more.',
  icon: 'i-respell-doc-to-text',
  documentationLink:
    'https://docs.respell.ai/steps-reference/file-tools/document-to-text',
  category: 'file',
  options: {
    file: {
      key: 'file',
      name: 'File',
      listDepth: 0,
      description: 'The file you want to extract the text from.',
      type: 'file/document',
      isOptional: false,
      value: null,
    },
  },
  outputs: {
    results: {
      key: 'results',
      name: 'File Contents',
      description: 'The text content of the file.',
      type: 'text/plain',
      metadata: {
        isMultiline: true,
      },
      listDepth: 0,
      isOptional: false,
      value: null,
    },
  },
} satisfies StepDefinition;
