import { StepDefinition } from '../../types';

export default {
  key: 'run_code',
  name: 'Custom Code',
  description: 'Run custom code in Javascript or Python.',
  documentationLink:
    'https://docs.respell.ai/steps-reference/web-code-tools/run-code',
  icon: 'i-respell-run-code',
  category: 'web',
  services: [],
  options: {
    code: {
      key: 'code',
      name: 'Code',
      description:
        'The code to run. Make sure to include a singular print statement to output the result of the execution.',
      isOptional: false,
      type: 'code',
      metadata: {
        allowedLanguages: ['javascript', 'python'],
      },
      listDepth: 0,
      value: null,
    },
  },
  outputs: {
    result: {
      key: 'result',
      name: 'Output',
      description: 'The result of the custom code.',
      type: 'text/plain',
      isOptional: false,
      listDepth: 0,
      value: null,
    },
  },
} satisfies StepDefinition;
