import { TriggerDefinition } from '../../../definition';

export default {
  key: 'new_email',
  name: 'New Email',
  description: 'An email is received in Outlook.',
  icon: 'i-respell-outlook',
  options: {},
  outputs: {
    email: {
      key: 'email',
      name: 'Email',
      isOptional: false,
      type: 'object',
      listDepth: 0,
      value: {},
      metadata: {
        schema: {
          id: {
            key: 'id',
            name: 'Email ID',
            isOptional: false,
            type: 'text/plain',
            listDepth: 0,
            value: '',
          },
          sender: {
            key: 'sender',
            name: 'Sender',
            isOptional: false,
            type: 'text/plain',
            listDepth: 0,
            value: '',
          },
          recipients: {
            key: 'recipients',
            name: 'Recipients',
            isOptional: false,
            type: 'text/plain',
            listDepth: 0,
            value: '',
          },
          date: {
            key: 'date',
            name: 'Date',
            isOptional: false,
            type: 'date',
            listDepth: 0,
            value: null,
          },
          subject: {
            key: 'subject',
            name: 'Subject',
            isOptional: false,
            type: 'text/plain',
            listDepth: 0,
            value: '',
          },
          body: {
            key: 'body',
            name: 'Body',
            isOptional: false,
            type: 'text/plain',
            listDepth: 0,
            value: '',
          },
        },
      },
    },
  },
} satisfies TriggerDefinition;
