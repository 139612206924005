import { StepDefinition } from '../../types';

export default {
  key: 'create_apollo_opportunity',
  name: 'Create Opportunity',
  description: 'Creates an Apollo opportunity.',
  icon: 'i-respell-apollo',
  category: 'integrations',
  services: ['apollo'],
  options: {
    owner_id: {
      key: 'owner_id',
      name: 'Owner ID',
      description: "The owner's ID.",
      type: 'text/plain',
      isOptional: false,
      metadata: {
        icon: 'i-ph-id-card',
        options: {
          reference: {
            namespace: 'apollo',
            member: 'list_users',
          },
        },
      },
      listDepth: 0,
      value: null,
    },
    name: {
      key: 'name',
      name: 'Name',
      description: "The opportunity's name.",
      type: 'text/plain',
      isOptional: false,
      metadata: {
        icon: 'i-ph-subtitles-bold',
      },
      listDepth: 0,
      value: null,
    },
    amount: {
      key: 'amount',
      name: 'Amount',
      description: 'The amount of money involved in the opportunity/ deal.',
      type: 'number/integer',
      isOptional: false,
      metadata: {
        icon: 'i-ph-dollar-sign',
      },
      listDepth: 0,
      value: null,
    },
    opportunity_stage_id: {
      key: 'opportunity_stage_id',
      name: 'Opportunity Stage ID',
      description: "The ID of the opportunity's stage.",
      type: 'text/plain',
      isOptional: false,
      metadata: {
        icon: 'i-ph-stage',
        options: {
          reference: {
            namespace: 'apollo',
            member: 'list_stages',
          },
        },
      },
      listDepth: 0,
      value: null,
    },
    closed_date: {
      key: 'closed_date',
      name: 'Closed Date',
      description: 'The date when the opportunity was closed.',
      type: 'date',
      isOptional: true,
      metadata: {
        icon: 'i-ph-calendar',
      },
      listDepth: 0,
      value: null,
    },
    account_id: {
      key: 'account_id',
      name: 'Account ID',
      description: 'The ID of the account.',
      type: 'text/plain',
      isOptional: false,
      metadata: {
        isSearchable: true,
        icon: 'i-ph-id-card',
        options: {
          reference: {
            namespace: 'apollo',
            member: 'list_accounts',
            arguments: [
              {
                namespace: 'options',
                member: 'query',
                parameter: 'query',
              },
            ],
          },
        },
      },
      listDepth: 0,
      value: null,
    },
  },
  outputs: {},
} satisfies StepDefinition;
