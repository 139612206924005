import { StepDefinition } from '../../types';

export default {
  key: 'search_contacts_hubspot',
  name: 'Search Contacts',
  description: 'Searches HubSpot Contacts',
  icon: 'i-respell-hubspot',
  category: 'integrations',
  services: ['hubspot'],
  options: {
    query: {
      name: 'Query',
      type: 'text/plain',
      listDepth: 0,
      isOptional: false,
      value: null,
      key: 'query',
    },
  },
  outputs: {
    contacts: {
      name: 'Contacts',
      type: 'object',
      metadata: {
        schema: {
          reference: {
            namespace: 'hubspot',
            member: 'get_properties_contact',
          },
        },
      },
      listDepth: 1,
      isOptional: false,
      value: null,
      key: 'contacts',
    },
  },
} satisfies StepDefinition;
