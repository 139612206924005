<script setup lang="ts">
import type { Variable } from '@respell/utils';
import VariableNode from '~/components/app/VariableNode.vue';
import VariablePicker from '~/components/editor/config/VariablePicker.vue';

const modelValue = defineModel<number>();
const props = defineProps({
  option: {
    type: Object as PropType<Variable>,
    required: true,
  },
  injectable: {
    type: Boolean,
    default: false,
  },
  readonly: {
    type: Boolean,
    default: false,
  },
});

const isVariable = computed(() => {
  if (typeof modelValue.value === 'string') {
    const matches = modelValue.value.match(variableRegex);
    return !!matches;
  }
  return false;
});
</script>
<template>
  <div class="flex flex-row items-center gap-3 justify-between">
    <URange
      v-model="modelValue"
      color="primary"
      :disabled="isVariable || props.readonly"
      size="sm"
      :step="option.type.includes('decimal') ? 0.01 : 1"
      :min="option.metadata?.min"
      :max="option.metadata?.max"
      :ui="{ progress: { base: 'w-full left-0' } }"
      class="w-full"
    />
    <VariableNode v-if="isVariable" v-model="modelValue" size="sm" />
    <VariablePicker
      v-else-if="injectable"
      v-model="modelValue"
      :type="option.type"
      is-range
    >
      <template #button="{ incoming }">
        <UButton
          color="white"
          :disabled="!incoming.valid?.length"
          size="2xs"
          :ui="{
            font: 'font-normal',
            inline: 'w-fit',
          }"
        >
          <span
            class="flex flex-row items-center gap-2 text-sm"
            :class="!incoming.valid?.length ? 'text-gray-300' : 'text-gray-500'"
          >
            {{ 'Or select variable' }}
            <UIcon name="i-ph-caret-down" class="w-4 h-4" />
          </span>
        </UButton>
      </template>
    </VariablePicker>
  </div>
</template>
