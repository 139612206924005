import {
  CommentModel,
  TicketModel,
} from '@respell/integrations/zendesk/models';
import { StepDefinition } from '../../types';

export default {
  key: 'get_ticket',
  name: 'Get Ticket',
  description: 'Retrieves a single Zendesk ticket from a unique identifier.',
  icon: 'i-respell-zendesk',
  category: 'integrations',
  services: ['zendesk'],
  options: {
    ticketId: {
      key: 'ticketId',
      name: 'Ticket ID',
      description: 'The ID of the ticker you want to retrieve.',
      type: 'number/integer',
      metadata: {
        options: {
          reference: {
            namespace: 'zendesk',
            member: 'get_tickets',
          },
        },
      },
      listDepth: 0,
      value: null,
      isOptional: true,
    },
    includeComments: {
      key: 'includeComments',
      name: 'Include Comments',
      description: 'Whether to include comments associated with the ticket.',
      type: 'boolean',
      listDepth: 0,
      value: false,
      isOptional: true,
    },
  },
  outputs: {
    ticket: {
      key: 'ticket',
      name: 'Ticket Details',
      description: 'Details of the ticket.',
      type: 'text/plain',
      isOptional: false,
      listDepth: 0,
      metadata: {
        icon: 'i-ph-subtitles-bold',
        schema: TicketModel.schema,
      },
      value: null,
    },
    comments: {
      key: 'comments',
      name: 'Ticket Comments',
      description: 'List of comments associated with the ticket.',
      type: 'object',
      isOptional: false,
      listDepth: 1,
      conditions: [
        {
          reference: 'includeComments',
          type: 'equals',
          value: true,
        },
      ],
      metadata: {
        icon: 'i-ph-subtitles-bold',
        schema: CommentModel.schema,
      },
      value: null,
    },
  },
} satisfies StepDefinition;
