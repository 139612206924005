import { StepDefinition } from '../../types';

export default {
  key: 'sosl_query',
  name: 'SOSL Query',
  description: 'Performs a Salesforce SOSL query',
  icon: 'i-respell-salesforce',
  category: 'integrations',
  services: ['salesforce'],
  options: {
    query: {
      name: 'Query',
      type: 'text/plain',
      listDepth: 0,
      isOptional: false,
      value: null,
      key: 'query',
    },
  },
  outputs: {
    results: {
      name: 'Results',
      type: 'text/plain',
      listDepth: 0,
      isOptional: false,
      value: null,
      key: 'results',
    },
  },
} satisfies StepDefinition;
