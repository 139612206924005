import { StepDefinition } from '../../types';

export default {
  key: 'api',
  name: 'Make API Request',
  description:
    'Makes an API request to a URL. Commonly used for querying data from services we don’t have access to.',
  icon: 'i-respell-api-request',
  category: 'web',
  options: {
    url: {
      key: 'url',
      name: 'URL',
      listDepth: 0,
      description: 'URL to send the request to.',
      type: 'text/url',
      isOptional: false,
      value: null,
    },
    method: {
      key: 'method',
      name: 'Method',
      listDepth: 0,
      description: 'HTTP method to use for the request.',
      type: 'text/plain',
      metadata: {
        options: [
          {
            key: 'GET',
            name: 'GET',
          },
          {
            key: 'POST',
            name: 'POST',
          },
          {
            key: 'PUT',
            name: 'PUT',
          },
          {
            key: 'DELETE',
            name: 'DELETE',
          },
          {
            key: 'PATCH',
            name: 'PATCH',
          },
        ],
      },
      isOptional: false,
      value: 'GET',
    },
    headers: {
      key: 'headers',
      name: 'Headers',
      listDepth: 0,
      description: 'Headers to send with the request.',
      type: 'code',
      metadata: {
        hint: 'Content-Type: application/json\nAuthorization: Bearer YOUR_API_KEY',
        allowedLanguages: ['http'],
      },
      isOptional: true,
      value: null,
    },
    body: {
      key: 'body',
      name: 'Body',
      listDepth: 0,
      description: 'Body content to send with the request.',
      conditions: [
        {
          reference: 'method',
          type: 'notIn',
          value: ['GET', 'DELETE'],
        },
      ],
      type: 'code',
      isOptional: true,
      value: null,
    },
  },
  outputs: {
    result: {
      key: 'result',
      name: 'Result',
      description: 'Response from the API.',
      type: 'object', // TOOD: adapt to MIME type
      listDepth: 0,
      isOptional: false,
      value: null,
    },
  },
} satisfies StepDefinition;
