import { BucketFileId } from '@respell/utils/types';
import { Model } from '../definition';

export interface DataSourceSearchResult {
  /** The bucketFileId of the file matching the search. */
  file: BucketFileId;
  /** The matching chunk. */
  chunk: string;
}

export const DataSourceSearchResultModel: Model<DataSourceSearchResult> = {
  schema: {
    file: {
      key: 'file',
      name: 'File',
      description: 'The file containing the matched search.',
      type: 'file/document',
      isOptional: false,
      listDepth: 0,
      value: null,
    },
    chunk: {
      key: 'chunk',
      name: 'Chunk',
      description: 'The matching chunk.',
      type: 'text/plain',
      isOptional: false,
      listDepth: 0,
      value: null,
    },
  },
  parse(data: unknown): DataSourceSearchResult {
    return data as DataSourceSearchResult;
  },
};
