import { StepDefinition } from '../../types';

export default {
  key: 'classify_text',
  name: 'Classify Text',
  description: 'Classifies a text into a category.',
  icon: 'i-respell-classify-text',
  category: 'text',
  options: {
    input: {
      key: 'input',
      name: 'Input',
      description: 'The text you want to analyze.',
      isOptional: false,
      type: 'text/plain',
      listDepth: 0,
      metadata: {
        max: 128000,
        isMultiline: true,
      },
      value: null,
    },
    categories: {
      key: 'categories',
      name: 'Categories',
      description: 'The categories to extract from the input data',
      isOptional: false,
      type: 'object',
      metadata: {
        schema: {
          name: {
            key: 'name',
            listDepth: 0,
            isOptional: false,
            value: null,
            type: 'text/plain',
            name: 'Category Name',
          },
          description: {
            key: 'description',
            listDepth: 0,
            isOptional: true,
            value: null,
            type: 'text/plain',
            name: 'Category Description',
          },
        },
      },
      listDepth: 1,
      value: [],
    },
  },
  outputs: {
    class: {
      key: 'class',
      name: 'Classification',
      type: 'text/plain',
      listDepth: 0,
      isOptional: false,
      value: null,
    },
  },
} satisfies StepDefinition;
