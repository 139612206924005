import { StepDefinition } from '../../types';

export default {
  key: 'onedrive_read_file',
  name: 'Read File',
  description: "Reads a file from the user's OneDrive.",
  icon: 'i-respell-one-drive',
  category: 'integrations',
  services: ['one-drive'],
  options: {
    item_id: {
      key: 'item_id',
      name: 'File ID',
      description: 'The id of the file.',
      type: 'text/plain',
      isOptional: false,
      metadata: {
        icon: 'i-ph-subtitles-bold',
      },
      listDepth: 0,
      value: null,
    },
  },
  outputs: {
    content: {
      key: 'content',
      name: 'Content',
      description: 'The content of the file.',
      isOptional: false,
      type: 'text/plain',
      metadata: {
        isMultiline: true,
      },
      listDepth: 0,
      value: null,
    },
  },
} satisfies StepDefinition;
