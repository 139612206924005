import { StepDefinition } from '../../types';

export default {
  key: 'calendly_create_one_off_event_type',
  name: 'Create One Off Event Type',
  description: 'Creates a one off event type in calendly',
  icon: 'i-respell-calendly',
  category: 'integrations',
  services: ['calendly'],
  options: {
    name: {
      key: 'name',
      name: 'Name',
      description: 'The name of the event type.',
      type: 'text/plain',
      isOptional: false,
      listDepth: 0,
      value: null,
    },
    duration: {
      key: 'duration',
      name: 'Duration',
      description: 'The duration of the event (in minutes)',
      type: 'number/integer',
      isOptional: false,
      listDepth: 0,
      value: null,
    },
    start_date: {
      key: 'start_date',
      name: 'Start Date',
      type: 'date',
      isOptional: false,
      listDepth: 0,
      value: null,
    },
    end_date: {
      key: 'end_date',
      name: 'End Date',
      type: 'date',
      isOptional: false,
      listDepth: 0,
      value: null,
    },
    location: {
      key: 'location',
      name: 'Location',
      type: 'text/plain',
      isOptional: false,
      listDepth: 0,
      value: null,
      metadata: {
        options: [
          {
            key: 'custom',
            name: 'Custom',
          },
          {
            key: 'google_conference',
            name: 'Google Conference',
          },
          {
            key: 'gotomeeting_conference',
            name: 'GoToMeeting Conference',
          },
          {
            key: 'Zoom',
            name: 'Zoom Conference',
          },
        ],
      },
    },
  },
  outputs: {
    event: {
      key: 'event',
      name: 'Event Configuration',
      type: 'object',
      isOptional: false,
      listDepth: 0,
      value: null,
    },
  },
} satisfies StepDefinition;
