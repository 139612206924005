import { StepDefinition } from '../../types';

export default {
  key: 'outlook_search_events',
  name: 'Search Events',
  description:
    'Searches for events matching a certain criteria in your Outlook account',
  icon: 'i-respell-outlook',
  category: 'integrations',
  services: ['outlook'],
  options: {
    body: {
      key: 'body',
      name: 'Body',
      description: 'A Description of the Event',
      type: 'text/plain',
      isOptional: true,
      listDepth: 0,
      metadata: {
        icon: 'i-ph-text-align-left',
      },
      value: null,
    },
    subject: {
      key: 'subject',
      name: 'Subject',
      description: 'The subject of the event.',
      type: 'text/plain',
      isOptional: true,
      listDepth: 0,
      metadata: {
        icon: 'i-ph-text-align-left',
      },
      value: null,
    },
    start: {
      key: 'start',
      name: 'Start Time',
      description: 'The start time of the meeting',
      type: 'datetime',
      isOptional: true,
      listDepth: 0,
      metadata: {
        icon: 'i-ph-user',
      },
      value: null,
    },
    end: {
      key: 'end',
      name: 'End Time',
      description: 'The end time of the meeting',
      type: 'datetime',
      isOptional: true,
      listDepth: 0,
      metadata: {
        icon: 'i-ph-user',
      },
      value: null,
    },
  },
  outputs: {
    event_results: {
      key: 'event_results',
      name: 'Event Results',
      description: 'A list of events matching the given query',
      isOptional: false,
      type: 'object',
      listDepth: 0,
      value: null,
    },
  },
} satisfies StepDefinition;
