import { StepDefinition } from '../../types';

export default {
  key: 'attio_update_company',
  name: 'Update Company',
  description: 'Updates a company record in Attio',
  icon: 'i-respell-attio',
  category: 'integrations',
  services: ['attio'],
  options: {
    companyId: {
      key: 'companyId',
      name: 'Company ID',
      type: 'text/plain',
      isOptional: false,
      listDepth: 0,
      value: null,
    },
    company: {
      key: 'company',
      name: 'Company',
      type: 'object',
      metadata: {
        schema: {
          reference: {
            namespace: 'attio',
            member: 'attio_list_attributes_company',
          },
        },
      },
      isOptional: false,
      listDepth: 0,
      value: null,
    },
  },
  outputs: {},
} satisfies StepDefinition;
