import { Integration } from '../definition';

export default {
  key: 'zendesk',
  name: 'Zendesk',
  icon: 'i-respell-zendesk',
  options: [
    {
      key: 'subdomain',
      name: 'Zendesk Subdomain',
      description:
        'Your zendesk subdomain can be found in your zendesk url https://[subdomain].zendesk.com/',
      isOptional: false,
      type: 'text/plain',
      listDepth: 0,
      metadata: {
        mapping: 'params.subdomain',
      },
      // should go to params with subdomain key for nango
      value: null,
    },
  ],
  preAuthCaption: `Zendesk provides a secure way for Respell to access Zendesk data. To connect Zendesk to Respell please input your zendesk subdomain, which can be found in your zendesk url.`,
  postAuthOptions: [],
} satisfies Integration;
