import { StepDefinition } from '../../types';

export default {
  key: 'onedrive_search_files',
  name: 'Search Files',
  description: "Searches for files in the user's OneDrive.",
  icon: 'i-respell-one-drive',
  category: 'integrations',
  services: ['one-drive'],
  options: {
    search: {
      key: 'search',
      name: 'Search Query',
      description: "Query for the user's onedrive",
      type: 'text/plain',
      isOptional: false,
      metadata: {
        icon: 'i-ph-subtitles-bold',
      },
      listDepth: 0,
      value: null,
    },
  },
  outputs: {
    results: {
      key: 'results',
      name: 'Results',
      description: 'List of items that the query has found',
      type: 'object',
      isOptional: false,
      metadata: {
        icon: 'i-ph-subtitles-bold',
      },
      listDepth: 1,
      value: null,
    },
  },
} satisfies StepDefinition;
