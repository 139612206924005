import { StepDefinition } from '../../types';

export default {
  key: 'text_to_speech',
  name: 'Text to Speech',
  description: 'Generates speech using an AI voice from text.',
  icon: 'i-respell-text-to-speech',
  category: 'file',
  documentationLink:
    'https://docs.respell.ai/steps-reference/file-tools/text-to-speech',
  options: {
    model: {
      key: 'model',
      name: 'Model',
      description: 'The base model you want to use.',
      isOptional: false,
      type: 'text/plain',
      listDepth: 0,
      metadata: {
        options: [
          {
            key: 'openai/tts',
            name: 'OpenAI',
            icon: 'i-ph-open-ai-logo-bold',
          },
        ],
      },
      value: 'openai/tts',
    },
    prompt: {
      key: 'prompt',
      name: 'Prompt',
      description:
        'What you want the AI to say. The AI will narrate exactly what you enter.',
      isOptional: false,
      type: 'text/plain',
      listDepth: 0,
      metadata: {
        max: 128000,
        isMultiline: true,
        isPrompt: true,
      },
      value: null,
    },
    voice: {
      key: 'voice',
      name: 'Voice',
      description:
        'Which voice you want the model to use to narrate the script.',
      isOptional: false,
      type: 'text/plain',
      listDepth: 0,
      metadata: {
        options: [
          {
            key: 'alloy',
            name: 'Alloy',
          },
          {
            key: 'echo',
            name: 'Echo',
          },
          {
            key: 'fable',
            name: 'Fable',
          },
          {
            key: 'onyx',
            name: 'Onyx',
          },
        ],
      },
      value: 'alloy',
    },
    language: {
      key: 'langauge',
      name: 'Language',
      description: 'Which language you want the script to be spoken in.',
      isOptional: true,
      type: 'text/plain',
      listDepth: 0,
      metadata: {
        options: [
          {
            key: 'english',
            name: 'English',
          },
        ],
      },
      value: 'english',
    },
  },
  outputs: {
    response: {
      key: 'response',
      name: 'Generated Speech',
      description: 'The speech generated by the AI.',
      type: 'file/audio',
      listDepth: 0,
      isOptional: false,
      value: null,
    },
  },
} satisfies StepDefinition;
