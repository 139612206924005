import { StepDefinition } from '../../types';

export default {
  key: 'twilio_send_message',
  name: 'Send SMS message',
  description: 'Sends an SMS text',
  icon: 'i-logos-twilio-icon',
  category: 'integrations',
  services: ['twilio'],
  options: {
    from: {
      key: 'from',
      name: 'Sender',
      description: 'A phone number in the conversation',
      isOptional: false,
      type: 'text/phone-number',
      listDepth: 0,
      value: null,
    },
    to: {
      key: 'to',
      name: 'Recipient',
      description: 'Another phone number in the conversation',
      isOptional: false,
      type: 'text/phone-number',
      listDepth: 0,
      value: null,
    },
    body: {
      key: 'body',
      name: 'Body',
      description: 'Text content of the message',
      isOptional: false,
      type: 'text/plain',
      listDepth: 0,
      value: null,
    },
  },
  outputs: {
    result: {
      key: 'result',
      name: 'Result',
      description: 'The metadata of the sent message',
      isOptional: false,
      type: 'object',
      listDepth: 0,
      value: null,
    },
  },
} satisfies StepDefinition;
