import { defineNuxtPlugin, NuxtApp } from '#app';

const plugin: NuxtPlugin = (_nuxtApp: NuxtApp) => {
  if (process.client) {
    loadScript('https://apis.google.com/js/api.js');
    loadScript('https://accounts.google.com/gsi/client');
  }

  async function loadScript(src: string): Promise<void> {
    const script = document.createElement('script');
    script.src = src;
    script.async = true;
    script.defer = true;

    await new Promise((resolve, reject) => {
      script.onload = resolve;
      script.onerror = (error: Event | string) => {
        reject(typeof error === 'string' ? new Error(error) : error);
      };

      document.body.appendChild(script);
    });
  }
};

export default defineNuxtPlugin(plugin);
