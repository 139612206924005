import { StepDefinition } from '../../types';

export default {
  key: 'search_transcripts',
  name: 'Search Transcripts',
  description:
    'Returns all fireflies transcripts matching the given criteria. If fields are left blank returns all transcripts',
  icon: 'i-respell-fireflies', // FIXME: This should currently error out the UI due to tailwind complaining
  category: 'integrations',
  services: ['fireflies'],
  options: {
    title: {
      key: 'title',
      name: 'Title',
      description: 'The title of the transcript',
      isOptional: true,
      type: 'text/plain',
      listDepth: 0,
      value: null,
    },
    start_date: {
      key: 'start_date',
      name: 'Start Date',
      description: 'Only show transcripts from start date to end date',
      isOptional: true,
      type: 'date',
      listDepth: 0,
      value: null,
    },
    end_date: {
      key: 'end_date',
      name: 'End Date',
      description:
        'Only show transcripts from start date to end date. e.g. If you want to get a transcript exactly on July 12th, the start should be July 12th, this should be July 13th ',
      isOptional: true,
      type: 'date',
      listDepth: 0,
      value: null,
    },
    host_email: {
      key: 'host_email',
      name: 'Host Email',
      description: "The email address of the meeting's host",
      isOptional: true,
      type: 'text/plain',
      listDepth: 0,
      value: null,
    },
    organizer_email: {
      key: 'organizer_email',
      name: 'Organizer Email',
      description: "The email address of the meeting's organizer",
      isOptional: true,
      type: 'text/plain',
      listDepth: 0,
      value: null,
    },
  },
  outputs: {
    transcripts: {
      key: 'transcripts',
      name: 'Transcripts',
      description: 'object',
      isOptional: false,
      type: 'object',
      // metadata: {
      //   schema: TranscriptsModel.schema,
      // },
      listDepth: 1,
      value: null,
    },
  },
} satisfies StepDefinition;
