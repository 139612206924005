import { TriggerDefinition } from '../../../definition';

export default {
  name: 'Inbound Call',
  key: 'inbound',
  description: 'An inbound call is received.',
  icon: 'i-respell-bland',
  options: {
    target: {
      key: 'target',
      name: 'Target Number',
      description: 'The phone number to receive calls from.',
      metadata: {
        options: {
          reference: {
            namespace: 'bland',
            member: 'list_inbound',
          },
        },
      },
      isOptional: false,
      type: 'text/plain',
      listDepth: 0,
      value: null,
    },
  },
  outputs: {
    transcript: {
      key: 'transcript',
      name: 'Transcript',
      description: 'The transcript of the call.',
      type: 'text/plain',
      isOptional: false,
      listDepth: 0,
      value: null,
    },
    callLength: {
      key: 'callLength',
      name: 'Call Length',
      description: 'The length of the call in seconds.',
      type: 'text/plain',
      isOptional: false,
      listDepth: 0,
      value: null,
    },
    phoneNumber: {
      key: 'phoneNumber',
      name: 'Phone Number',
      description: 'The phone number of the caller.',
      type: 'text/plain',
      isOptional: false,
      listDepth: 0,
      value: null,
    },
  },
  example: {
    transcript: `assistant: Hello, is this Jake?\nuser: Yes, this is Jake.\nassistant: Hi Jake, this is your assistant. I am calling to remind you of your appointment tomorrow at 10am.\nuser: Oh, thank you for reminding me. I almost forgot.`,
    callLength: '120',
    phoneNumber: '+1234567890',
  },
} satisfies TriggerDefinition;
