import { Integration } from '../definition';

export default {
  key: 'gcp',
  name: 'Google Cloud Platform',
  icon: 'i-logos-google-cloud',
  options: [
    {
      key: 'apiKey',
      name: 'Private Key',
      isOptional: false,
      type: 'file/document',
      listDepth: 0,
      value: null,
    },
  ],
  preAuthCaption: 'Ensure your service account has the Storage Admin role.',
  postAuthOptions: [],
} satisfies Integration;
