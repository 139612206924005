import { config } from '@respell/utils/config';
import { Carbon } from 'carbon-typescript-sdk';

let carbon: Carbon | null = null;

// Get an accessToken server-side that we can surface to the client.
export async function getAccessToken(customerId: string) {
  if (!import.meta.client) {
    const carbonWithApiKey = new Carbon({
      apiKey: config.CARBON_API_KEY,
      customerId: customerId,
    });

    const accessToken = await carbonWithApiKey.auth.getAccessToken();

    return accessToken.data.access_token;
  }
}

export async function initializeCarbonByAccessToken(carbonAccessToken: string) {
  carbon = new Carbon({ accessToken: carbonAccessToken });
}

export function useCarbon() {
  return { carbon };
}
