<script setup lang="ts">
import CodeBlockLowlight from '@tiptap/extension-code-block-lowlight';
import Document from '@tiptap/extension-document';
import Text from '@tiptap/extension-text';
import { EditorContent, VueNodeViewRenderer, useEditor } from '@tiptap/vue-3';
import { all, common, createLowlight } from 'lowlight';
import AppCopyButton from '~/components/app/AppCopyButton.vue';
import LanguagePicker from '~/components/editor/config/LanguagePicker.vue';
import VariablePicker from '~/components/editor/config/VariablePicker.vue';
import { ShiftSpace, Variable } from '~/util/tiptap';

const props = defineProps({
  languages: {
    type: Array as PropType<string[]>,
    default: null,
  },
  options: {
    type: Object as PropType<Record<string, string>>,
    default: null,
  },
  injectable: {
    type: Boolean,
    default: false,
  },
  editable: {
    type: Boolean,
    default: true,
  },
  placeholder: {
    type: String,
    default: null,
  },
});

const modelValue = defineModel<{
  content: string;
  language: string;
}>();

const copyText = ref<string>('');

const { emitFormChange } = useFormGroup();

const lowlight = createLowlight(
  props.languages
    ? Object.fromEntries(
        Object.entries(all).filter(([key]) => props.languages.includes(key)),
      )
    : common,
);

const editor = useEditor({
  editable: props.editable,
  extensions: [
    Document.extend({
      content: 'codeBlock',
    }),
    Text,
    CodeBlockLowlight.extend({
      content: 'inline* text* variable*',
      ...(props.languages?.length > 1 && {
        addNodeView() {
          return VueNodeViewRenderer(LanguagePicker);
        },
      }),
    }).configure({
      lowlight,
    }),
    Variable.configure({
      mode: 'editable',
    }),
    ShiftSpace,
  ],
  onBeforeCreate: ({ editor }) => {
    if (!modelValue.value) {
      modelValue.value = {
        language: props.languages?.[0],
        content: props.options
          ? props.options[props.languages?.[0]]
          : props.placeholder
            ? props.placeholder
            : props.languages?.[0] === 'python'
              ? 'print("Hello, World!")'
              : props.languages?.[0] === 'javascript'
                ? 'console.log("Hello, World!");'
                : 'Write your code here...',
      };
    }
  },
  onCreate: ({ editor }) => {
    if (!modelValue.value) {
      return;
    }

    const parsedContent = modelValue.value?.content.replace(
      globalVariableRegex,
      (match: string) => {
        return `<span class="variable">${match}</span>`;
      },
    );

    copyText.value = parsedContent;

    editor.commands.setContent(
      `<pre><code class="language-${modelValue.value?.language}">${parsedContent}</code></pre>`,
      true,
    );
  },
  onUpdate: ({ editor }) => {
    if (!modelValue.value?.language && !modelValue.value?.content) {
      return;
    }

    modelValue.value.language =
      editor.getAttributes('codeBlock').language ?? modelValue.value.language;

    modelValue.value.content = props.options
      ? props.options[modelValue.value.language]
      : editor.getText();

    copyText.value = modelValue.value.content;

    if (props.options) {
      editor.commands.setContent(
        `<pre><code class="language-${modelValue.value?.language}">${modelValue.value?.content}</code></pre>`,
      );
    }

    emitFormChange();
  },
});

// Function to inject a variable into the editor content
function injectVariable({ variable, step }) {
  const variableNode = {
    type: 'variable',
    attrs: {
      id: `{{${step.slug}.${variable.key}}}`,
    },
  };

  const transaction = editor.value
    ?.chain()
    .focus()
    .insertContent(variableNode)
    .run();

  if (!transaction) {
    console.error('Failed to insert variable');
  }
}
</script>
<template>
  <ClientOnly>
    <span class="relative">
      <editor-content
        v-if="editor"
        :editor="editor"
        class="w-full"
        :style="{ '--pre-min-height': !props.options ? '15rem' : 'auto' }"
      />
      <span
        class="absolute inset-y-0 right-0 w-10 flex flex-col items-end gap-2 p-2 justify-start"
      >
        <AppCopyButton v-if="!props.editable" :text="copyText" />
        <VariablePicker
          v-else-if="injectable"
          :handle-click="injectVariable"
          type="code"
        />
      </span>
    </span>
  </ClientOnly>
</template>
<style lang="scss" scoped>
/* Basic editor styles */
:deep(.tiptap) {
  > * + * {
    margin-top: 0.75em;
  }

  [contenteditable='false']:focus {
    outline: none;
  }

  pre {
    background: #364356;
    color: #fff;
    font-family: 'JetBrainsMono', monospace;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;
    min-height: var(--pre-min-height, auto);

    code {
      color: inherit;
      padding: 0;
      background: none;
      font-size: 0.8rem;
      white-space: pre-wrap; /* Ensures that empty lines are preserved */
    }

    .hljs-comment,
    .hljs-quote {
      color: #616161;
    }

    .hljs-variable,
    .hljs-template-variable,
    .hljs-attribute,
    .hljs-tag,
    .hljs-name,
    .hljs-regexp,
    .hljs-link,
    .hljs-name,
    .hljs-selector-id,
    .hljs-selector-class {
      color: #f98181;
    }

    .hljs-number,
    .hljs-meta,
    .hljs-built_in,
    .hljs-builtin-name,
    .hljs-literal,
    .hljs-type,
    .hljs-params {
      color: #fbbc88;
    }

    .hljs-string,
    .hljs-symbol,
    .hljs-bullet {
      color: #b9f18d;
    }

    .hljs-title,
    .hljs-section {
      color: #faf594;
    }

    .hljs-keyword,
    .hljs-selector-tag {
      color: #70cff8;
    }

    .hljs-emphasis {
      font-style: italic;
    }

    .hljs-strong {
      font-weight: 700;
    }
  }
}
</style>
