import { StepDefinition } from '../../types';

export default {
  key: 'remove_contact',
  name: 'Remove Contact',
  description: 'Removes a contact from a mailing list.',
  icon: 'i-respell-instantly',
  category: 'integrations',
  services: ['coda'],
  options: {
    email: {
      key: 'email',
      name: 'Email',
      description: 'The email of the contact to remove.',
      isOptional: false,
      type: 'text/email',
      listDepth: 0,
      value: null,
    },
    listName: {
      key: 'listName',
      name: 'List',
      description: 'The mailing list to remove the contact from.',
      isOptional: false,
      type: 'text/plain',
      metadata: {
        options: {
          reference: {
            namespace: 'coda',
            member: 'list_campaigns_instantly',
          },
        },
      },
      listDepth: 0,
      value: null,
    },
  },
  outputs: {},
} satisfies StepDefinition;
