import { StepDefinition } from '../../types';

export default {
  key: 'search_apollo_people',
  name: 'Search People',
  description:
    'Searches Apollo for people matching a query. This will consume credits on your Apollo account.',
  icon: 'i-respell-apollo',
  category: 'integrations',
  services: ['apollo'],
  options: {
    query: {
      key: 'query',
      name: 'Query',
      description:
        'The search term to search people for. Functions the same as the search bar in Apollo.',
      type: 'text/plain',
      isOptional: false,
      metadata: {
        icon: 'i-ph-subtitles-bold',
      },
      listDepth: 0,
      value: null,
    },
    organization_locations: {
      key: 'organization_locations',
      name: 'Location',
      description:
        "The location of the peron's company. This will limit the results. ",
      type: 'text/plain',
      isOptional: true,
      metadata: {
        icon: 'i-ph-subtitles-bold',
      },
      listDepth: 0,
      value: null,
    },
  },
  outputs: {
    results: {
      metadata: {
        schema: {
          name: {
            key: 'name',
            name: 'Name',
            description: "The person's name.",
            type: 'text/plain',
            isOptional: false,
            metadata: {
              icon: 'i-ph-subtitles-bold',
            },
            listDepth: 0,
            value: null,
          },
          phone: {
            key: 'phone',
            name: 'Phone',
            description: "The person's phone number.",
            type: 'text/phone-number',
            isOptional: true,
            listDepth: 0,
            metadata: {
              icon: 'i-ph-phone',
            },
            value: null,
          },
          linkedin_url: {
            key: 'linkedin_url',
            name: 'LinkedIn URL',
            description: "The person's LinkedIn URL.",
            type: 'text/url',
            isOptional: true,
            listDepth: 0,
            metadata: {
              icon: 'i-ph-linkedin',
            },
            value: null,
          },
          title: {
            key: 'title',
            name: 'Title',
            description: "The person's title.",
            type: 'text/plain',
            isOptional: true,
            listDepth: 0,
            metadata: {
              icon: 'i-ph-badge',
            },
            value: null,
          },
          state: {
            key: 'state',
            name: 'State',
            description: "The person's state.",
            type: 'text/plain',
            isOptional: true,
            listDepth: 0,
            metadata: {
              icon: 'i-ph-location',
            },
            value: null,
          },
          city: {
            key: 'city',
            name: 'City',
            description: "The person's city.",
            type: 'text/plain',
            isOptional: true,
            listDepth: 0,
            metadata: {
              icon: 'i-ph-location',
            },
            value: null,
          },
          current_company: {
            key: 'current_company',
            name: 'Current Company',
            description: "The person's current company.",
            type: 'text/plain',
            isOptional: true,
            listDepth: 0,
            metadata: {
              icon: 'i-ph-building',
            },
            value: null,
          },
        },
      },
      name: 'Search Results',
      description: 'People matching your query.',
      type: 'object',
      listDepth: 1,
      isOptional: false,
      value: null,
      key: 'results',
    },
  },
} satisfies StepDefinition;
