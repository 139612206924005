import { StepDefinition } from '../../types';

export default {
  key: 'sdr_agent',
  name: 'SDR Agent',
  description:
    'The SDR Agent is a tool that helps you generate leads, qualify leads, and prospect companies.',
  icon: 'i-respell-sdr-agent',
  category: 'sdr',
  services: ['apollo'],
  options: {
    mode: {
      key: 'mode',
      name: 'Mode',
      description: 'Pick the mode you want to run the SDR Agent in.',
      type: 'text/plain',
      isOptional: false,
      listDepth: 0,
      value: 'prospect',
      metadata: {
        options: [
          {
            key: 'prospect',
            name: 'Prospect Companies',
          },
          {
            key: 'leads',
            name: 'Generate Leads',
          },
        ],
      },
    },
    numberOfCompanies: {
      key: 'numberOfCompanies',
      name: 'Number of Prospects',
      type: 'number/integer',
      description: 'The number of prospects to generate.',
      isOptional: true,
      listDepth: 0,
      conditions: [
        {
          reference: 'mode',
          type: 'equals',
          value: 'prospect',
        },
      ],
      metadata: {
        min: 1,
        max: 500,
      },
      value: 30,
    },
    numberOfLeads: {
      key: 'numberOfLeads',
      name: 'Number of Leads',
      type: 'number/integer',
      description: 'The number of leads to generate.',
      isOptional: true,
      listDepth: 0,
      conditions: [
        {
          reference: 'mode',
          type: 'equals',
          value: 'leads',
        },
      ],
      metadata: {
        min: 1,
        max: 10,
      },
      value: 5,
    },
    // Prospecting
    prospectingGoal: {
      key: 'prospectingGoal',
      name: 'Prospecting Goal',
      description: 'What is the goal of the SDR Agent?',
      type: 'text/plain',
      isOptional: true,
      conditions: [
        {
          reference: 'mode',
          type: 'equals',
          value: 'prospect',
        },
      ],
      metadata: {
        isMultiline: true,
        hint: 'Example: I want to find mid sized companies that are in the healthcare industry and have more than 100 employees.',
      },
      value: null,
      listDepth: 0,
    },
    technologies: {
      key: 'technologies',
      name: 'Technologies In Use',
      type: 'text/plain',
      isOptional: true,
      conditions: [
        {
          reference: 'mode',
          type: 'equals',
          value: 'prospect',
        },
      ],
      listDepth: 1,
      metadata: {
        isSearchable: true,
        options: {
          reference: {
            namespace: 'apollo',
            member: 'search_tags_tech',
            arguments: [
              {
                namespace: 'options',
                member: 'query',
                parameter: 'query',
              },
            ],
          },
        },
      },
      value: [],
    },
    number_of_employees: {
      key: 'number_of_employees',
      name: 'Number of Employees',
      // description: 'The number of employees in the company.',
      type: 'text/plain',
      isOptional: true,
      conditions: [
        {
          reference: 'mode',
          type: 'equals',
          value: 'prospect',
        },
      ],
      listDepth: 1,
      metadata: {
        options: [
          {
            name: '1, 10',
            key: '1,10',
          },
          {
            name: '11, 20',
            key: '11,20',
          },
          {
            name: '21, 50',
            key: '21,50',
          },
          {
            name: '51, 100',
            key: '51,100',
          },
          {
            name: '101, 200',
            key: '101,200',
          },
          {
            name: '201, 500',
            key: '201,500',
          },
          {
            name: '501, 1000',
            key: '501,1000',
          },
          {
            name: '1001, 2000',
            key: '1001,2000',
          },
          {
            name: '2001, 5000',
            key: '2001,5000',
          },
          {
            name: '5001, 10000',
            key: '5001,10000',
          },
        ],
      },
      value: [],
    },
    locations: {
      key: 'locations',
      name: 'Company Locations',
      // description: 'The location of the company.',
      type: 'text/plain',
      isOptional: true,
      conditions: [
        {
          reference: 'mode',
          type: 'equals',
          value: 'prospect',
        },
      ],
      listDepth: 1,
      metadata: {
        isSearchable: true,
        options: {
          reference: {
            namespace: 'apollo',
            member: 'search_tags_location',
            arguments: [
              {
                namespace: 'options',
                member: 'query',
                parameter: 'query',
              },
            ],
          },
        },
      },
      value: [],
    },
    hiring_for: {
      key: 'hiring_for',
      name: 'Open Positions',
      type: 'text/plain',
      isOptional: true,
      conditions: [
        {
          reference: 'mode',
          type: 'equals',
          value: 'prospect',
        },
      ],
      metadata: {
        isSearchable: true,
        options: {
          reference: {
            namespace: 'apollo',
            member: 'search_tags_title',
            arguments: [
              {
                namespace: 'options',
                member: 'query',
                parameter: 'query',
              },
            ],
          },
        },
      },
      listDepth: 1,
      value: [],
    },
    funding_stages: {
      key: 'funding_stages',
      name: 'Funding Stages',
      // description: 'The funding stages of the company.',
      type: 'text/plain',
      allowMultiple: true,
      isOptional: true,
      conditions: [
        {
          reference: 'mode',
          type: 'equals',
          value: 'prospect',
        },
      ],
      listDepth: 1,
      metadata: {
        options: [
          {
            name: 'Seed',
            value: 0,
          },
          {
            name: 'Angel',
            value: 1,
          },
          {
            name: 'Series A',
            value: 2,
          },
          {
            name: 'Series B',
            value: 3,
          },
          {
            name: 'Series C',
            value: 4,
          },
          {
            name: 'Series D',
            value: 5,
          },
          {
            name: 'Series E',
            value: 6,
          },
          {
            name: 'Series F',
            value: 7,
          },
          {
            name: 'Private Equity',
            value: 11,
          },
        ],
      },
      value: [],
    },
    industries: {
      key: 'industries',
      name: 'Company Industry',
      // description: 'The industry the company belongs to.',
      type: 'text/plain',
      isOptional: true,
      conditions: [
        {
          reference: 'mode',
          type: 'equals',
          value: 'prospect',
        },
      ],
      listDepth: 1,
      metadata: {
        isSearchable: true,
        options: {
          reference: {
            namespace: 'apollo',
            member: 'search_tags_industry',
            arguments: [
              {
                namespace: 'options',
                member: 'query',
                parameter: 'query',
              },
            ],
          },
        },
      },
      value: null,
    },

    // Generating Leads
    leadGoal: {
      key: 'leadGoal',
      name: 'Lead Goal',
      description: 'What kind of leads are we looking for?',
      type: 'text/plain',
      isOptional: true,
      conditions: [
        {
          reference: 'mode',
          type: 'equals',
          value: 'leads',
        },
      ],
      metadata: {
        isMultiline: true,
        hint: 'Example: I want to find leads that are key decision makers at the target company.',
      },
      value: null,
      listDepth: 0,
    },
    companyInput: {
      key: 'companyInput',
      name: 'Company',
      description: 'The company to generate leads for.',
      type: 'text/plain',
      isOptional: true,
      metadata: {
        variableOnly: true,
      },
      conditions: [
        {
          reference: 'mode',
          type: 'equals',
          value: 'leads',
        },
      ],
      listDepth: 0,
      value: null,
    },
  },
  outputs: {
    // Qualifying Companies
    outputs: {
      key: 'outputs',
      name: 'SDR Outputs',
      description: 'A list of the prospected leads/companies.',
      listDepth: 1,
      isOptional: false,
      type: 'object',
      conditions: [],
      value: [],
    },
  },
} satisfies StepDefinition;
