import { StepDefinition } from '../../types';

export default {
  key: 'create_appointment_highlevel',
  name: 'Create Contact',
  description: 'Creates a GoHighLevel Calendar Appointment',
  icon: 'i-respell-highlevel',
  category: 'integrations',
  services: ['highlevel'],
  options: {
    locationId: {
      name: 'Location ID',
      type: 'text/plain',
      listDepth: 0,
      metadata: {
        isSearchable: true,
        options: {
          reference: {
            namespace: 'highlevel',
            member: 'search_locations',
            arguments: [
              {
                namespace: 'options',
                member: 'query',
                parameter: 'query',
              },
            ],
          },
        },
      },
      isOptional: false,
      value: null,
      key: 'locationId',
    },
    calendarId: {
      name: 'Calendar ID',
      type: 'text/plain',
      listDepth: 0,
      isOptional: false,
      metadat: {
        options: {
          reference: {
            namespace: 'highlevel',
            member: 'list_calendars',
            arguments: [
              {
                namespace: 'options',
                member: 'locationId',
                parameter: 'location_id',
              },
            ],
          },
        },
      },
      value: null,
      key: 'calendarId',
    },
    contactId: {
      name: 'Contact ID',
      type: 'text/plain',
      listDepth: 0,
      isOptional: false,
      metadata: {
        options: {
          reference: {
            namespace: 'highlevel',
            member: 'list_contacts',
            arguments: [
              {
                namespace: 'options',
                member: 'locationId',
                parameter: 'location_id',
              },
            ],
          },
        },
      },
      value: null,
      key: 'contactId',
    },
    startTime: {
      name: 'Start Time',
      type: 'datetime',
      listDepth: 0,
      isOptional: false,
      value: null,
      key: 'startTime',
    },
    endTime: {
      name: 'End Time',
      type: 'datetime',
      listDepth: 0,
      isOptional: true,
      value: null,
      key: 'endTime',
    },
    address: {
      name: 'Address',
      type: 'text/plain',
      listDepth: 0,
      isOptional: true,
      value: null,
      key: 'address',
    },
    title: {
      name: 'Title',
      type: 'text/plain',
      listDepth: 0,
      isOptional: true,
      value: null,
      key: 'title',
    },
  },
  outputs: {},
} satisfies StepDefinition;
