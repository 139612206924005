import { StepDefinition } from '../../types';

export default {
  key: 'box_upload_file',
  name: 'Upload File',
  description: 'Uploads file to a specific directory in Box',
  icon: 'i-respell-box',
  category: 'integrations',
  services: ['box'],
  options: {
    file: {
      key: 'file',
      name: 'File',
      type: 'file/document',
      description: 'The file that you want to upload',
      isOptional: false,
      listDepth: 0,
      value: null,
    },
    folder: {
      key: 'folder',
      name: 'Folder',
      type: 'number/integer',
      description: 'The folder to which the file should be uploaded to',
      isOptional: true,
      listDepth: 0,
      metadata: {
        preventInject: true,
        options: {
          reference: {
            namespace: 'box',
            member: 'box_list_folders',
          },
        },
      },
      value: null,
    },
  },
  outputs: {},
} satisfies StepDefinition;
