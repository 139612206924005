import { StepDefinition } from '../../types';

export default {
  key: 'create_issue_linear',
  name: 'Create Issue',
  description: 'Creates a new Linear issue.',
  icon: 'i-respell-linear',
  //documentationLink: 'https://docs.respell.ai/linear',
  category: 'integrations',
  services: ['linear'],
  options: {
    title: {
      key: 'title',
      name: 'Title',
      description: 'Title of the issue.',
      type: 'text/plain',
      isOptional: false,
      metadata: {
        icon: 'i-ph-subtitles-bold',
      },
      listDepth: 0,
      value: null,
    },
    description: {
      key: 'description',
      name: 'Description',
      description:
        'Description of the issue. This is where you enter details about the task.',
      type: 'text/plain',
      isOptional: true,
      listDepth: 0,
      metadata: {
        icon: 'i-ph-text-align-left',
      },
      value: null,
    },
    teamId: {
      key: 'teamId',
      name: 'Team',
      description: 'Which team would you like Respell to use?',
      isOptional: false,
      type: 'text/plain',
      listDepth: 0,
      metadata: {
        options: {
          reference: {
            namespace: 'linear',
            member: 'get_teams',
          },
        },
      },
      value: null,
    },
  },
  outputs: {},
} satisfies StepDefinition;
