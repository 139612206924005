<script setup lang="ts">
import type { Graph, Spell, Step, Trigger } from '@respell/database';
import { GraphType } from '@respell/database';
import integrations from '@respell/integrations';
import definitions from '@respell/steps';

const props = defineProps({
  spell: {
    type: Object as () => Spell & {
      versions: (Graph & { trigger: Trigger; steps: Step[] })[];
    },
    required: true,
  },
  size: {
    type: String as () => 'm' | 'l',
    default: 'm',
  },
});

const liveGraph = computed(() =>
  props.spell?.versions?.find((v) => v.type === GraphType.live),
);

const steps = computed(() => {
  const filteredSteps =
    liveGraph.value?.steps
      ?.filter((s: Step) => !['start', 'display'].includes(s.key))
      .slice(0, 3) || [];

  return filteredSteps.length ? filteredSteps : [{ key: 'start' }];
});

const triggerService = computed(
  () => liveGraph.value?.trigger?.service as keyof typeof integrations,
);
</script>
<template>
  <div class="flex">
    <span v-if="triggerService" class="contents">
      <UIcon
        :name="integrations[triggerService]?.icon"
        class="rounded-full border-gray-50 outline outline-gray-200 border bg-white"
        :class="size === 'm' ? 'text-2xl' : 'text-4xl'"
      />
      <UIcon
        name="i-ph-lightning-fill"
        class="text-primary-500 text-sm mr-0.5"
      />
    </span>

    <div class="flex -space-x-2">
      <UIcon
        v-for="step in steps"
        :key="step.key"
        :name="definitions[step.key]?.icon"
        class="rounded-full border-gray-50 outline outline-gray-200 border bg-white"
        :class="size === 'm' ? 'text-2xl' : 'text-4xl'"
      />
    </div>
  </div>
</template>
