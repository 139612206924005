import { Integration } from '../definition';

// There's a gotcha here, api key most be passed in the header as Authorization: `Basic ${btoa(connection.accessToken + ":")}`
export default {
  key: 'greenhouse',
  name: 'Greenhouse',
  icon: 'i-respell-greenhouse',
  options: [
    {
      key: 'username',
      name: 'API Key',
      listDepth: 0,
      type: 'text/plain',
      isOptional: false,
      value: null,
    },
  ],
  postAuthOptions: [
    {
      key: 'accountId',
      description: 'Which user would you like Respell to use?',
      name: 'User',
      listDepth: 0,
      metadata: {
        options: {
          reference: {
            namespace: 'greenhouse',
            member: 'list_users',
          },
        },
      },
      type: 'text/plain',
      isOptional: false,
      value: null,
    },
  ],
} satisfies Integration;
