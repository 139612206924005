import { JsonObject } from '@respell/utils/types';
import type { Tickets as TicketRaw } from 'node-zendesk/index';
import { Model } from '../definition';

export interface Ticket extends JsonObject {
  /**
   * - Write only. The email address of the agent to assign the ticket to
   */
  assignee_email?: string;
  /**
   * - The agent currently assigned to the ticket
   */
  assignee_id?: number;
  /**
   * - Write only. An array of the IDs of attribute values to be associated with the ticket
   */
  attribute_value_ids?: number[];

  /**
   * - The ids of users currently CC'ed on the ticket
   */
  collaborator_ids?: number[];

  /**
   * - When this record was created
   */
  created_at: string;

  /**
   * - The custom ticket status id of the ticket. See custom ticket statuses
   */
  custom_status_id?: number;
  /**
   * - Read-only first comment on the ticket. When creating a ticket, use comment to set the description. See Description and first comment
   */
  description: string;

  /**
   * - If this is a ticket of type "task" it has a due date. Due date format uses ISO 8601 format.
   */
  due_at?: string;

  /**
   * - Automatically assigned when the ticket is created
   */
  id: number;

  // For dropdown
  name: string;

  /**
   * - The urgency with which the ticket should be addressed. Allowed values are "urgent", "high", "normal", or "low".
   */
  priority?: 'urgent' | 'high' | 'normal' | 'low';

  /**
   * - The original recipient e-mail address of the ticket. Notification emails for the ticket are sent from this address
   */
  recipient?: string;

  /**
   * - The state of the ticket. If your account has activated custom ticket statuses, this is the ticket's status category. See custom ticket statuses. Allowed values are "new", "open", "pending", "hold", "solved", or "closed".
   */
  status?: string;
  /**
   * - The value of the subject field for this ticket
   */
  subject?: string;

  /**
   * - The array of tags applied to this ticket
   */
  tags?: string[];

  /**
   * - The type of this ticket. Allowed values are "problem", "incident", "question", or "task".
   */
  type?: 'problem' | 'incident' | 'question' | 'task';

  /**
   * - The API url of this ticket
   */
  url: string;
}

export const TicketModel: Model<Ticket> = {
  schema: {
    // assignee_email: {
    //   key: 'assignee_email',
    //   name: 'Assignee Email',
    //   description: 'The email address of the agent to assign the ticket to',
    //   type: 'text/plain',
    //   isOptional: true,
    //   listDepth: 0,
    //   value: null,
    // },
    assignee_id: {
      key: 'assignee_id',
      name: 'Assignee ID',
      description: 'The agent currently assigned to the ticket',
      type: 'number/integer',
      isOptional: true,
      listDepth: 0,
      value: null,
    },
    // attribute_value_ids: {
    //   key: 'attribute_value_ids',
    //   name: 'Attribute Value IDs',
    //   description:
    //     'An array of the IDs of attribute values to be associated with the ticket',
    //   type: 'number/integer',
    //   isOptional: true,
    //   listDepth: 1,
    //   value: null,
    // },
    // collaborator_ids: {
    //   key: 'collaborator_ids',
    //   name: 'Collaborator IDs',
    //   description: "The ids of users currently CC'ed on the ticket",
    //   type: 'number/integer',
    //   isOptional: true,
    //   listDepth: 1,
    //   value: null,
    // },
    created_at: {
      key: 'created_at',
      name: 'Created At',
      description: 'When this record was created',
      type: 'date',
      isOptional: false,
      listDepth: 0,
      value: null,
    },
    // custom_status_id: {
    //   key: 'custom_status_id',
    //   name: 'Custom Status ID',
    //   description:
    //     'The custom ticket status id of the ticket. See custom ticket statuses',
    //   type: 'number/integer',
    //   isOptional: true,
    //   listDepth: 0,
    //   value: null,
    // },
    description: {
      key: 'description',
      name: 'Description',
      description:
        'Read-only first comment on the ticket. When creating a ticket, use comment to set the description. See Description and first comment',
      type: 'text/plain',
      isOptional: false,
      listDepth: 0,
      value: null,
    },
    due_at: {
      key: 'due_at',
      name: 'Due At',
      description:
        'If this is a ticket of type "task" it has a due date. Due date format uses ISO 8601',
      type: 'date',
      isOptional: true,
      listDepth: 0,
      value: null,
    },
    id: {
      key: 'id',
      name: 'ID',
      description: 'Automatically assigned when the ticket is created',
      type: 'number/integer',
      isOptional: false,
      listDepth: 0,
      value: null,
    },
    priority: {
      key: 'priority',
      name: 'Priority',
      description:
        'The urgency with which the ticket should be addressed. Allowed values are "urgent", "high", "normal", or "low".',
      type: 'text/plain',
      isOptional: true,
      listDepth: 0,
      value: null,
    },
    recipient: {
      key: 'recipient',
      name: 'Recipient',
      description:
        'The original recipient e-mail address of the ticket. Notification emails for the ticket are sent from this address',
      type: 'text/plain',
      isOptional: true,
      listDepth: 0,
      value: null,
    },
    status: {
      key: 'status',
      name: 'Status',
      description:
        'The state of the ticket. If your account has activated custom ticket statuses, this is the ticket\'s status category. See custom ticket statuses. Allowed values are "new", "open", "pending", "hold", "solved", or "closed".',
      type: 'text/plain',
      isOptional: true,
      listDepth: 0,
      value: null,
    },
    subject: {
      key: 'subject',
      name: 'Subject',
      description: 'The value of the subject field for this ticket',
      type: 'text/plain',
      isOptional: true,
      listDepth: 0,
      value: null,
    },
    tags: {
      key: 'tags',
      name: 'Tags',
      description: 'The array of tags applied to this ticket',
      type: 'text/plain',
      isOptional: true,
      listDepth: 1,
      value: null,
    },
    type: {
      key: 'type',
      name: 'Type',
      description:
        'The type of this ticket. Allowed values are "problem", "incident", "question", or "task".',
      type: 'text/plain',
      isOptional: true,
      listDepth: 0,
      value: null,
    },
    url: {
      key: 'url',
      name: 'URL',
      description: 'The API url of this ticket',
      type: 'text/plain',
      isOptional: false,
      listDepth: 0,
      value: null,
    },
  },
  parse(data: unknown): Ticket {
    const value = data as TicketRaw.ResponseModel;

    return {
      // assignee_email: value.assignee_email,
      name: `Ticket #${value.id}`,
      assignee_id: value.assignee_id ?? undefined,
      // attribute_value_ids: value.attribute_value_ids,
      // collaborator_ids: value.collaborator_ids,
      created_at: value.created_at,
      // custom_status_id: value.custom_status_id,
      description: value.description,
      due_at: value.due_at ?? undefined,
      id: value.id,
      priority: value.priority ?? undefined,
      recipient: value.recipient ?? undefined,
      status: value.status,
      subject: value.subject ?? undefined,
      tags: (value.tags as string[]) ?? undefined,
      type: value.type ?? undefined,
      url: value.url,
    };
  },
};
/**
 * Represents a comment in the Zendesk system
 */
export interface Comment extends JsonObject {
  body?: string; // The body content of the comment
  created_at: string; // The creation date of the comment
  id: number; // The unique ID of the comment
  metadata?: string; // Additional metadata of the comment
  public?: boolean; // Indicates if the comment is public
  type: 'Comment' | 'VoiceComment'; // The type of the comment
}

export const CommentModel: Model<Comment> = {
  schema: {
    body: {
      key: 'body',
      name: 'Body',
      description: 'The body content of the comment',
      type: 'text/plain',
      isOptional: true,
      listDepth: 0,
      value: null,
    },
    metadata: {
      key: 'metadata',
      name: 'Metadata',
      description: 'The metadata of the comment',
      type: 'text/plain',
      isOptional: true,
      listDepth: 0,
      value: null,
    },
    public: {
      key: 'public',
      name: 'Public',
      description: 'Indicates if the comment is public',
      type: 'boolean',
      isOptional: true,
      listDepth: 0,
      value: null,
    },
    created_at: {
      key: 'created_at',
      name: 'Created At',
      description: 'The creation date of the comment',
      type: 'text/plain',
      isOptional: false,
      listDepth: 0,
      value: null,
    },
    type: {
      key: 'type',
      name: 'Type',
      description: 'The type of the comment',
      type: 'text/plain',
      isOptional: false,
      listDepth: 0,
      value: null,
    },
    id: {
      key: 'id',
      name: 'ID',
      description: 'The unique ID of the comment',
      type: 'number/integer',
      isOptional: false,
      listDepth: 0,
      value: null,
    },
  },
  parse(data: unknown): Comment {
    const value = data as TicketRaw.Comments.ResponseModel;

    return {
      id: value.id,
      type: value.type,
      body: value.body,
      metadata: JSON.stringify(value.metadata),
      created_at: value.created_at,
      public: value.public,
    };
  },
};
