import { StepDefinition } from '../../types';

export default {
  key: 'text_to_list',
  name: 'Text To List',
  description: 'Converts text input into a list using AI.',
  icon: 'i-respell-classify-text',
  category: 'text',
  options: {
    text: {
      key: 'text',
      name: 'Input Text',
      description: 'The text to be converted into a list.',
      metadata: {
        variableOnly: true,
      },
      isOptional: false,
      type: 'text/plain',
      listDepth: 0,
      value: null,
    },
    instructions: {
      key: 'instructions',
      name: 'Instructions',
      description:
        'Tell us how you want to break up your text into a list. For example, you could say "Break this into separate sentences" or "Pull out all the product names".',
      metadata: {
        hint: '',
        max: 8000,
        isMultiline: true,
      },
      isOptional: true,
      type: 'text/plain',
      listDepth: 0,
      value: null,
    },
  },
  outputs: {
    list: {
      key: 'list',
      name: 'List Output',
      description:
        'Your text broken up into separate items based on your instructions.',
      type: 'object',
      listDepth: 1,
      isOptional: false,
      value: null,
    },
  },
} satisfies StepDefinition;
