/* eslint-disable prettier/prettier */
import { StepDefinition } from '../../types';

export default {
  key: 'wait',
  name: 'Wait',
  description: 'Pauses the spell run for a configurable amount of time.',
  documentationLink: 'https://docs.respell.ai/steps-reference/flow-tools/wait',
  icon: 'i-respell-wait',
  category: 'flow',
  options: {
    amount: {
      key: 'amount',
      name: 'Amount',
      description:
        'How long the the step should wait before being marked as complete and continuing forward.',
      listDepth: 0,
      type: 'number/integer',
      isOptional: false,
      metadata: {
        hint: '5',
      },
      value: 5,
    },
    units: {
      key: 'units',
      name: 'Wait Time Unit',
      description: 'Minutes / Hours / Days / Weeks',
      isOptional: false,
      type: 'text/plain',
      listDepth: 0,
      metadata: {
        options: [
          {
            key: 'minutes',
            name: 'Minutes',
          },
          {
            key: 'hours',
            name: 'Hours',
          },
          {
            key: 'days',
            name: 'Days',
          },
          {
            key: 'weeks',
            name: 'Weeks',
          },
        ],
      },
      value: 'minutes',
    },
  },
  outputs: {
    waitTime: {
      key: 'waitTime',
      name: 'Waited Time',
      description: 'The amount of time the step waited.',
      listDepth: 0,
      type: 'text/plain',
      isOptional: false,
      value: null,
    },
  },
} satisfies StepDefinition;
