import { StepDefinition } from '../../types';

export default {
  key: 'attio_list_deals',
  name: 'List deals',
  description: 'Lists all deal records contained within Attio',
  icon: 'i-respell-attio',
  category: 'integrations',
  services: ['attio'],
  options: {},
  outputs: {
    deals: {
      key: 'deals',
      name: 'Deals',
      type: 'object',
      listDepth: 1,
      isOptional: false,
      value: null,
    },
  },
} satisfies StepDefinition;
