<script setup lang="ts">
import AppTextEditor, {
  type TextEditorProps,
} from '~/components/app/AppTextEditor.vue';

// Define component props with default values
const props = defineProps<TextEditorProps & { text: string }>();

const emit = defineEmits(['close']);
const modal = useModal();

const card = ref<HTMLElement | null>(null);
const liveText = ref(props.text);

function handleClose() {
  emit('close', liveText.value);
  modal.close();
}

onClickOutside(card, () => {
  close();
});
</script>
<template>
  <UModal
    prevent-close
    :ui="{
      height: 'h-[90vh]',
      width: 'sm:max-w-4xl',
      rounded: 'rounded-2xl',
    }"
  >
    <UCard
      ref="card"
      :ui="{
        base: 'w-full h-full pb-14',
        rounded: 'rounded-2xl',
        body: { base: 'h-full w-full', padding: '!p-0' },
        header: {
          base: 'flex justify-start items-baseline',
          padding: 'pb-4',
        },
      }"
    >
      <template #header>
        <p class="title shrink-0">{{ title }}</p>
        <p class="caption truncate px-4 shrink">{{ description }}</p>
        <UButton
          :padded="false"
          color="gray"
          variant="link"
          icon="i-ph-x"
          class="ml-auto"
          @click="handleClose"
        />
      </template>

      <AppTextEditor
        v-model="liveText"
        v-bind="props"
        expanded
        :show-wizard="false"
        class="w-full"
      />
    </UCard>
  </UModal>
</template>
