import { StepDefinition } from '../../types';

export default {
  key: 'calendly_list_events',
  name: 'List events',
  description: 'Returns a list of Events.',
  icon: 'i-respell-calendly',
  category: 'integrations',
  services: ['calendly'],
  options: {
    min_start_date: {
      key: 'min_start_date',
      name: 'Start Date',
      isOptional: false,
      type: 'date',
      description: 'The earliest date that you should start searching from',
      listDepth: 0,
      // value: Date.now(),
      value: null,
    },
    max_start_date: {
      key: 'max_start_date',
      name: 'End Date',
      isOptional: false,
      type: 'date',
      description: 'The last date which should be searched for',
      listDepth: 0,
      // tomorrow in ms
      // value: new Date(Date.now() + 86400000),
      value: null,
    },
  },
  outputs: {
    event: {
      key: 'event',
      name: 'Event',
      type: 'object',
      isOptional: false,
      listDepth: 1,
      // This is ridiculously verbose:
      // https://developer.calendly.com/api-docs/2d5ed9bbd2952-list-events
      metadata: {
        schema: {
          uri: {
            key: 'uri',
            name: 'Url',
            type: 'text/url',
            isOptional: false,
            listDepth: 0,
            value: null,
          },
          name: {
            key: 'name',
            name: 'Name',
            type: 'text/plain',
            isOptional: false,
            listDepth: 0,
            value: null,
          },
          status: {
            key: 'status',
            name: 'Status',
            type: 'text/plain',
            isOptional: false,
            listDepth: 0,
            value: null,
          },
          meeting_notes_plain: {
            key: 'meeting_notes_plain',
            name: 'Meeting Notes Plain',
            type: 'text/plain',
            isOptional: true,
            listDepth: 0,
            value: null,
          },
          start_time: {
            key: 'start_time',
            name: 'Start Time',
            type: 'text/plain',
            isOptional: false,
            listDepth: 0,
            value: null,
          },
          end_time: {
            key: 'end_time',
            name: 'End Time',
            type: 'text/plain',
            isOptional: false,
            listDepth: 0,
            value: null,
          },
          location: {
            key: 'location',
            name: 'Location',
            type: 'text/plain',
            isOptional: false,
            listDepth: 0,
            value: null,
          },
        },
      },
      value: null,
    },
  },
} satisfies StepDefinition;
