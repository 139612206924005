import { StepDefinition } from '../../types';

export default {
  key: 'create_ticket',
  name: 'Create Ticket',
  description: 'Creates a ticket.',
  icon: 'i-logos-intercom-icon',
  category: 'integrations',
  services: ['intercom'],
  options: {
    ticket_type_id: {
      key: 'ticket_type_id',
      name: 'Type',
      description: 'The type of the ticket.',
      type: 'text/plain',
      isOptional: false,
      metadata: {
        icon: 'i-ph-text-align-left',
        options: {
          reference: {
            namespace: 'intercom',
            member: 'list_ticket_types',
          },
        },
      },
      listDepth: 0,
      value: null,
    },
    body: {
      key: 'body',
      name: 'Body',
      description: 'The body of the ticket.',
      type: 'text/plain',
      isOptional: false,
      metadata: {
        icon: 'i-ph-text-align-left',
      },
      listDepth: 0,
      value: null,
    },
    contact: {
      key: 'contact',
      name: 'Contact',
      description:
        'The email you have defined for the contact who is being added as a participant. If a contact with this email does not exist, one will be created.',
      type: 'text/plain',
      isOptional: false,
      metadata: {
        icon: 'i-ph-text-align-left',
      },
      listDepth: 0,
      value: null,
    },

    title: {
      key: 'title',
      name: 'Title',
      description: 'The title of the ticket.',
      type: 'text/plain',
      isOptional: false,
      metadata: {
        icon: 'i-ph-text-align-left',
      },
      listDepth: 0,
      value: null,
    },
  },
  outputs: {},
} satisfies StepDefinition;
