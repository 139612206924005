import { StepDefinition } from '../../types';

export default {
  key: 'enrich_pdl_organization',
  name: 'Enrich Organization',
  description:
    'Provides more information about a specific organization (enriching) given some unique identifiers.',
  icon: 'i-respell-peopledatalabs',
  category: 'integrations',
  services: ['peopledatalabs'],
  options: {
    domain: {
      key: 'domain',
      name: 'Domain',
      description: 'Domain of the organization.',
      type: 'text/plain',
      isOptional: false,
      listDepth: 0,
      metadata: {
        icon: 'i-ph-globe',
      },
      value: null,
    },
  },
  outputs: {
    organization: {
      name: 'Enriched Organization Details',
      type: 'object',
      description: 'The enriched organization details.',
      listDepth: 0,
      isOptional: false,
      metadata: {
        schema: {
          name: {
            key: 'name',
            name: 'Name',
            description: 'The name of the organization.',
            type: 'text/plain',
            isOptional: false,
            metadata: {
              icon: 'i-ph-subtitles-bold',
            },
            listDepth: 0,
            value: null,
          },
          website_url: {
            key: 'website_url',
            name: 'Website',
            description: 'The website URL of the organization.',
            type: 'text/plain',
            isOptional: false,
            metadata: {
              icon: 'i-ph-subtitles-bold',
            },
            listDepth: 0,
            value: null,
          },
          linkedin_url: {
            key: 'linkedin_url',
            name: 'LinkedIn URL',
            description: 'The LinkedIn URL of the organization.',
            type: 'text/plain',
            isOptional: false,
            metadata: {
              icon: 'i-ph-subtitles-bold',
            },
            listDepth: 0,
            value: null,
          },
          twitter_url: {
            key: 'twitter_url',
            name: 'Twitter URL',
            description: 'The Twitter URL of the organization.',
            type: 'text/plain',
            isOptional: false,
            metadata: {
              icon: 'i-ph-subtitles-bold',
            },
            listDepth: 0,
            value: null,
          },
          facebook_url: {
            key: 'facebook_url',
            name: 'Facebook URL',
            description: 'The Facebook URL of the organization.',
            type: 'text/plain',
            isOptional: false,
            metadata: {
              icon: 'i-ph-subtitles-bold',
            },
            listDepth: 0,
            value: null,
          },
          phone: {
            key: 'phone',
            name: 'Phone',
            description: 'The phone number of the organization.',
            type: 'text/plain',
            isOptional: false,
            metadata: {
              icon: 'i-ph-subtitles-bold',
            },
            listDepth: 0,
            value: null,
          },
          founded_year: {
            key: 'founded_year',
            name: 'Founded Year',
            description: 'The year the organization was founded.',
            type: 'text/plain',
            isOptional: false,
            metadata: {
              icon: 'i-ph-subtitles-bold',
            },
            listDepth: 0,
            value: null,
          },
        },
      },
      value: null,
      key: 'organization',
    },
  },
} satisfies StepDefinition;
