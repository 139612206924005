import { Integration } from '../definition';

export default {
  key: 'skyvern',
  name: 'Skyvern',
  icon: 'i-respell-skyvern',
  options: [
    {
      key: 'apiKey',
      name: 'API Key',
      isOptional: false,
      type: 'text/plain',
      listDepth: 0,
      metadata: {},
      value: null,
    },
  ],
  postAuthOptions: [],
  metadataOverride: {},
} satisfies Integration;
