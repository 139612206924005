import { TriggerDefinition } from '../../../definition';

export default {
  key: 'new_ticket_zd',
  name: 'New Ticket',
  description: 'A new ticket is created.',
  icon: 'i-respell-zendesk',
  options: {},
  outputs: {
    ticket: {
      metadata: {
        schema: {
          account: {
            key: 'account',
            name: 'Account',
            type: 'text/plain',
            isOptional: false,
            listDepth: 0,
            value: null,
          },
          assignee_name: {
            key: 'assignee_name',
            name: 'Assignee Name',
            type: 'text/plain',
            isOptional: false,
            listDepth: 0,
            value: null,
          },
          brand_name: {
            key: 'brand_name',
            name: 'Brand Name',
            type: 'text/plain',
            isOptional: false,
            listDepth: 0,
            value: null,
          },
          created_at: {
            key: 'created_at',
            name: 'Created At',
            type: 'text/plain',
            isOptional: false,
            listDepth: 0,
            value: null,
          },
          description: {
            key: 'description',
            name: 'Description',
            type: 'text/plain',
            isOptional: false,
            listDepth: 0,
            value: null,
          },
          due_date: {
            key: 'due_date',
            name: 'Due Date',
            type: 'text/plain',
            isOptional: false,
            listDepth: 0,
            value: null,
          },
          id: {
            key: 'id',
            name: 'ID',
            type: 'number/integer',
            isOptional: false,
            listDepth: 0,
            value: null,
          },
          link: {
            key: 'link',
            name: 'Link',
            type: 'text/plain',
            isOptional: false,
            listDepth: 0,
            value: null,
          },
          organization_name: {
            key: 'organization_name',
            name: 'Organization Name',
            type: 'text/plain',
            isOptional: false,
            listDepth: 0,
            value: null,
          },
          priority: {
            key: 'priority',
            name: 'Priority',
            type: 'text/plain',
            isOptional: false,
            listDepth: 0,
            value: null,
          },
          requester_name: {
            key: 'requester_name',
            name: 'Requester Name',
            type: 'text/plain',
            isOptional: false,
            listDepth: 0,
            value: null,
          },
          requester_email: {
            key: 'requester_email',
            name: 'Requester Email',
            type: 'text/plain',
            isOptional: false,
            listDepth: 0,
            value: null,
          },
          status: {
            key: 'status',
            name: 'Status',
            type: 'text/plain',
            isOptional: false,
            listDepth: 0,
            value: null,
          },
          tags: {
            key: 'tags',
            name: 'Tags',
            type: 'text/plain',
            isOptional: false,
            listDepth: 0,
            value: null,
          },
          title: {
            key: 'title',
            name: 'Title',
            type: 'text/plain',
            isOptional: false,
            listDepth: 0,
            value: null,
          },
          url: {
            key: 'url',
            name: 'URL',
            type: 'text/plain',
            isOptional: false,
            listDepth: 0,
            value: null,
          },
          verbatim_description: {
            key: 'verbatim_description',
            name: 'Verbatim Description',
            type: 'text/plain',
            isOptional: false,
            listDepth: 0,
            value: null,
          },
          via: {
            key: 'via',
            name: 'Via',
            type: 'text/plain',
            isOptional: false,
            listDepth: 0,
            value: null,
          },
          incoming_phone_number_ID: {
            key: 'incoming_phone_number_ID',
            name: 'Incoming Phone Number ID',
            type: 'text/plain',
            isOptional: false,
            listDepth: 0,
            value: null,
          },
        },
      },
      name: 'Ticket',
      type: 'object',
      listDepth: 0,
      isOptional: false,
      value: null,
      key: 'ticket',
    },
  },
} satisfies TriggerDefinition;
