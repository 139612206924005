import { SubscriptionType } from '@respell/database';
import { isProd } from '@respell/utils/config';

export const taskCost = 0.015;

export const stripeStarterMonthlyPriceId = isProd
  ? 'price_1PRLZvAX72UmFuzdP3JcGKn3'
  : 'price_1PORUEAX72UmFuzd6moPDwto';
export const stripeStarterYearlyPriceId = isProd
  ? 'price_1PRLZvAX72UmFuzdR8IUPajm'
  : 'price_1PORV2AX72UmFuzdiVX5gz0U';
export const stripeTeamMonthlyPriceId = isProd
  ? 'price_1PRLbLAX72UmFuzdgnADoMTM'
  : 'price_1PORfBAX72UmFuzdxAq7ODqA';
export const stripeTeamYearlyPriceId = isProd
  ? 'price_1PRgnTAX72UmFuzd2xjOblNI'
  : 'price_1PORfNAX72UmFuzddNLSkNsB';
export const stripeMonthlyTaskPriceId = isProd
  ? 'price_1PxaYfAX72UmFuzd3XFM8Bt2'
  : 'price_1Pv4nJAX72UmFuzdC23uitOc';
export const stripeYearlyTaskPriceId = isProd
  ? 'price_1PxaYuAX72UmFuzdOb9d0QtW'
  : 'price_1PxJhxAX72UmFuzdZiVbbIf0';

export const stripePriceIdToSubscriptionType = {
  [stripeStarterMonthlyPriceId]: SubscriptionType.starter,
  [stripeStarterYearlyPriceId]: SubscriptionType.starter,
  [stripeTeamMonthlyPriceId]: SubscriptionType.team,
  [stripeTeamYearlyPriceId]: SubscriptionType.team,
};

export const subscriptionTypeToMonthlyActionLimit = (
  subscriptionType: SubscriptionType,
): number | null => {
  switch (subscriptionType) {
    case SubscriptionType.free:
      return 125;
    case SubscriptionType.starter:
      return 750;
    case SubscriptionType.team:
      return 2500;
    case SubscriptionType.enterprise:
      return null;
    default:
      return 25;
  }
};

export const stripeProductNameToSubscriptionType = (
  stripeProductName: string,
): SubscriptionType | undefined => {
  switch (stripeProductName) {
    case 'Team': // v1 Legacy
    case 'Pro Plan': // v1 Legacy
    case 'Free Plan': // v1 Legacy
    case 'Free': // v1 Legacy
      return SubscriptionType.legacy;
    case 'Starter Plan':
    case 'Starter Plan (V2)': // Staging and Development
      return SubscriptionType.starter;
    case 'Team Plan':
    case 'Team Plan (V2)': // Staging and Development
      return SubscriptionType.team;
    default:
      return SubscriptionType.unknown;
  }
};

export interface SubscriptionDetail {
  type?: SubscriptionType;
  shortName: string;
  longName: string;
  icon: string;
  description?: string;
  marketingTitle?: string;
  marketingItems?: string[];
  subscriptionPriceIds?: {
    monthly: string;
    yearly: string;
  };
  subscriptionPricePerMonth?: {
    monthly: number;
    yearly: number;
  };
  isEnterprise: boolean;
  highlighted: boolean;
}

export const subscriptionDetails: Record<SubscriptionType, SubscriptionDetail> =
  {
    free: {
      shortName: 'Free',
      longName: 'Respell Free',
      icon: 'i-ph-gift',
      isEnterprise: false,
      highlighted: false,
    },
    starter: {
      type: SubscriptionType.starter,
      shortName: 'Starter',
      longName: 'Respell Starter',
      icon: 'i-ph-lightning',
      description: 'For power users who need advanced automations',
      marketingTitle: 'Everything in Free, plus:',
      marketingItems: [
        '3 builder licenses',
        'Build spells with all steps and premium integrations (Instagram and LinkedIn)',
        'Build spells with our Research Agent and Bland Phone Calling Agent',
        'Use your team’s Knowledge Bases in spells with Data Sources',
      ],
      subscriptionPriceIds: {
        monthly: stripeStarterMonthlyPriceId,
        yearly: stripeStarterYearlyPriceId,
      },
      subscriptionPricePerMonth: {
        monthly: 19.99,
        yearly: 14.99,
      },
      isEnterprise: false,
      highlighted: false,
    },
    team: {
      type: SubscriptionType.team,
      shortName: 'Team',
      longName: 'Respell Team',
      icon: 'i-ph-users',
      description: 'For corporate teams building AI automations',
      marketingTitle: 'Everything in Starter, plus:',
      marketingItems: [
        'Unlimited builder licenses',
        'Build spells with all steps and premium integrations (Instagram and LinkedIn)',
        'Build spells with our Research Agent and Bland Call Agent',
        'Use your team’s Knowledge Bases in spells with Data Sources',
        'Respell Inbox - access to Human Review step to review your Agents’ work',
      ],
      subscriptionPriceIds: {
        monthly: stripeTeamMonthlyPriceId,
        yearly: stripeTeamYearlyPriceId,
      },
      subscriptionPricePerMonth: {
        monthly: 59.99,
        yearly: 44.99,
      },
      isEnterprise: false,
      highlighted: true,
    },
    enterprise: {
      type: SubscriptionType.enterprise,
      shortName: 'Enterprise',
      longName: 'Respell Enterprise',
      icon: 'i-ph-globe',
      description: 'For companies with high-volume workflows',
      subscriptionPricePerMonth: {
        monthly: 0,
        yearly: 0,
      },

      isEnterprise: true,
      marketingTitle: 'Everything in Team, plus:',
      marketingItems: [
        'Uncapped Tasks',
        'SDR Agent',
        'No code Agent Studio - build all of your own Agents',
        'Build spells with our Research Agent and Bland Call Agent',
        'Advanced security and compliance controls, including SOC2',
        'Prompt Injection Prevention, PII & Sensitive Data, and Data Retention controls',
      ],
      highlighted: false,
    },
    unknown: {
      shortName: 'Unknown',
      longName: 'Unknown Plan',
      icon: 'i-ph-asterisk',
      isEnterprise: false,
      highlighted: false,
    },
    legacy: {
      shortName: 'Legacy',
      longName: 'Respell Legacy',
      icon: 'i-ph-asterisk',
      isEnterprise: false,
      highlighted: false,
    },
  };

const baseFileSizeLimits: Record<string, string> = {
  'file/audio': '20MB',
  'file/document': '20MB',
  'file/image': '10MB',
  'file/spreadsheet': '20MB',
  'file/video': '100MB',
};

export const subscriptionTypeToFileSizeLimits: Record<
  SubscriptionType,
  Record<string, string>
> = {
  free: baseFileSizeLimits,
  starter: {
    ...baseFileSizeLimits,
    'file/audio': '100MB',
    'file/video': '1000MB', // 1GB
  },
  team: {
    ...baseFileSizeLimits,
    'file/audio': '100MB',
    'file/video': '1000MB', // 1GB
  },
  enterprise: {
    ...baseFileSizeLimits,
    'file/audio': '100MB',
    'file/video': '1000MB', // 1GB
  },
  unknown: baseFileSizeLimits,
  legacy: baseFileSizeLimits,
};
