import { StepDefinition } from '../../types';

export default {
  key: 'describe_image',
  name: 'Describe Image',
  description: 'Describes an image using AI.',
  icon: 'i-respell-describe-image',
  documentationLink:
    'https://docs.respell.ai/steps-reference/file-tools/describe-image',
  category: 'file',
  options: {
    image: {
      key: 'image',
      name: 'Image',
      description: 'The input image to describe. ',
      isOptional: false,
      type: 'file/image',
      listDepth: 0,
      value: null,
    },
    question: {
      key: 'question',
      name: 'Question',
      description: 'The question you want to ask about the image. ',
      isOptional: false,
      metadata: {
        max: 4096,
      },
      type: 'text/plain',
      listDepth: 0,
      value: 'Describe this image.',
    },
  },
  outputs: {
    response: {
      key: 'response',
      name: 'Response',
      description: 'The description of the image.',
      type: 'text/plain',
      listDepth: 0,
      isOptional: false,
      value: null,
    },
  },
} satisfies StepDefinition;
