import { StepDefinition } from '../../types';

export default {
  key: 'extract_fields',
  name: 'Extract Fields',
  description: 'Extract fields from text data using AI.',
  icon: 'i-respell-extract-fields',
  category: 'text',
  options: {
    input: {
      key: 'input',
      name: 'Input',
      description: 'The input data to extract fields from.',
      isOptional: false,
      type: 'text/plain',
      listDepth: 0,
      metadata: {
        max: 128000,
        isMultiline: true,
      },
      value: '',
    },
    fields: {
      key: 'fields',
      name: 'Fields',
      description: 'The fields to extract from the input data.',
      isOptional: false,
      type: 'object',
      metadata: {
        schema: {
          name: {
            key: 'name',
            listDepth: 0,
            isOptional: false,
            value: null,
            type: 'text/plain',
            name: 'Field Name',
          },
          description: {
            key: 'description',
            listDepth: 0,
            isOptional: true,
            value: null,
            type: 'text/plain',
            name: 'Field Description',
          },
          type: {
            key: 'type',
            listDepth: 0,
            isOptional: false,
            value: null,
            type: 'text/plain',
            metadata: {
              preventInject: true,
              options: [
                {
                  key: 'text/plain',
                  name: 'Text',
                },
                {
                  key: 'boolean',
                  name: 'True/False',
                },
              ],
            },
            name: 'Field Type',
          },
        },
      },
      listDepth: 1,
      value: [],
    },
    instructions: {
      key: 'instructions',
      name: 'Instructions',
      description:
        'Optional additional instructions for how to extract the fields.',
      isOptional: true,
      type: 'text/plain',
      listDepth: 0,
      metadata: {
        max: 128000,
        isMultiline: true,
      },
      value: null,
    },
  },
  outputs: {
    field_outputs: {
      key: 'field_outputs',
      name: 'Field Outputs',
      type: 'object',
      listDepth: 0,
      metadata: {
        schema: {
          reference: {
            member: 'extract_fields',
            arguments: [
              {
                namespace: 'options',
                member: 'fields',
                parameter: 'fields',
              },
            ],
          },
        },
      },
      isOptional: false,
      value: null,
    },
  },
} satisfies StepDefinition;
