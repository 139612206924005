import { StepDefinition } from '../../types';

export default {
  key: 'attio_list_users',
  name: 'List users',
  description: 'Lists all user records contained within Attio',
  icon: 'i-respell-attio',
  category: 'integrations',
  services: ['attio'],
  options: {},
  outputs: {
    users: {
      key: 'users',
      name: 'users',
      type: 'object',
      listDepth: 1,
      isOptional: false,
      value: null,
    },
  },
} satisfies StepDefinition;
