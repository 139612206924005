import { StepDefinition } from '../../types';

export default {
  key: 'send_campaign',
  name: 'Launch Campaign',
  description: 'Launches a campaign',
  icon: 'i-respell-instantly',
  category: 'integrations',
  services: ['coda'],
  options: {
    campaign_id: {
      key: 'campaign_id',
      name: 'List',
      description: 'The campaign to launch.',
      type: 'text/plain',
      isOptional: false,
      listDepth: 0,
      metadata: {
        icon: 'i-ph-text-align-left',
        options: {
          reference: {
            namespace: 'coda',
            member: 'list_campaigns_instantly',
          },
        },
      },
      value: null,
    },
  },
  outputs: {},
} satisfies StepDefinition;
