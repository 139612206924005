import { Integration } from '../definition.js';

export default {
  key: 'mailchimp',
  name: 'Mailchimp',
  icon: 'i-respell-mailchimp',
  postAuthCaption:
    'To find the value for the server prefix log into your Mailchimp account and look at the URL in your browser. You’ll see something like https://us19.admin.mailchimp.com/; the us19 part is the server prefix. Note that your specific value may be different.',
  postAuthOptions: [
    {
      key: 'url',
      name: 'Server Prefix',
      type: 'text/plain',
      description: 'The prefix of the Mailchimp server.',
      isOptional: false,
      listDepth: 0,
      value: null,
    },
  ],
} satisfies Integration;
