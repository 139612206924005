import { defineNuxtPlugin } from '#app/nuxt'
import { LazyEmailFooter, LazyEmailHeader, LazyEmailTemplate, LazyInviteToWorkspace, LazyLogin, LazyProseA, LazyProseBlockquote, LazyProseCode, LazyProseEm, LazyProseH1, LazyProseH2, LazyProseH3, LazyProseH4, LazyProseH5, LazyProseH6, LazyProseHr, LazyProseImg, LazyProseLi, LazyProseOl, LazyProseP, LazyProsePre, LazyProseScript, LazyProseStrong, LazyProseTable, LazyProseTbody, LazyProseTd, LazyProseTh, LazyProseThead, LazyProseTr, LazyProseUl, LazyUAccordion, LazyUAlert, LazyUAvatar, LazyUAvatarGroup, LazyUBadge, LazyUButton, LazyUButtonGroup, LazyUCarousel, LazyUChip, LazyUDropdown, LazyUIcon, LazyUKbd, LazyULink, LazyUMeter, LazyUMeterGroup, LazyUProgress, LazyUCheckbox, LazyUForm, LazyUFormGroup, LazyUInput, LazyUInputMenu, LazyURadio, LazyURadioGroup, LazyURange, LazyUSelect, LazyUSelectMenu, LazyUTextarea, LazyUToggle, LazyUTable, LazyUCard, LazyUContainer, LazyUDivider, LazyUSkeleton, LazyUBreadcrumb, LazyUCommandPalette, LazyUCommandPaletteGroup, LazyUHorizontalNavigation, LazyUPagination, LazyUTabs, LazyUVerticalNavigation, LazyUContextMenu, LazyUModal, LazyUModals, LazyUNotification, LazyUNotifications, LazyUPopover, LazyUSlideover, LazyUSlideovers, LazyUTooltip, LazyIcon } from '#components'
const lazyGlobalComponents = [
  ["EmailFooter", LazyEmailFooter],
["EmailHeader", LazyEmailHeader],
["EmailTemplate", LazyEmailTemplate],
["InviteToWorkspace", LazyInviteToWorkspace],
["Login", LazyLogin],
["ProseA", LazyProseA],
["ProseBlockquote", LazyProseBlockquote],
["ProseCode", LazyProseCode],
["ProseEm", LazyProseEm],
["ProseH1", LazyProseH1],
["ProseH2", LazyProseH2],
["ProseH3", LazyProseH3],
["ProseH4", LazyProseH4],
["ProseH5", LazyProseH5],
["ProseH6", LazyProseH6],
["ProseHr", LazyProseHr],
["ProseImg", LazyProseImg],
["ProseLi", LazyProseLi],
["ProseOl", LazyProseOl],
["ProseP", LazyProseP],
["ProsePre", LazyProsePre],
["ProseScript", LazyProseScript],
["ProseStrong", LazyProseStrong],
["ProseTable", LazyProseTable],
["ProseTbody", LazyProseTbody],
["ProseTd", LazyProseTd],
["ProseTh", LazyProseTh],
["ProseThead", LazyProseThead],
["ProseTr", LazyProseTr],
["ProseUl", LazyProseUl],
["UAccordion", LazyUAccordion],
["UAlert", LazyUAlert],
["UAvatar", LazyUAvatar],
["UAvatarGroup", LazyUAvatarGroup],
["UBadge", LazyUBadge],
["UButton", LazyUButton],
["UButtonGroup", LazyUButtonGroup],
["UCarousel", LazyUCarousel],
["UChip", LazyUChip],
["UDropdown", LazyUDropdown],
["UIcon", LazyUIcon],
["UKbd", LazyUKbd],
["ULink", LazyULink],
["UMeter", LazyUMeter],
["UMeterGroup", LazyUMeterGroup],
["UProgress", LazyUProgress],
["UCheckbox", LazyUCheckbox],
["UForm", LazyUForm],
["UFormGroup", LazyUFormGroup],
["UInput", LazyUInput],
["UInputMenu", LazyUInputMenu],
["URadio", LazyURadio],
["URadioGroup", LazyURadioGroup],
["URange", LazyURange],
["USelect", LazyUSelect],
["USelectMenu", LazyUSelectMenu],
["UTextarea", LazyUTextarea],
["UToggle", LazyUToggle],
["UTable", LazyUTable],
["UCard", LazyUCard],
["UContainer", LazyUContainer],
["UDivider", LazyUDivider],
["USkeleton", LazyUSkeleton],
["UBreadcrumb", LazyUBreadcrumb],
["UCommandPalette", LazyUCommandPalette],
["UCommandPaletteGroup", LazyUCommandPaletteGroup],
["UHorizontalNavigation", LazyUHorizontalNavigation],
["UPagination", LazyUPagination],
["UTabs", LazyUTabs],
["UVerticalNavigation", LazyUVerticalNavigation],
["UContextMenu", LazyUContextMenu],
["UModal", LazyUModal],
["UModals", LazyUModals],
["UNotification", LazyUNotification],
["UNotifications", LazyUNotifications],
["UPopover", LazyUPopover],
["USlideover", LazyUSlideover],
["USlideovers", LazyUSlideovers],
["UTooltip", LazyUTooltip],
["Icon", LazyIcon],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
