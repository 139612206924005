import { StepDefinition } from '../../types';

export default {
  key: 'list_to_text',
  name: 'List To Text',
  description: 'Converts a list input into a single text output using AI.',
  icon: 'i-respell-classify-text',
  category: 'text',
  options: {
    input: {
      key: 'input',
      name: 'Input List',
      description: 'The list to be converted into text.',
      metadata: {
        variableOnly: true,
      },
      isOptional: false,
      type: 'object',
      listDepth: 0,
      value: null,
    },
    instructions: {
      key: 'instructions',
      name: 'Instructions',
      description:
        'Optional guidance on how you want the list items combined into text. For example, "Join with commas" or "Create complete sentences".',
      metadata: {
        hint: 'Specify how to format the list items into a cohesive text.',
        max: 8000,
        isMultiline: true,
      },
      isOptional: true,
      type: 'text/plain',
      listDepth: 0,
      value: null,
    },
  },
  outputs: {
    text: {
      key: 'text',
      name: 'Text Output',
      description:
        'Your list items combined into a single piece of text based on your instructions.',
      type: 'text/plain',
      listDepth: 0,
      isOptional: false,
      value: null,
    },
  },
} satisfies StepDefinition;
