<script setup lang="ts">
import VariableNode from '~/components/app/VariableNode.vue';
import VariablePicker from '~/components/editor/config/VariablePicker.vue';

const modelValue = defineModel<boolean>();

const props = defineProps({
  injectable: {
    type: Boolean,
    default: false,
  },
  readonly: {
    type: Boolean,
    default: false,
  },
});

const isVariable = computed(() => {
  if (typeof modelValue.value === 'string') {
    const matches = modelValue.value.match(variableRegex);
    return matches;
  }
  return false;
});
</script>
<template>
  <div class="flex flex-row items-center gap-3 justify-start">
    <UToggle
      v-model="modelValue"
      :disabled="isVariable || props.readonly"
      color="primary"
    />
    <VariableNode v-if="isVariable" v-model="modelValue" size="sm" />
    <VariablePicker v-else-if="injectable" v-model="modelValue" type="boolean">
      <template #button="{ incoming }">
        <UButton
          color="white"
          :disabled="!incoming.valid?.length"
          size="2xs"
          :ui="{
            font: 'font-normal',
            inline: 'w-fit',
          }"
        >
          <span
            class="flex flex-row items-center gap-2 text-sm"
            :class="!incoming.valid?.length ? 'text-gray-300' : 'text-gray-500'"
          >
            {{ 'Or select variable' }}
            <UIcon name="i-ph-caret-down" class="w-4 h-4" />
          </span>
        </UButton>
      </template>
    </VariablePicker>
  </div>
</template>
