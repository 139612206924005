import { StepDefinition } from '../../types';

export default {
  key: 'transcribe',
  name: 'Transcribe Speech',
  description: 'Transcribes speech using AI.',
  documentationLink:
    'https://docs.respell.ai/steps-reference/file-tools/transcribe-speech',
  icon: 'i-respell-transcribe-speech',
  category: 'file',
  options: {
    model: {
      key: 'model',
      name: 'Model',
      description: 'The base model you want to use.',
      isOptional: false,
      type: 'text/plain',
      listDepth: 0,
      metadata: {
        options: [
          {
            key: 'openai/whisper',
            name: 'Whisper',
            icon: 'i-ph-open-ai-logo-bold',
            tags: ['Accurate'],
          },
          {
            key: 'deepgram/nova',
            name: 'Nova-2',
            icon: 'i-simple-icons-deepgram',
          },
        ],
      },
      value: 'openai/whisper',
    },
    speech: {
      key: 'speech',
      name: 'Speech',
      description: 'The speech you want the AI to transcribe.',
      isOptional: false,
      type: 'file/audio',
      listDepth: 0,
      value: null,
    },
  },
  outputs: {
    response: {
      key: 'response',
      name: 'Transcribed Text',
      description: 'The transcribed text from the AI.',
      type: 'text/plain',
      listDepth: 0,
      isOptional: false,
      value: null,
    },
  },
} satisfies StepDefinition;
