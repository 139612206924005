import { StepDefinition } from '../../types';

export default {
  key: 'describe_object',
  name: 'Describe Object',
  description: 'Describes a salesforce object',
  icon: 'i-respell-salesforce',
  category: 'integrations',
  services: ['salesforce'],
  options: {
    sobject: {
      name: 'SObject Name',
      type: 'text/plain',
      listDepth: 0,
      isOptional: false,
      value: null,
      key: 'sobject',
    },
  },
  outputs: {
    results: {
      name: 'Results',
      type: 'object',
      listDepth: 1,
      isOptional: false,
      value: null,
      key: 'results',
    },
  },
} satisfies StepDefinition;
