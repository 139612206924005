<script lang="ts" setup>
defineProps({
  type: {
    type: String,
    required: true,
  },
  action: {
    type: String,
    required: true,
  },
  message: {
    type: String,
    required: true,
  },
  isDangerous: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['confirm', 'close']);
const modal = useModal();

function close() {
  emit('close');
  modal.close();
}
function onConfirm() {
  emit('confirm');
  close();
}

defineShortcuts({
  enter: {
    handler: () => {
      onConfirm();
    },
  },
});
</script>

<template>
  <UModal>
    <UCard :ui="{ base: 'w-full' }">
      <div class="flex flex-col items-start gap-5">
        <div class="flex flex-row justify-between w-full">
          <p class="title capitalize">{{ action }} {{ type }}?</p>
          <UButton
            :padded="false"
            color="gray"
            variant="link"
            icon="i-ph-x"
            @click="close"
          />
        </div>

        <p class="body dimmed">{{ message }}</p>

        <div class="flex flex-row gap-2 justify-between w-full">
          <UButton
            label="Cancel"
            variant="solid"
            color="white"
            size="xl"
            block
            class="shrink"
            @click="close"
          />
          <UButton
            :label="action"
            size="xl"
            variant="solid"
            :color="isDangerous ? 'red' : 'primary'"
            block
            class="shrink"
            :ui="{ base: 'capitalize' }"
            @click="onConfirm"
          />
        </div>
      </div>
    </UCard>
  </UModal>
</template>
