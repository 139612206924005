import { StepDefinition } from '../../types';

export default {
  key: 'create_opportunity_hubspot',
  name: 'Create Deal',
  description: 'Creates a HubSpot Deal',
  icon: 'i-respell-hubspot',
  category: 'integrations',
  services: ['hubspot'],
  options: {
    opportunity: {
      key: 'opportunity',
      name: 'Deal',
      type: 'object',
      metadata: {
        schema: {
          reference: {
            namespace: 'hubspot',
            member: 'get_properties_deal',
          },
        },
      },
      isOptional: false,
      listDepth: 0,
      value: null,
    },
  },
  outputs: {},
} satisfies StepDefinition;
