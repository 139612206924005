import { StepDefinition } from '../../types';

export default {
  key: 'twilio_list_messages',
  name: 'List Messages',
  description:
    'Reads a conversation between two people from your twilio account',
  icon: 'i-logos-twilio-icon',
  category: 'integrations',
  services: ['twilio'],
  options: {
    from: {
      key: 'from',
      name: 'Sender',
      description: 'A phone number in the conversation',
      isOptional: false,
      type: 'text/phone-number',
      listDepth: 0,
      value: null,
    },
    to: {
      key: 'to',
      name: 'Recipient',
      description: 'Another phone number in the conversation',
      isOptional: false,
      type: 'text/phone-number',
      listDepth: 0,
      value: null,
    },
    limit: {
      key: 'limit',
      name: 'Limit',
      description:
        'The amount of messages that should be fetched (returns most recent first)',
      isOptional: true,
      type: 'number/integer',
      listDepth: 0,
      value: 20,
    },
  },
  outputs: {
    messages: {
      key: 'messages',
      name: 'Messages',
      isOptional: false,
      type: 'object',
      listDepth: 1,
      value: null,
      metadata: {
        schema: {
          from: {
            key: 'from',
            name: 'From',
            type: 'text/phone-number',
            listDepth: 0,
            isOptional: false,
            value: null,
          },
          to: {
            key: 'to',
            name: 'To',
            type: 'text/phone-number',
            listDepth: 0,
            isOptional: false,
            value: null,
          },
          body: {
            key: 'body',
            name: 'Body',
            type: 'text/plain',
            listDepth: 0,
            isOptional: false,
            value: null,
          },
        },
      },
    },
  },
} satisfies StepDefinition;
