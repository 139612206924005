import { JsonObject } from '@respell/utils';
import { Model } from '../definition';

// export interface Summary extends JsonObject {
//   action_items: string;
//   keywords: string;
//   outline: string;
//   overview: string;
//   shorthand_bullets: string;
//   gist: string;
//   bullet_gist: string;
//   short_summary: string;
// }
//
// export interface Sentence extends JsonObject {
//   index: number;
//   text: string;
//   start_time: string;
//   end_time: string;
//   speaker_id: string;
//   speaker_name: string;
// }
//
// export interface MeetingAttendee extends JsonObject {
//   name: string;
//   email: string;
// }

export interface Transcript extends JsonObject {
  title: string;
  id: string;
  participants: string[];
  organizer_email: string;
  // transcript_url: string | null;
  // meeting_attendees: MeetingAttendee[];
  // sentence: Sentence[]; // This should be called sentences but the graphql api calls it "sentence" for some reason
  // summary: Summary;
  sentences: string[];
  summary: string;
  date: Date;
}

export const TranscriptModel: Model<Transcript> = {
  schema: {
    title: {
      key: 'title',
      name: 'Title',
      description: 'The title of the transcript.',
      type: 'text/plain',
      isOptional: true,
      listDepth: 0,
      // metadata: {
      //   icon: 'fingerprint',
      // },
      value: null,
    },
    id: {
      key: 'id',
      name: 'ID',
      description: 'The id of the transcript.',
      type: 'text/plain',
      isOptional: true,
      listDepth: 0,
      // metadata: {
      //   icon: 'fingerprint',
      // },
      value: null,
    },
    participants: {
      key: 'participants',
      name: 'Participants',
      description: 'The participants in the meeting.',
      type: 'text/plain',
      isOptional: true,
      listDepth: 1,
      // metadata: {
      //   icon: 'fingerprint',
      // },
      value: null,
    },
    organizer_email: {
      key: 'organizer_email',
      name: 'Organizer Email',
      description: 'The email address of the organizer.',
      type: 'text/email',
      isOptional: true,
      listDepth: 0,
      // metadata: {
      //   icon: 'fingerprint',
      // },
      value: null,
    },
    date: {
      key: 'participants',
      name: 'Participants',
      description: 'The date that the transcript was recorded',
      type: 'date',
      isOptional: true,
      listDepth: 0,
      // metadata: {
      //   icon: 'fingerprint',
      // },
      value: null,
    },
    sentences: {
      key: 'sentences',
      name: 'Sentences',
      description: 'Each sentence said in the meeting',
      type: 'text/plain',
      isOptional: true,
      listDepth: 1,
      // metadata: {
      //   icon: 'fingerprint',
      // },
      value: null,
    },
    summary: {
      key: 'summary',
      name: 'Summary',
      description: 'A summary of the meeting',
      type: 'text/plain',
      isOptional: true,
      listDepth: 0,
      // metadata: {
      //   icon: 'fingerprint',
      // },
      value: null,
    },
  },
  parse(data: unknown): Transcript {
    return data as Transcript;
  },
};
