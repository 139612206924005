<script setup lang="ts">
import type { Variable } from '@respell/utils';
import { listDepthMacroMap, typeMacroMap } from '@respell/utils';
import { NodeViewWrapper, nodeViewProps } from '@tiptap/vue-3';
import AppVariableTag from '~/components/app/AppVariableTag.vue';
import type { KeyableDropdownItem } from '~/stores/canvas';

const {
  node = null,
  updateAttributes = null,
  size = '2xs',
  readonly = false,
} = defineProps({
  size: {
    type: String,
    default: '2xs',
  },
  readonly: {
    type: Boolean,
    default: false,
  },
  ...nodeViewProps,
});
const modelValue = defineModel<string>();
const canvasStore = useCanvasStore();

// Create a ref for the variable data
const variable = ref<Variable | null>(null);
const isLoading = ref(false);
const macroSelection = ref<KeyableDropdownItem | null>(null);
const fieldSelection = ref<KeyableDropdownItem | null>(null);
const actionItems = ref<KeyableDropdownItem[][] | []>([]);
const contextOptions = ref<KeyableDropdownItem[][] | []>([]);
const context = ref<string | null>(null);
const step = ref<string | null>(null);
const output = ref<string | null>(null);
const isContextualizing = ref(false);

const variableId = computed(() => modelValue.value ?? node?.attrs.id);
const isTriggerOutput = computed(() => {
  return step.value === 'start' && !!canvasStore.trigger;
});
const showContext = computed(
  () => node?.attrs.mode === 'context' && !isTriggerOutput.value,
);

function toggleContextualizing() {
  isContextualizing.value = !isContextualizing.value;
}

// Watch for changes in variableId and update variableData
watch(
  variableId,
  async (newId) => {
    if (!newId) {
      variable.value = null;
      actionItems.value = [];
      contextOptions.value = [];
      macroSelection.value = null;
      context.value = null;
      return;
    }

    // Parse the variable ID from the node attributes
    const {
      variable: variableData,
      macroKey,
      context: variableContext,
      fieldKey,
      linkedAccount,
      options,
      stepSlug,
      stepKey,
    } = canvasStore.parseVariableId(newId);

    // If no variable is found, return null

    variable.value = variableData;
    step.value = stepSlug;
    output.value = stepKey;
    context.value = variableContext;

    if (variableData?.key === 'missing') {
      return;
    }

    // Function to create transformation option
    const formatForDropdown = (
      transformation: Variable,
      type: 'macro' | 'field',
    ) => ({
      key: transformation.key,
      name: transformation.name,
      label: transformation.name,
      click: () => handleSelection(transformation, type),
    });

    // Get the macro options based on the variable type
    const macroOptions = typeMacroMap[variableData.type].concat(
      listDepthMacroMap[variableData.listDepth] ?? [],
    );

    actionItems.value = showContext.value
      ? [
          [
            {
              slot: 'context',
              update: (newContext: string) => updateContext(newContext),
              click: toggleContextualizing,
            },
          ],
        ]
      : [];
    // TODO: Look for previous values

    // Create transformation options by combining macro options and schema values
    // And format them for the UDropdown component
    if (macroOptions.length) {
      actionItems.value.push([
        {
          key: 'macroHeader',
          slot: 'header',
          label: 'Transformations',
          name: 'Transformations',
          disabled: true,
        },
        ...macroOptions.map((t) => formatForDropdown(t, 'macro')),
      ]);
    }
    // Find the current macro based on the macro key
    const currentMacro = macroKey
      ? actionItems.value
          .flat()
          .find((t: KeyableDropdownItem) => t.key === macroKey)
      : null;

    macroSelection.value = currentMacro;

    // Get the schema from the variable metadata
    let schema = variableData.metadata?.schema;

    if (schema && 'reference' in schema) {
      isLoading.value = true;
      const reference = schema.reference;
      const serviceAgnostic = !('namespace' in reference);

      const { data } = await useAsyncData(
        `context/${serviceAgnostic ? 'account' : linkedAccount?.id}/output/${variableData.key}`,
        () =>
          canvasStore.fetchContext({
            options,
            linkedAccountId: linkedAccount?.id,
            reference,
            isReady: serviceAgnostic || !!linkedAccount,
          }),
      );
      schema = data.value;
      isLoading.value = false;
    }

    if (schema) {
      actionItems.value.push([
        {
          key: 'schemaHeader',
          slot: 'header',
          label: 'Fields',
          name: 'Fields',
          disabled: true,
        },
        ...Object.values(schema).map((t) => formatForDropdown(t, 'field')),
      ]);
    }

    // Find the current macro based on the macro key
    const currentField = fieldKey
      ? actionItems.value
          .flat()
          .find((t: KeyableDropdownItem) => t.key === fieldKey)
      : null;

    fieldSelection.value = currentField;
  },
  { immediate: true },
);

// Handle macro update when a transformation is selected
const handleSelection = (
  transformation: KeyableDropdownItem,
  type: 'macro' | 'field',
) => {
  let newId;

  if (type === 'macro') {
    if (transformation.key === macroSelection.value?.key) {
      // Remove the macro key
      macroSelection.value = null;
      newId = `{{${step.value}.${output.value}${fieldSelection.value ? `.${fieldSelection.value.key}` : ''}}}`;
    } else {
      // Update the macro key
      macroSelection.value = transformation;
      newId = `{{${step.value}.${output.value}${fieldSelection.value ? `.${fieldSelection.value.key}` : ''}->${transformation.key}}}`;
    }
  } else if (type === 'field') {
    if (transformation.key === fieldSelection.value?.key) {
      // Remove the field key
      fieldSelection.value = null;
      newId = `{{${step.value}.${output.value}${macroSelection.value ? `->${macroSelection.value.key}` : ''}}}`;
    } else {
      // Update the field key
      fieldSelection.value = transformation;
      newId = `{{${step.value}.${output.value}.${transformation.key}${macroSelection.value ? `->${macroSelection.value.key}` : ''}}}`;
    }
  }

  if (node && updateAttributes) {
    updateAttributes({ id: newId });
  } else if (modelValue.value) {
    modelValue.value = newId;
  }
};

const handleDelete = () => {
  modelValue.value = undefined;
};

async function updateContext(newContext: string) {
  await canvasStore.addContext({
    step: step.value,
    variable: variable.value,
    context: newContext,
  });
  context.value = newContext;
  isContextualizing.value = false;
}
</script>
<template>
  <node-view-wrapper :content-editable="false" class="inline">
    <AppVariableTag
      :variable="variable"
      :size="size"
      :is-loading="isLoading"
      :macro-selection="macroSelection"
      :field-selection="fieldSelection"
      :action-items="actionItems"
      :existing-context="context"
      :show-context="showContext"
      :handle-delete="modelValue ? handleDelete : undefined"
      :readonly="node?.attrs.mode === 'readonly' || readonly"
      :is-contextualizing="isContextualizing"
    />
  </node-view-wrapper>
</template>
