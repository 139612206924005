import { StepDefinition } from '../../types';

export default {
  key: 'combine_text',
  name: 'Combine Text',
  description:
    'Combines multiple text values into one. Useful for creating prompts or combining outputs from multiple sources.',
  icon: 'i-respell-combine-text',
  documentationLink:
    'https://docs.respell.ai/steps-reference/text-tools/combine-text',
  category: 'text',
  options: {
    text: {
      key: 'text',
      name: 'Combined Text',
      description: 'The text you want to combine together.',
      isOptional: false,
      type: 'text/plain',
      listDepth: 0,
      metadata: {
        isMultiline: true,
      },
      value: null,
    },
  },
  outputs: {
    text: {
      key: 'text',
      name: 'Combined Text',
      description: 'The combined text result.',
      type: 'text/plain',
      isOptional: false,
      listDepth: 0,
      metadata: {
        icon: 'i-ph-subtitles-bold',
        isMultiline: true,
      },
      value: null,
    },
  },
} satisfies StepDefinition;
