import { StepDefinition } from '../../types';

export default {
  key: 'calendly_create_single_use_scheduling_link',
  name: 'Create Single Use Scheduling Link',
  description: 'Creates a single-use scheduling link in calendly',
  icon: 'i-respell-calendly',
  category: 'integrations',
  services: ['calendly'],
  options: {
    event_type: {
      key: 'event_type',
      name: 'Event Type',
      type: 'text/plain',
      isOptional: false,
      listDepth: 0,
      value: null,
      metadata: {
        options: {
          reference: {
            namespace: 'calendly',
            member: 'calendly_list_event_types',
          },
        },
      },
    },
  },
  outputs: {
    event: {
      key: 'event',
      name: 'Event',
      type: 'object',
      isOptional: false,
      listDepth: 0,
      value: null,
    },
  },
} satisfies StepDefinition;
