import { StepDefinition } from '../../types';

export default {
  key: 'attio_create_company',
  name: 'Create Company',
  description: 'Creates a company record in Attio',
  icon: 'i-respell-attio',
  category: 'integrations',
  services: ['attio'],
  options: {
    company: {
      key: 'company',
      name: 'Company',
      type: 'object',
      metadata: {
        schema: {
          reference: {
            namespace: 'attio',
            member: 'attio_list_attributes_company',
          },
        },
      },
      isOptional: false,
      listDepth: 0,
      value: null,
    },
  },
  outputs: {},
} satisfies StepDefinition;
