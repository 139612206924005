export function useConditional(option) {
  const canvasStore = useCanvasStore();
  const { selectedStep } = useSelectedStep();

  return computed(() => {
    if (!option.conditions) return true;
    return option.conditions.every((condition: any) => {
      if (condition.or) {
        return (
          canvasStore.evaluateCondition(
            condition,
            selectedStep.value?.data.options[condition.reference],
          ) ||
          condition.or.some((orCondition: any) =>
            canvasStore.evaluateCondition(
              orCondition,
              selectedStep.value?.data.options[orCondition.reference],
            ),
          )
        );
      }
      return canvasStore.evaluateCondition(
        condition,
        selectedStep.value?.data.options[condition.reference],
      );
    });
  });
}
