export default defineNuxtRouteMiddleware((to, from) => {
  const workspaceStore = useWorkspaceStore();

  const dashboardPages = [
    'dashboard',
    'inbox',
    'spells',
    'team',
    'dataSources',
    'history',
    'templates',
  ];

  const toName = to.name ?? to.matched[0]?.name;
  const fromName = from.name ?? from.matched[0]?.name;

  if (
    dashboardPages.includes(toName as string) &&
    !dashboardPages.includes(fromName as string)
  ) {
    workspaceStore.fetchInboxPages('todo');
    workspaceStore.fetchSpellMeter();
  }
  return;
});
