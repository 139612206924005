import { TicketModel } from '@respell/integrations/zendesk/models';
import { StepDefinition } from '../../types';

export default {
  key: 'search_tickets',
  name: 'Search Tickets',
  description: 'Searches Zendesk for tickets matching your query.',
  icon: 'i-respell-zendesk',
  //documentationLink: 'https://docs.respell.ai/zendesk',
  category: 'integrations',
  services: ['zendesk'],
  options: {
    query: {
      key: 'query',
      name: 'Query',
      description:
        'The query to use for finding tickets. Searches across ticket names, details, and more. Functions the same as the search bar in the Zendesk dashboard.',
      type: 'text/plain',
      isOptional: false,
      metadata: {
        icon: 'i-ph-subtitles-bold',
      },
      listDepth: 0,
      value: null,
    },
  },
  outputs: {
    tickets: {
      key: 'tickets',
      name: 'Search Results',
      description: 'Details of the tickets found in the search.',
      type: 'text/plain',
      isOptional: false,
      listDepth: 1,
      metadata: {
        icon: 'i-ph-subtitles-bold',
        schema: TicketModel.schema,
      },
      value: null,
    },
  },
} satisfies StepDefinition;
