import { TriggerDefinition } from '../../../definition';

export default {
  name: 'New Submission',
  key: 'new_submission',
  description: 'A response is recorded in Jotform.',
  icon: 'i-respell-jotform',
  options: {
    form_id: {
      key: 'form_id',
      name: 'Form ID',
      description: 'The ID of the form to watch for responses.',
      metadata: {
        options: {
          reference: {
            namespace: 'jotform',
            member: 'list_forms',
          },
        },
      },
      isOptional: false,
      type: 'text/plain',
      listDepth: 0,
      value: null,
    },
  },
  outputs: {
    output: {
      key: 'output',
      name: 'Submission Output',
      type: 'object',
      isOptional: false,
      listDepth: 0,
      metadata: {
        schema: {
          reference: {
            namespace: 'jotform',
            member: 'get_form_questions',
            arguments: [
              {
                namespace: 'options',
                member: 'form_id',
                parameter: 'form_id',
              },
            ],
          },
        },
      },
      value: null,
    },
  },
  example: {
    output: {
      'What is your name?': 'John Doe',
      'What is your company?': 'Acme',
      'Rate our service 1-5': '5',
    },
  },
} satisfies TriggerDefinition;
