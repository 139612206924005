<script setup lang="ts">
import type { Graph, GraphRun } from '@respell/database';
import integrations, { triggers } from '@respell/integrations';
import { typeMap } from '@respell/utils';
import AppInput from '~/components/app/AppInput.vue';

const props = defineProps<{ run: GraphRun; graph: Graph }>();

const trigger = computed(() => {
  const graphTrigger = props.graph.trigger;
  if (!graphTrigger) return null;
  const triggerDefinition = triggers[graphTrigger.service][graphTrigger.event];

  const outputs = Object.entries(props.run.inputs || {}).reduce(
    (acc, [key, value]) => {
      acc[key] = {
        ...triggerDefinition.outputs[key],
        value: value,
      };
      return acc;
    },
    {},
  );

  return {
    ...graphTrigger,
    ...triggerDefinition,
    outputs,
  };
});
</script>
<template>
  <UAccordion
    v-if="trigger"
    :items="[trigger]"
    class="w-full h-full border bg-white border-gray-200 p-4 rounded-md"
    :ui="{
      item: {
        base: 'w-full',
        padding: '!p-0',
      },
    }"
  >
    <template #default="{ open }">
      <div class="flex justify-between cursor-pointer w-full">
        <span class="flex gap-2">
          <UIcon
            class="text-lg rounded-sm"
            :name="integrations[trigger.service].icon"
          />

          <p class="body truncate">
            {{ trigger.name }}
          </p>
          <p class="caption ms-s truncate">
            {{ trigger.description }}
          </p>
        </span>

        <UIcon
          name="i-ph-caret-right-bold"
          class="w-4 h-4 text-gray-400 ml-auto transform transition-transform duration-200"
          :class="[open && 'rotate-90']"
        />
      </div>
    </template>
    <template #item>
      <div
        v-for="(output, key) in trigger.outputs"
        :key="key"
        class="bg-primary-50 border border-gray-200 rounded-lg p-4 mt-3 flex gap-2 flex-col items-start w-full"
      >
        <span class="flex gap-2">
          <UIcon :name="typeMap[output.type].icon" class="text-lg" />
          <p class="body-sm">{{ output.name }}</p>
          <p class="caption">{{ output.description }}</p>
        </span>

        <AppInput
          :model-value="output.value"
          :option="output"
          owner-type="GraphRun"
          :owner-id="run.id"
          readonly
          hide-label
        />
      </div>
    </template>
  </UAccordion>
</template>
