import { Extension, Node } from '@tiptap/core';
import { VueNodeViewRenderer } from '@tiptap/vue-3';
import { Plugin, PluginKey } from 'prosemirror-state';
import VariableNode from '~/components/app/VariableNode.vue';

export const escapeHtml = (unsafe) => {
  return unsafe
    .replaceAll('&', '&amp;')
    .replaceAll('<', '&lt;')
    .replaceAll('>', '&gt;')
    .replaceAll('"', '&quot;')
    .replaceAll("'", '&#039;');
};

export const Variable = Node.create({
  name: 'variable',
  group: 'inline',
  inline: true,
  atom: true,

  addOptions() {
    return {
      mode: {
        default: 'editable',
      },
    };
  },

  addAttributes() {
    return {
      id: {
        default: null,
      },
      mode: {
        default: this.options.mode,
      },
    };
  },

  renderHTML({ node }) {
    return [
      'span',
      {
        'data-variable-id': node.attrs.id,
        class: 'variable-tag',
      },
    ];
  },
  parseHTML() {
    return [
      {
        tag: 'span.variable',
        getAttrs: (domNode) => {
          const element = domNode as HTMLElement;
          return {
            id: element.innerText,
          };
        },
      },
    ];
  },
  renderText({ node }) {
    return node.attrs.id;
  },
  addNodeView() {
    return VueNodeViewRenderer(VariableNode);
  },
});

// Function to parse plain text into HTML
export function textToHtml(content) {
  // Replace variable placeholders
  let parsedContent = content.replace(globalVariableRegex, (match: string) => {
    return `<span class="variable">${match}</span>`;
  });

  // TODO: Handle headings
  // parsedContent = parsedContent.replace(
  //   /^(#{1,6}) (.*)$/gm,
  //   (match, p1, p2) => {
  //     const level = p1.length;
  //     return `<h${level}>${p2}</h${level}>`;
  //   },
  // );

  // Handle lists
  parsedContent = parsedContent.replace(/^- (.*)$/gm, '<li>$1</li>');
  parsedContent = parsedContent.replace(/^\* (.*)$/gm, '<li>$1</li>');
  parsedContent = parsedContent.replace(
    /(<li>.*<\/li>)(?!<li>)/g,
    '<ul>$1</ul>',
  );

  // Ensure that consecutive <li> elements are wrapped in a single <ul>
  parsedContent = parsedContent.replace(/<\/ul>\s*<ul>/g, '');

  // Handle new lines
  parsedContent = parsedContent.replace(/\n/g, '</p><p>');

  // Wrap all content in a paragraph
  parsedContent = `<p>${parsedContent}</p>`;

  // Remove <p> tags around <ul> and <li> elements
  parsedContent = parsedContent.replace(/<p><ul>/g, '<ul>');
  parsedContent = parsedContent.replace(/<\/ul><\/p>/g, '</ul>');
  parsedContent = parsedContent.replace(/<p><li>/g, '<li>');
  parsedContent = parsedContent.replace(/<\/li><\/p>/g, '</li>');

  // Remove <p> tags around <h1> to <h6> elements
  parsedContent = parsedContent.replace(/<p><h(\d)>/g, '<h$1>');
  parsedContent = parsedContent.replace(/<\/h(\d)><\/p>/g, '</h$1>');

  return parsedContent;
}

// Create custom extension to support numeric input + paste on first character = number.
export const Numeric = Extension.create({
  name: 'numeric',

  addOptions() {
    return {
      allowDecimals: true,
    };
  },

  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: new PluginKey('numeric'),
        props: {
          handleTextInput: (view, from, to, text) => {
            const regex = this.options.allowDecimals ? /^\d*\.?\d*$/ : /^\d+$/;
            return !regex.test(text); // Return true to PREVENT input
          },
          handlePaste: (view, event, slice) => {
            const text = event.clipboardData?.getData('text/plain');
            const regex = this.options.allowDecimals ? /^\d*\.?\d*$/ : /^\d+$/;
            return !regex.test(text as string); // Return true to PREVENT paste
          },
        },
      }),
    ];
  },
});

export const ShiftSpace = Extension.create({
  addKeyboardShortcuts() {
    return {
      'Shift-Space': () => {
        this.editor.chain().focus().insertContent(' ').run();
        return true;
      },
    };
  },
});

export const PreventEnter = Extension.create({
  name: 'preventEnter',

  addOptions() {
    return {
      type: {
        default: 'text/plain',
      },
    };
  },

  addKeyboardShortcuts() {
    return {
      Enter: () => {
        return this.options.type !== 'text/plain';
      },
    };
  },
});
