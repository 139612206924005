import { StepDefinition } from '../../types';

export default {
  key: 'read_website',
  name: 'Read Website',
  description: 'Retrieves the text content from a website.',
  documentationLink:
    'https://docs.respell.ai/steps-reference/web-code-tools/read-website',
  icon: 'i-respell-read-website',
  category: 'web',
  options: {
    url: {
      key: 'url',
      name: 'URL',
      listDepth: 0,
      description: 'The URL of the page you want to read.',
      type: 'text/url',
      isOptional: false,
      value: null,
    },
    allowJS: {
      key: 'allowJS',
      name: 'Allow JavaScript',
      listDepth: 0,
      description:
        'Whether to allow JavaScript to run on the page. If website fails to load, try enabling this option.',
      type: 'boolean',
      isOptional: true,
      value: false,
    },
  },
  outputs: {
    content: {
      key: 'content',
      name: 'Website Contents',
      description: 'The text content of the website.',
      type: 'text/plain',
      listDepth: 0,
      isOptional: false,
      value: null,
    },
  },
} satisfies StepDefinition;
