import { StepDefinition } from '../../types';

export default {
  key: 'add_canididate_greenhouse',
  name: 'Add Candidate',
  description: 'Add a new Greenhouse candidate.',
  icon: 'i-respell-greenhouse',
  category: 'integrations',
  services: ['greenhouse'],
  options: {
    jobId: {
      key: 'jobId',
      name: 'Job',
      description: 'The job to associate the candidate to.',
      isOptional: false,
      type: 'number/integer',
      metadata: {
        options: {
          reference: {
            namespace: 'greenhouse',
            member: 'list_jobs',
          },
        },
      },
      listDepth: 0,
      value: null,
    },
    first_name: {
      key: 'first_name',
      name: 'First Name',
      description: 'The first name of the candidate to be created.',
      isOptional: false,
      type: 'text/plain',
      listDepth: 0,
      value: null,
    },
    last_name: {
      key: 'last_name',
      name: 'Last Name',
      description: 'The last name of the candidate to be created.',
      isOptional: false,
      type: 'text/plain',
      listDepth: 0,
      value: null,
    },
    email: {
      key: 'email',
      name: 'Email',
      description: 'Primary, personal email of the candidate to be created.',
      isOptional: false,
      type: 'text/email',
      listDepth: 0,
      value: null,
    },
    phoneNumber: {
      key: 'phoneNumber',
      name: 'Phone Number',
      description:
        'Primary, personal phone number of the candidate to be created.',
      isOptional: true,
      type: 'text/phone-number',
      listDepth: 0,
      value: null,
    },
    linkedInUrl: {
      key: 'linkedInUrl',
      name: 'LinkedIn URL',
      description:
        "Url to the candidate's LinkedIn profile. Must be a valid Url.",
      isOptional: true,
      type: 'text/url',
      listDepth: 0,
      value: null,
    },
    githubUrl: {
      key: 'githubUrl',
      name: 'Github URL',
      description:
        "Url to the candidate's Github profile. Must be a valid Url.",
      isOptional: true,
      type: 'text/url',
      listDepth: 0,
      value: null,
    },
    website: {
      key: 'website',
      name: 'Website',
      description: "Url of the candidate's website. Must be a valid Url.",
      isOptional: true,
      type: 'text/url',
      listDepth: 0,
      value: null,
    },
  },
  outputs: {},
} satisfies StepDefinition;
