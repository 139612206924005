import { TriggerDefinition } from '../../../definition';

export default {
  key: 'apollo-contacts',
  name: 'New Contact',
  description: 'A contact is created in Apollo.',
  options: {},
  icon: 'i-respell-apollo',
  outputs: {
    contact: {
      metadata: {
        schema: {
          firstName: {
            key: 'firstName',
            name: 'First Name',
            type: 'text/plain',
            isOptional: false,
            listDepth: 0,
            metadata: {
              icon: 'user',
            },
            value: null,
          },
          lastName: {
            key: 'lastName',
            name: 'Last Name',
            type: 'text/plain',
            isOptional: false,
            listDepth: 0,
            metadata: {
              icon: 'user',
            },
            value: null,
          },
          organizationName: {
            key: 'organizationName',
            name: 'Organization Name',
            type: 'text/plain',
            isOptional: false,
            listDepth: 0,
            metadata: {
              icon: 'building',
            },
            value: null,
          },
          title: {
            key: 'title',
            name: 'Title',
            type: 'text/plain',
            isOptional: false,
            listDepth: 0,
            metadata: {
              icon: 'id-badge',
            },
            value: null,
          },
          accountId: {
            key: 'accountId',
            name: 'Account ID',
            type: 'text/plain',
            isOptional: false,
            listDepth: 0,
            metadata: {
              icon: 'id-card',
            },
            value: null,
          },
          email: {
            key: 'email',
            name: 'Email',
            type: 'text/email',
            isOptional: false,
            listDepth: 0,
            metadata: {
              icon: 'envelope',
            },
            value: null,
          },
          websiteUrl: {
            key: 'websiteUrl',
            name: 'Website URL',
            type: 'text/url',
            isOptional: false,
            listDepth: 0,
            metadata: {
              icon: 'globe',
            },
            value: null,
          },
          presentRawAddress: {
            key: 'presentRawAddress',
            name: 'Present Raw Address',
            type: 'text/plain',
            isOptional: false,
            listDepth: 0,
            metadata: {
              icon: 'map-marker',
            },
            value: null,
          },
          directPhone: {
            key: 'directPhone',
            name: 'Direct Phone',
            type: 'text/phone-number',
            isOptional: false,
            listDepth: 0,
            metadata: {
              icon: 'phone',
            },
            value: null,
          },
          corporatePhone: {
            key: 'corporatePhone',
            name: 'Corporate Phone',
            type: 'text/phone-number',
            isOptional: false,
            listDepth: 0,
            metadata: {
              icon: 'phone',
            },
            value: null,
          },
          mobilePhone: {
            key: 'mobilePhone',
            name: 'Mobile Phone',
            type: 'text/phone-number',
            isOptional: false,
            listDepth: 0,
            metadata: {
              icon: 'mobile',
            },
            value: null,
          },
          homePhone: {
            key: 'homePhone',
            name: 'Home Phone',
            type: 'text/phone-number',
            isOptional: false,
            listDepth: 0,
            metadata: {
              icon: 'phone',
            },
            value: null,
          },
          otherPhone: {
            key: 'otherPhone',
            name: 'Other Phone',
            type: 'text/phone-number',
            isOptional: false,
            listDepth: 0,
            metadata: {
              icon: 'phone',
            },
            value: null,
          },
        },
      },
      name: 'Contact',
      type: 'object',
      listDepth: 0,
      isOptional: false,
      value: null,
      key: 'contact',
    },
  },
  example: {
    contact: {
      firstName: 'John',
      lastName: 'Doe',
      organizationName: 'Acme, Inc.',
      title: 'Acme',
      accountId: '123456f0c940f00300222ac7',
      email: 'hello@acme.com',
      websiteUrl: 'https://acme.com',
      presentRawAddress: '123 Example Ln. Suite A, San Francisco, CA 94102',
      directPhone: '123-456-7890',
      corporatePhone: '123-456-7890',
      mobilePhone: '123-456-7890',
      homePhone: '123-456-7890',
      otherPhone: '123-456-7890',
    },
  },
} satisfies TriggerDefinition;
