import { StepDefinition } from '../../types';

export default {
  key: 'read_linkedin_profile',
  name: 'Read Linkedin Profile',
  description: 'Reads a LinkedIn profile.',
  icon: 'i-logos-linkedin-icon',
  category: 'integrations',
  services: ['linkedin'],
  options: {
    name: {
      key: 'name',
      name: 'Name',
      description: "The account's linkedin profile ID.",
      type: 'text/plain',
      isOptional: false,
      metadata: {
        icon: 'i-ph-subtitles-bold',
      },
      listDepth: 0,
      value: null,
    },
  },
  outputs: {
    profile: {
      key: 'profile',
      name: 'Profile Data',
      isOptional: false,
      type: 'object',
      listDepth: 0,
      value: null,
      metadata: {
        schema: {
          name: {
            key: 'name',
            type: 'text/plain',
            name: 'Name',
            listDepth: 0,
            isOptional: false,
            value: null,
          },
          headline: {
            key: 'headline',
            type: 'text/plain',
            name: 'Headline',
            listDepth: 0,
            isOptional: false,
            value: null,
          },
          location: {
            key: 'location',
            type: 'text/plain',
            name: 'Location',
            listDepth: 0,
            isOptional: false,
            value: null,
          },
          summary: {
            key: 'summary',
            type: 'text/plain',
            name: 'Summary',
            listDepth: 0,
            isOptional: false,
            value: null,
          },
          experience: {
            key: 'experience',
            type: 'array',
            name: 'Experience',
            listDepth: 1,
            isOptional: false,
            value: null,
            metadata: {
              schema: {
                title: {
                  key: 'title',
                  type: 'text/plain',
                  name: 'Job Title',
                  listDepth: 0,
                  isOptional: false,
                  value: null,
                },
                company: {
                  key: 'company',
                  type: 'text/plain',
                  name: 'Company',
                  listDepth: 0,
                  isOptional: false,
                  value: null,
                },
                duration: {
                  key: 'duration',
                  type: 'text/plain',
                  name: 'Duration',
                  listDepth: 0,
                  isOptional: false,
                  value: null,
                },
                location: {
                  key: 'location',
                  type: 'text/plain',
                  name: 'Location',
                  listDepth: 0,
                  isOptional: false,
                  value: null,
                },
              },
            },
          },
          education: {
            key: 'education',
            type: 'array',
            name: 'Education',
            listDepth: 1,
            isOptional: false,
            value: null,
            metadata: {
              schema: {
                institution: {
                  key: 'institution',
                  type: 'text/plain',
                  name: 'Institution',
                  listDepth: 0,
                  isOptional: false,
                  value: null,
                },
                degree: {
                  key: 'degree',
                  type: 'text/plain',
                  name: 'Degree',
                  listDepth: 0,
                  isOptional: false,
                  value: null,
                },
                duration: {
                  key: 'duration',
                  type: 'text/plain',
                  name: 'Duration',
                  listDepth: 0,
                  isOptional: false,
                  value: null,
                },
              },
            },
          },
        },
      },
    },
  },
} satisfies StepDefinition;
