import { StepDefinition } from '../../types';

export default {
  key: 'related_profiles',
  name: 'Get Users Related to a Profile',
  description:
    'Returns users present in the "More profiles to browse" section of a LinkedIn profile.',
  icon: 'i-logos-linkedin-icon',
  category: 'integrations',
  services: ['linkedin'],
  options: {
    name: {
      key: 'name',
      name: 'Name',
      description: "The account's linkedin profile ID.",
      type: 'text/plain',
      isOptional: false,
      metadata: {
        icon: 'i-ph-subtitles-bold',
      },
      listDepth: 0,
      value: null,
    },
  },
  outputs: {
    profiles: {
      key: 'profiles',
      name: 'Related Profiles',
      isOptional: false,
      type: 'object',
      listDepth: 1,
      metadata: {
        schema: {
          connectionDegree: {
            key: 'connectionDegree',
            name: 'Connection Degree',
            type: 'text/plain',
            listDepth: 0,
            isOptional: false,
            value: null,
          },
          name: {
            key: 'name',
            type: 'text/plain',
            name: 'Name',
            listDepth: 0,
            isOptional: false,
            value: null,
          },
          tagline: {
            key: 'tagline',
            type: 'text/plain',
            name: 'Tagline',
            listDepth: 0,
            isOptional: false,
            value: null,
          },
          link: {
            key: 'link',
            type: 'text/plain',
            name: 'Profile Link',
            listDepth: 0,
            isOptional: false,
            value: null,
          },
        },
      },
      value: [],
    },
  },
} satisfies StepDefinition;
