import { StepDefinition } from '../../types';

export default {
  key: 'send_campaign_mailgun',
  name: 'Send Campaign',
  description: 'Sends an email campaign from your Mailgun account.',
  icon: 'i-respell-mailgun',
  category: 'integrations',
  services: ['mailgun'],
  options: {
    domain: {
      key: 'domain',
      name: 'Domain',
      description: 'The domain of the article.',
      type: 'text/plain',
      isOptional: true,
      listDepth: 0,
      metadata: {
        icon: 'i-ph-text-align-left',
        options: {
          reference: {
            namespace: 'mailgun',
            member: 'list_domains',
          },
        },
      },
      value: null,
    },
    body: {
      key: 'body',
      name: 'Body',
      description: 'The body (message) of the email.',
      type: 'text/plain',
      isOptional: true,
      listDepth: 0,
      metadata: {
        icon: 'i-ph-text-align-left',
      },
      value: null,
    },
    from_name: {
      key: 'from_name',
      name: 'From Name',
      description: 'The name of the sender of the email.',
      type: 'text/plain',
      isOptional: false,
      listDepth: 0,
      value: null,
    },
    from_email: {
      key: 'from_email',
      name: 'From Email',
      description: 'The email of the sender of the email.',
      type: 'text/email',
      isOptional: false,
      listDepth: 0,
      value: null,
    },
    subject: {
      key: 'subject',
      name: 'Subject',
      description: 'The subject of the email.',
      type: 'text/plain',
      isOptional: false,
      listDepth: 0,
      metadata: {
        icon: 'i-ph-text-align-left',
      },
      value: null,
    },
    campaign_id: {
      key: 'campaign_id',
      name: 'List',
      description: 'The list to send the email to.',
      type: 'text/plain',
      isOptional: false,
      listDepth: 0,
      metadata: {
        icon: 'i-ph-text-align-left',
        options: {
          reference: {
            namespace: 'mailgun',
            member: 'list_lists',
          },
        },
      },
      value: null,
    },
  },
  outputs: {},
} satisfies StepDefinition;
