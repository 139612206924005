import { StepDefinition } from '../../types';

export default {
  key: 'send_mail',
  name: 'Send Email',
  description: 'Sends an email from your Sendgrid account.',
  icon: 'i-respell-sendgrid',
  category: 'integrations',
  services: ['sendgrid'],
  options: {
    body: {
      key: 'body',
      name: 'Body',
      description: 'The body (message) of the email.',
      type: 'text/plain',
      isOptional: true,
      listDepth: 0,
      metadata: {
        icon: 'i-ph-text-align-left',
      },
      value: null,
    },
    subject: {
      key: 'subject',
      name: 'Subject',
      description: 'The subject of the email.',
      type: 'text/plain',
      isOptional: false,
      listDepth: 0,
      metadata: {
        icon: 'i-ph-text-align-left',
      },
      value: null,
    },
    to: {
      key: 'to',
      name: 'To',
      description: "The recipient's email address.",
      type: 'text/email',
      isOptional: false,
      listDepth: 0,
      metadata: {
        icon: 'i-ph-user',
      },
      value: null,
    },
    from: {
      key: 'from',
      name: 'From',
      description: "The sender's email address.",
      type: 'text/email',
      isOptional: false,
      listDepth: 0,
      metadata: {
        icon: 'i-ph-user',
      },
      value: null,
    },
  },
  outputs: {
    details: {
      key: 'details',
      name: 'Sent Email Details',
      description: ' Details of the email that was sent.',
      type: 'object',
      isOptional: false,
      listDepth: 0,
      metadata: {
        icon: 'i-ph-info',
      },
      value: null,
    },
  },
} satisfies StepDefinition;
