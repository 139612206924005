import { TriggerDefinition } from '../../../definition';

export default {
  key: 'global',
  name: 'Tracking Events',
  description: 'A link in an email is clicked.',
  icon: 'i-respell-sendgrid',
  options: {
    event: {
      key: 'event',
      name: 'Event',
      description: 'The event that triggered the webhook.',
      isOptional: false,
      metadata: {
        options: {
          reference: {
            namespace: 'sendgrid',
            member: 'list_triggers',
          },
        },
      },
      type: 'text/plain',
      listDepth: 0,
      value: null,
    },
    single_send_id: {
      key: 'single_send_id',
      name: 'Single Send ID',
      description: 'The ID of the single send that triggered the webhook.',
      isOptional: true,
      type: 'text/plain',
      metadata: {
        isSearchable: true,
        options: {
          reference: {
            namespace: 'sendgrid',
            member: 'list_single_sends',
          },
        },
      },
      listDepth: 0,
      value: null,
    },
  },
  outputs: {
    url: {
      key: 'url',
      name: 'URL',
      type: 'text/plain',
      description:
        'The URL that was clicked, only present if the event is a click event.',
      isOptional: true,
      conditions: [
        {
          reference: 'event',
          type: 'equals',
          value: 'click',
        },
      ],
      listDepth: 0,
      value: null,
    },
    email: {
      key: 'email',
      name: 'Email',
      type: 'text/plain',
      description:
        'The email address that clicked the link or opened the message.',
      isOptional: false,
      listDepth: 0,
      metadata: {
        icon: 'fingerprint',
      },
      value: null,
    },
  },
  example: {
    url: 'https://acme.com',
    email: 'john@acme.com',
  },
} satisfies TriggerDefinition;
