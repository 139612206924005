import { StepDefinition } from '../../types';

export default {
  key: 'drive_upload_file',
  name: 'Upload File',
  description: 'Will upload a file to Google Drive',
  icon: 'i-respell-google',
  category: 'integrations',
  services: ['google-drive'],
  options: {
    file: {
      type: 'file/document',
      isOptional: false,
      description: 'The file to upload.',
      name: 'File',
      listDepth: 0,
      value: null,
      key: 'file',
    },
    folder: {
      key: 'folder',
      name: 'Folder',
      isOptional: true,
      value: null,
      listDepth: 0,
      description: 'The folder to which the file should be uploaded',
      type: 'text/plain',
      metadata: {
        options: {
          reference: {
            namespace: 'google-drive',
            member: 'list_drive_folders',
          },
        },
      },
    },
  },
  outputs: {},
} satisfies StepDefinition;
