import { StepDefinition } from '../../types';

export default {
  key: 'add_file_text',
  name: 'Add Text To Workspace',
  description: 'Adds a file to your Data Sources based on input text.',
  category: 'file',
  icon: 'i-respell-text-to-workspace',
  options: {
    content: {
      key: 'content',
      name: 'Content',
      description: 'The text content you want to place in the file.',
      isOptional: false,
      type: 'text/plain',
      listDepth: 0,
      metadata: {
        isMultiline: true,
      },
      value: null,
    },
    folderId: {
      key: 'folderId',
      name: 'Folder',
      listDepth: 0,
      description: 'Choose the destination folder from your Data Sources.',
      type: 'text/plain',
      metadata: {
        isSearchable: true,
        options: {
          reference: {
            member: 'get_folders',
            arguments: [
              {
                namespace: 'options',
                member: 'query',
                parameter: 'query',
              },
            ],
          },
        },
      },
      isOptional: false,
      value: 'all',
    },
    filename: {
      key: 'filename',
      name: 'Filename',
      description:
        'The name of the file to create, the file is always a .txt file.',
      isOptional: false,
      type: 'text/plain',
      conditions: [
        {
          reference: 'folderId',
          type: 'not',
          value: null,
        },
      ],
      listDepth: 0,
      value: null,
    },
  },
  outputs: {},
} satisfies StepDefinition;
