import { StepDefinition } from '../../types';

export default {
  key: 'create_opportunity',
  name: 'Create Opportunity',
  description: 'Creates a Salesforce Opportunity',
  icon: 'i-respell-salesforce',
  category: 'integrations',
  services: ['salesforce'],
  options: {
    opportunity: {
      name: 'opportunity',
      type: 'object',
      listDepth: 0,
      isOptional: false,
      value: null,
      key: 'opportunity',
      metadata: {
        schema: {
          reference: {
            namespace: 'salesforce',
            member: 'describe_opportunity',
          },
        },
      },
    },
  },
  outputs: {},
} satisfies StepDefinition;
