import { IssueModel } from '@respell/integrations/linear/models';
import { StepDefinition } from '../../types';

export default {
  key: 'get_issue',
  name: 'Get Issue',
  description: 'Retrieves a Linear issue from an issue ID.',
  icon: 'i-respell-linear',
  category: 'integrations',
  services: ['linear'],
  options: {
    issue_id: {
      key: 'issue_id',
      name: 'Issue ID',
      description: 'ID of the issue you want to retrieve.',
      isOptional: false,
      type: 'text/plain',
      listDepth: 0,
      metadata: {
        options: {
          reference: {
            namespace: 'linear',
            member: 'get_issues',
          },
        },
      },
      value: null,
    },
  },
  outputs: {
    issue: {
      key: 'issue',
      name: 'Issue',
      description:
        'Contents of the issue. Contains the title, description, tags, and more data.',
      type: 'object',
      isOptional: false,
      listDepth: 0,
      metadata: {
        icon: 'i-respell-t-box',
        schema: IssueModel.schema,
      },
      value: null,
    },
  },
} satisfies StepDefinition;
