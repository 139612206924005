import { StepDefinition } from '../../types';

export default {
  key: 'attio_create_deal',
  name: 'Create deal',
  description: 'Creates a deal record in your Attio workspace',
  icon: 'i-respell-attio',
  category: 'integrations',
  services: ['attio'],
  options: {
    deal: {
      key: 'deal',
      name: 'Deal',
      type: 'object',
      metadata: {
        schema: {
          reference: {
            namespace: 'attio',
            member: 'attio_list_attributes_deal',
          },
        },
      },
      isOptional: false,
      listDepth: 0,
      value: null,
    },
  },
  outputs: {},
} satisfies StepDefinition;
