import { StepDefinition } from '../../types';

export default {
  key: 'condition',
  name: 'Condition',
  description: 'Go to different paths based on a set of conditions.',
  icon: 'i-respell-condition',
  documentationLink:
    'https://docs.respell.ai/steps-reference/flow-tools/condition',
  category: 'flow',
  options: {
    conditions: {
      key: 'conditions',
      name: 'Conditions',
      description:
        'Conditions for moving to each possible path this step connects to.',
      listDepth: 1,
      type: 'condition',
      isOptional: false,
      value: [],
    },
  },
  outputs: {},
} satisfies StepDefinition;
