import { StepDefinition } from '../../types';

export default {
  key: 'search_apollo_organizations',
  name: 'Search Organizations',
  description:
    'Searches Apollo for organizations matching a query. This will consume credits on your Apollo account.',
  icon: 'i-respell-apollo',
  category: 'integrations',
  services: ['apollo'],
  options: {
    query: {
      key: 'query',
      name: 'Query',
      description: 'The search query.',
      type: 'text/plain',
      isOptional: false,
      metadata: {
        icon: 'i-ph-subtitles-bold',
      },
      listDepth: 0,
      value: null,
    },
    organization_locations: {
      key: 'organization_locations',
      name: 'Location',
      description:
        'The location of the persons company to limit results by. Example: California, US',
      type: 'text/plain',
      isOptional: true,
      metadata: {
        icon: 'i-ph-subtitles-bold',
      },
      listDepth: 0,
      value: null,
    },
  },
  outputs: {
    results: {
      metadata: {
        schema: {
          name: {
            key: 'name',
            name: 'Name',
            description: 'The name of the organization.',
            type: 'text/plain',
            isOptional: false,
            metadata: {
              icon: 'i-ph-subtitles-bold',
            },
            listDepth: 0,
            value: null,
          },
          website: {
            key: 'website',
            name: 'Website',
            description: 'The website URL of the organization.',
            type: 'text/plain',
            isOptional: false,
            metadata: {
              icon: 'i-ph-subtitles-bold',
            },
            listDepth: 0,
            value: null,
          },
          linkedin_url: {
            key: 'linkedin_url',
            name: 'LinkedIn URL',
            description: 'The LinkedIn URL of the organization.',
            type: 'text/plain',
            isOptional: false,
            metadata: {
              icon: 'i-ph-subtitles-bold',
            },
            listDepth: 0,
            value: null,
          },
          twitter_url: {
            key: 'twitter_url',
            name: 'Twitter URL',
            description: 'The Twitter URL of the organization.',
            type: 'text/plain',
            isOptional: false,
            metadata: {
              icon: 'i-ph-subtitles-bold',
            },
            listDepth: 0,
            value: null,
          },
          facebook_url: {
            key: 'facebook_url',
            name: 'Facebook URL',
            description: 'The Facebook URL of the organization.',
            type: 'text/plain',
            isOptional: false,
            metadata: {
              icon: 'i-ph-subtitles-bold',
            },
            listDepth: 0,
            value: null,
          },
          phone: {
            key: 'phone',
            name: 'Phone',
            description: 'The phone number of the organization.',
            type: 'text/plain',
            isOptional: false,
            metadata: {
              icon: 'i-ph-subtitles-bold',
            },
            listDepth: 0,
            value: null,
          },
          founded_year: {
            key: 'founded_year',
            name: 'Founded Year',
            description: 'The year the organization was founded.',
            type: 'text/plain',
            isOptional: false,
            metadata: {
              icon: 'i-ph-subtitles-bold',
            },
            listDepth: 0,
            value: null,
          },
        },
      },
      name: 'Search Results',
      type: 'object',
      description: 'Organizations matching your query.',
      listDepth: 1,
      isOptional: false,
      value: null,
      key: 'results',
    },
  },
} satisfies StepDefinition;
