import { StepDefinition } from '../../types';

export default {
  key: 'add_row_to_table',
  name: 'Add Row To Table',
  description: 'Add a new row to an Airtable table.',
  icon: 'i-respell-airtable',
  // documentationLink: 'https://docs.respell.ai/airtable',
  category: 'integrations',
  services: ['airtable'],
  options: {
    base_id: {
      key: 'base_id',
      name: 'Base',
      description: 'The base where the target table is located.',
      isOptional: false,
      type: 'text/plain',
      listDepth: 0,
      metadata: {
        options: {
          reference: {
            namespace: 'airtable',
            member: 'get_bases',
          },
        },
      },
      value: null,
    },
    table_id: {
      key: 'table_id',
      name: 'Table',
      description: 'The table to insert into.',
      isOptional: false,
      type: 'text/plain',
      conditions: [
        {
          reference: 'base_id',
          type: 'not',
          value: null,
        },
      ],
      metadata: {
        options: {
          reference: {
            namespace: 'airtable',
            member: 'get_tables',
            arguments: [
              {
                namespace: 'options',
                member: 'base_id',
                parameter: 'baseId',
              },
            ],
          },
        },
      },
      listDepth: 0,
      value: null,
    },
    fields: {
      key: 'fields',
      name: 'Fields',
      description: 'The fields to insert into the table.',
      isOptional: false,
      type: 'object',
      listDepth: 0,
      conditions: [
        {
          reference: 'table_id',
          type: 'not',
          value: null,
        },
      ],
      metadata: {
        schema: {
          reference: {
            namespace: 'airtable',
            member: 'get_headers',
            arguments: [
              {
                namespace: 'options',
                member: 'table_id',
                parameter: 'tableId',
              },
              {
                namespace: 'options',
                member: 'base_id',
                parameter: 'baseId',
              },
            ],
          },
        },
      },
      value: {},
    },
  },
  outputs: {},
} satisfies StepDefinition;
