import { TriggerDefinition } from '../../../definition';

export default {
  key: 'new_envelope',
  name: 'New Envelope',
  description: 'An envelope is completed in Docusign.',
  icon: 'i-respell-docusign',
  options: {},
  outputs: {
    envelopeId: {
      key: 'envelopeId',
      name: 'Envelope ID',
      isOptional: false,
      type: 'text/plain',
      listDepth: 0,
      value: '',
    },
    file: {
      key: 'file',
      name: 'Combined PDF',
      isOptional: false,
      type: 'file/document',
      listDepth: 0,
      value: null,
    },
  },
} satisfies TriggerDefinition;
