import { StepDefinition } from '../../types';

export default {
  key: 'update_contact',
  name: 'Update Contact',
  description: 'Updates a HubSpot Contact',
  icon: 'i-respell-hubspot',
  category: 'integrations',
  services: ['hubspot'],
  options: {
    contactId: {
      key: 'contactId',
      name: 'Contact ID',
      type: 'text/plain',
      isOptional: false,
      listDepth: 0,
      value: null,
      metadata: {
        allowsCustom: true,
        options: {
          reference: {
            namespace: 'hubspot',
            member: 'get_contacts',
          },
        },
        schema: {
          name: {
            key: 'name',
            type: 'text/plain',
            name: 'Contact ID',
            isOptional: false,
            listDepth: 0,
            value: null,
          },
        },
      },
    },
    contact: {
      key: 'contact',
      name: 'Contact',
      type: 'object',
      metadata: {
        schema: {
          reference: {
            namespace: 'hubspot',
            member: 'get_properties_contact_update',
          },
        },
      },
      isOptional: false,
      listDepth: 0,
      value: null,
    },
  },
  outputs: {},
} satisfies StepDefinition;
