import { StepDefinition } from '../../types';

export default {
  key: 'read_sheet',
  name: 'Read Google Sheet',
  description: 'Reads a Google Sheet as csv',
  icon: 'i-respell-google',
  category: 'integrations',
  services: ['google-sheet'],
  options: {
    file_id: {
      key: 'file_id',
      name: 'File ID',
      description: 'The id of the Google Sheet.',
      isOptional: false,
      type: 'text/plain',
      listDepth: 0,
      value: null,
      metadata: {
        options: {
          reference: {
            namespace: 'google-sheet',
            member: 'list_sheets',
          },
        },
      },
    },
  },
  outputs: {
    content: {
      key: 'content',
      name: 'Content',
      description: 'The content of the file.',
      isOptional: false,
      type: 'text/plain',
      metadata: {
        isMultiline: true,
      },
      listDepth: 0,
      value: null,
    },
  },
} satisfies StepDefinition;
