import { StepDefinition } from '../../types';

export default {
  key: 'calendly_get_user',
  name: 'Get User',
  description: 'Gathers metadata about your calendly user such as your ID',
  icon: 'i-respell-calendly',
  category: 'integrations',
  services: ['calendly'],
  options: {},
  outputs: {
    user: {
      key: 'user',
      name: 'User Metadata',
      type: 'object',
      isOptional: false,
      listDepth: 0,
      metadata: {
        schema: {
          url: {
            key: 'uri',
            name: 'Url',
            type: 'text/url',
          },
          scheduling_url: {
            key: 'scheduling_url',
            name: 'Scheduling Url',
            type: 'text/url',
          },
          timezone: {
            key: 'timezone',
            name: 'Timezone',
            type: 'text/plain',
          },
        },
      },
      value: null,
    },
  },
} satisfies StepDefinition;
