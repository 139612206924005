import { StepDefinition } from '../../types';

export default {
  key: 'enrich_pdl_person',
  name: 'Enrich Person',
  description:
    'Provides more information about a specific person (enriching) given some unique identifiers.',
  icon: 'i-respell-peopledatalabs',
  category: 'integrations',
  services: ['peopledatalabs'],
  options: {
    first_name: {
      key: 'first_name',
      name: 'First Name',
      description: 'First name of the person.',
      type: 'text/plain',
      isOptional: true,
      metadata: {
        icon: 'i-ph-subtitles-bold',
      },
      listDepth: 0,
      value: null,
    },
    last_name: {
      key: 'last_name',
      name: 'Last Name',
      description: 'Last name of the person.',
      type: 'text/plain',
      isOptional: true,
      listDepth: 0,
      metadata: {
        icon: 'i-ph-text-align-left',
      },
      value: null,
    },
    location: {
      key: 'location',
      name: 'Location',
      description:
        'The location where a person lives. This can be anything from a street address to a country name.	',
      type: 'text/plain',
      isOptional: true,
      listDepth: 0,
      metadata: {
        icon: 'i-ph-location',
        hint: 'Medford, OR USA',
      },
      value: null,
    },
    email: {
      key: 'email',
      name: 'Email',
      description: 'Email of the person.',
      type: 'text/plain',
      isOptional: true,
      listDepth: 0,
      metadata: {
        icon: 'i-ph-email',
      },
      value: null,
    },
    organization_name: {
      key: 'organization_name',
      name: 'Organization Name',
      description: 'Current organization/company of the person.',
      type: 'text/plain',
      isOptional: true,
      listDepth: 0,
      metadata: {
        icon: 'i-ph-building',
      },
      value: null,
    },
    domain: {
      key: 'domain',
      name: 'Website',
      description:
        'Social media URLs for the contact (e.g., Facebook, Twitter, LinkedIn)',
      type: 'text/plain',
      isOptional: true,
      listDepth: 0,
      metadata: {
        icon: 'i-ph-globe',
      },
      value: null,
    },
  },
  outputs: {
    person: {
      metadata: {
        schema: {
          full_name: {
            key: 'full_name',
            name: 'Name',
            description: "The person's name.",
            type: 'text/plain',
            isOptional: false,
            metadata: {
              icon: 'i-ph-subtitles-bold',
            },
            listDepth: 0,
            value: null,
          },
          mobile_phone: {
            key: 'mobile_phone',
            name: 'Phone',
            description: "The person's phone number.",
            type: 'text/phone-number',
            isOptional: true,
            listDepth: 0,
            metadata: {
              icon: 'i-ph-phone',
            },
            value: null,
          },
          linkedin_url: {
            key: 'linkedin_url',
            name: 'LinkedIn URL',
            description: "The person's LinkedIn URL.",
            type: 'text/url',
            isOptional: true,
            listDepth: 0,
            metadata: {
              icon: 'i-ph-linkedin',
            },
            value: null,
          },
          job_title: {
            key: 'job_title',
            name: 'Title',
            description: "The person's title.",
            type: 'text/plain',
            isOptional: true,
            listDepth: 0,
            metadata: {
              icon: 'i-ph-badge',
            },
            value: null,
          },
          location_region: {
            key: 'location_region',
            name: 'State',
            description: "The person's state.",
            type: 'text/plain',
            isOptional: true,
            listDepth: 0,
            metadata: {
              icon: 'i-ph-location',
            },
            value: null,
          },
          location_locality: {
            key: 'location_locality',
            name: 'City',
            description: "The person's city.",
            type: 'text/plain',
            isOptional: true,
            listDepth: 0,
            metadata: {
              icon: 'i-ph-location',
            },
            value: null,
          },
          work_email: {
            key: 'work_email',
            name: 'Work Email',
            description: 'Work email of the person.',
            type: 'text/email',
            isOptional: true,
            listDepth: 0,
            metadata: {
              icon: 'i-ph-email',
            },
            value: null,
          },
          job_company_name: {
            key: 'job_company_name',
            name: 'Current Company',
            description: "The person's current company.",
            type: 'text/plain',
            isOptional: true,
            listDepth: 0,
            metadata: {
              icon: 'i-ph-building',
            },
            value: null,
          },
        },
      },
      name: 'Enriched Person Details',
      description: 'Details of the enriched person.',
      type: 'object',
      listDepth: 0,
      isOptional: false,
      value: null,
      key: 'person',
    },
  },
} satisfies StepDefinition;
