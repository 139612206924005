import { StepDefinition } from '../../types';

export default {
  key: 'search_web',
  name: 'Search Google',
  description:
    'Searches Google with a query, returning the search engine results.',
  icon: 'i-respell-search-web',
  documentationLink:
    'https://docs.respell.ai/steps-reference/web-code-tools/search-google',
  category: 'web',
  options: {
    query: {
      key: 'query',
      name: 'Search Term',
      listDepth: 0,
      description: 'The term you want to search Google for.',
      type: 'text/plain',
      isOptional: false,
      value: null,
    },
    type: {
      key: 'type',
      name: 'Type',
      listDepth: 0,
      description: 'Type of search to perform.',
      type: 'text/plain',
      metadata: {
        options: [
          {
            key: 'web',
            name: 'Websites ',
          },
          {
            key: 'news',
            name: 'News Articles',
          },
          {
            key: 'paper',
            name: 'Research Papers',
          },
        ],
      },
      isOptional: false,
      value: 'web',
    },
  },
  outputs: {
    results: {
      key: 'results',
      name: 'Search Results',
      description:
        'The search results from Google. Includes the first page of results.',
      type: 'text/plain',
      listDepth: 0,
      isOptional: false,
      value: null,
    },
  },
} satisfies StepDefinition;
