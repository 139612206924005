import { StepDefinition } from '../../types';

export default {
  key: 'create_apollo_account',
  name: 'Create Account',
  description: 'Creates an Apollo account.',
  icon: 'i-respell-apollo',
  category: 'integrations',
  services: ['apollo'],
  options: {
    name: {
      key: 'name',
      name: 'Name',
      description: "The account's name.",
      type: 'text/plain',
      isOptional: false,
      metadata: {
        icon: 'i-ph-subtitles-bold',
      },
      listDepth: 0,
      value: null,
    },
    domain: {
      key: 'domain',
      name: 'Domain',
      description: 'The domain of the account you are adding',
      type: 'text/plain',
      isOptional: false,
      listDepth: 0,
      metadata: {
        icon: 'i-ph-text-align-left',
      },
      value: null,
    },
    phone_number: {
      key: 'phone_number',
      name: 'Phone Number',
      description: 'The corporate phone for this account.',
      type: 'text/phone-number',
      isOptional: true,
      listDepth: 0,
      metadata: {
        icon: 'i-ph-phone',
      },
      value: null,
    },
    raw_address: {
      key: 'raw_address',
      name: 'Raw Address',
      description:
        'The address string for this account, Apollo will intelligently infer the city, state, country, and time zone from your address',
      type: 'text/plain',
      isOptional: true,
      listDepth: 0,
      metadata: {
        icon: 'i-ph-location',
      },
      value: null,
    },
  },
  outputs: {},
} satisfies StepDefinition;
