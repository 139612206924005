import { Integration } from '../definition';

export default {
  key: 'linear',
  name: 'Linear',
  icon: 'i-respell-linear',
  options: [],
  postAuthOptions: [
    {
      key: 'teamId',
      name: 'Team',
      description: 'Which team would you like Respell to use?',
      isOptional: false,
      type: 'text/plain',
      listDepth: 0,
      metadata: {
        options: {
          reference: {
            namespace: 'linear',
            member: 'get_teams',
          },
        },
      },
      value: null,
    },
  ],
} satisfies Integration;
