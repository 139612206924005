import {
  Issue as IssueRaw,
  IssueLabel as LabelRaw,
  Project as ProjectRaw,
  Team as TeamRaw,
  User as UserRaw,
} from '@linear/sdk';
import { JsonObject } from '@respell/utils';
import { Model } from '../definition';

export interface Label {
  /** The unique identifier of the entity. */
  id: string;
  /** The team's name. */
  name: string;
}
export interface Team extends JsonObject {
  /** The team's description. */
  description?: string;
  /** The icon of the team. */
  icon?: string;
  /** The unique identifier of the entity. */
  id: string;
  /** The team's name. */
  name: string;
}
export interface Issue extends JsonObject {
  name: string;
  createdAt: Date;
  id: string;
  identifier: string;
  priority?: string;
  dueDate?: Date;
  description?: string;
  branchName: string;
  startedAt?: Date;
  url: string;
  completedAt?: Date;
  team: string | Team;
}
export interface Project extends JsonObject {
  content?: string;
  description: string;
  id: string;
  name: string;
  url: string;
}
export interface User {
  displayName: string;
  email: string;
  id: string;
}
export const ProjectModel: Model<Project> = {
  schema: {
    content: {
      key: 'content',
      name: 'Content',
      description: "The project's content in markdown format.",
      type: 'text/plain',
      isOptional: true,
      listDepth: 0,
      metadata: {
        icon: 'fingerprint',
      },
      value: null,
    },
    description: {
      key: 'description',
      name: 'Description',
      description: "The project's description.",
      type: 'text/plain',
      isOptional: false,
      listDepth: 0,
      metadata: {
        icon: 'fingerprint',
      },
      value: null,
    },
    id: {
      key: 'id',
      name: 'ID',
      description: 'The unique identifier of the project.',
      type: 'text/plain',
      isOptional: false,
      listDepth: 0,
      metadata: {
        icon: 'fingerprint',
      },
      value: null,
    },
    name: {
      key: 'name',
      name: 'Name',
      description: "The project's name.",
      type: 'text/plain',
      isOptional: false,
      listDepth: 0,
      metadata: {
        icon: 'fingerprint',
      },
      value: null,
    },
    url: {
      key: 'url',
      name: 'URL',
      description: "The project's URL.",
      type: 'text/url',
      isOptional: false,
      listDepth: 0,
      metadata: {
        icon: 'fingerprint',
      },
      value: null,
    },
  },
  parse(data: unknown): Project {
    const value = data as ProjectRaw;

    return {
      name: value.name,
      content: value.content,
      description: value.description,
      url: value.url,
      id: value.id,
    };
  },
};
export const TeamModel: Model<Team> = {
  schema: {
    name: {
      type: 'text/plain',
      name: 'Name',
      value: null,
      listDepth: 0,
      isOptional: false,
      key: 'name',
    },
    description: {
      type: 'text/plain',
      name: 'Description',
      value: null,
      listDepth: 0,
      isOptional: true,
      key: 'description',
    },
    icon: {
      type: 'text/plain',
      name: 'Icon',
      value: null,
      listDepth: 0,
      isOptional: true,
      key: 'icon',
    },
    id: {
      type: 'text/plain',
      name: 'ID',
      value: null,
      listDepth: 0,
      isOptional: false,
      key: 'id',
    },
  },
  parse(data: unknown): Team {
    const value = data as TeamRaw;

    return {
      name: value.name,
      description: value.description,
      icon: value.icon,
      id: value.id,
    };
  },
};
export const IssueModel: Model<Issue> = {
  schema: {
    branchName: {
      key: 'branchName',
      name: 'Branch Name',
      description: 'Suggested branch name for the issue.',
      type: 'text/plain',
      isOptional: false,
      listDepth: 0,
      value: null,
    },
    completedAt: {
      key: 'completedAt',
      name: 'Completed At',
      description:
        'The time at which the issue was moved into completed state.',
      type: 'date',
      isOptional: true,
      listDepth: 0,
      value: null,
    },
    createdAt: {
      key: 'createdAt',
      name: 'Created At',
      description: 'The time at which the entity was created.',
      type: 'date',
      isOptional: false,
      listDepth: 0,
      value: null,
    },
    description: {
      key: 'description',
      name: 'Description',
      description: 'The time at which the entity was created.',
      type: 'text/plain',
      metadata: {
        icon: 'i-ph-text-align-left',
      },
      isOptional: true,
      listDepth: 0,
      value: null,
    },
    dueDate: {
      key: 'dueDate',
      name: 'Due Date',
      description: 'The date at which the issue is due.',
      type: 'date',
      isOptional: true,
      listDepth: 0,
      value: null,
    },
    id: {
      key: 'id',
      name: 'ID',
      description: 'The unique identifier of the entity.',
      type: 'text/plain',
      isOptional: false,
      listDepth: 0,
      value: null,
    },
    identifier: {
      key: 'identifier',
      name: 'Identifier',
      description: "Issue's human readable identifier (e.g. ENG-123).",
      type: 'text/plain',
      isOptional: false,
      listDepth: 0,
      value: null,
    },
    priority: {
      key: 'priority',
      name: 'Priority',
      description: 'Label for the priority.',
      type: 'text/plain',
      isOptional: true,
      listDepth: 0,
      value: null,
    },
    startedAt: {
      key: 'startedAt',
      name: 'Started At',
      description: 'The date at which the issue was started.',
      type: 'date',
      isOptional: true,
      listDepth: 0,
      value: null,
    },
    name: {
      key: 'name',
      name: 'Title',
      description: "The issue's title.",
      type: 'text/plain',
      isOptional: false,
      metadata: {
        icon: 'i-ph-subtitles-bold',
      },
      listDepth: 0,
      value: null,
    },
    url: {
      key: 'url',
      name: 'URL',
      description: "The issue's URL.",
      type: 'text/url',
      isOptional: false,
      listDepth: 0,
      metadata: {
        icon: 'fingerprint',
      },
      value: null,
    },
    team: {
      key: 'team',
      name: 'Team',
      description: 'The name of the team this issue belongs to.',
      type: 'text/plain',
      isOptional: false,
      listDepth: 0,
      value: null,
    },
  },
  parse(data: unknown): Issue {
    const value = data as IssueRaw;

    return {
      name: value.title,
      team: TeamModel.parse(value.team),
      createdAt: value.createdAt,
      id: value.id,
      identifier: value.identifier,
      branchName: value.branchName,
      description: value.description,
      priority: value.priorityLabel,
      startedAt: value.startedAt,
      url: value.url,
    };
  },
};
export const UserModel: Model<User> = {
  schema: {
    displayName: {
      key: 'displayName',
      name: 'Display Name',
      description: "The user's display (nick) name.",
      type: 'text/plain',
      isOptional: false,
      listDepth: 0,
      metadata: {
        icon: 'fingerprint',
      },
      value: null,
    },
    email: {
      key: 'email',
      name: 'Email',
      description: "The user's email address.",
      type: 'text/email',
      isOptional: false,
      listDepth: 0,
      metadata: {
        icon: 'fingerprint',
      },
      value: null,
    },
    id: {
      key: 'id',
      name: 'ID',
      description: 'The unique identifier of the user.',
      type: 'text/plain',
      isOptional: false,
      listDepth: 0,
      metadata: {
        icon: 'fingerprint',
      },
      value: null,
    },
  },
  parse(data: unknown): User {
    const value = data as UserRaw;

    return {
      email: value.email,
      id: value.id,
      displayName: value.displayName,
    };
  },
};
export const LabelModel: Model<Label> = {
  schema: {
    name: {
      key: 'name',
      name: 'Name',
      description: "The label's name.",
      type: 'text/plain',
      isOptional: false,
      listDepth: 0,
      metadata: {
        icon: 'fingerprint',
      },
      value: null,
    },
    id: {
      key: 'id',
      name: 'ID',
      description: 'The unique identifier of the user.',
      type: 'text/plain',
      isOptional: false,
      listDepth: 0,
      metadata: {
        icon: 'fingerprint',
      },
      value: null,
    },
  },
  parse(data: unknown): Label {
    const value = data as LabelRaw;

    return {
      name: value.name,
      id: value.id,
    };
  },
};
