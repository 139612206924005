import { StepDefinition } from '../../types';

export default {
  key: 'summarize_text',
  name: 'Summarize Text',
  description: 'Generates a summary of the specified text.',
  icon: 'i-respell-summarize-text',
  documentationLink:
    'https://docs.respell.ai/steps-reference/text-tools/summarize-text',
  category: 'text',
  options: {
    text: {
      key: 'text',
      name: 'Text to Summarize',
      description: 'The text you want to summarize.',
      isOptional: false,
      type: 'text/plain',
      listDepth: 0,
      metadata: {
        max: 128000,
        isMultiline: true,
      },
      value: null,
    },
  },
  outputs: {
    text: {
      key: 'text',
      name: 'Summary',
      type: 'text/plain',
      isOptional: false,
      listDepth: 0,
      metadata: {
        icon: 'i-ph-subtitles-bold',
      },
      value: null,
    },
  },
} satisfies StepDefinition;
