import { StepDefinition } from '../../types';

export default {
  key: 'research_agent',
  name: 'Research Agent',
  description:
    'Searches Google and visits webpages to research a topic, person, company, etc.',
  icon: 'i-respell-research-agent',
  documentationLink:
    'https://docs.respell.ai/steps-reference/web-code-tools/research-agent',
  category: 'web',
  options: {
    query: {
      key: 'query',
      name: 'Research Query',
      listDepth: 0,
      description:
        'The term you want to research. If this is text, the agent will begin its research by searching Google. If it’s a URL, the agent will begin its research by visiting that website.',
      type: 'text/plain',
      isOptional: false,
      value: null,
    },
    urls: {
      key: 'urls',
      name: 'Source URLs',
      listDepth: 1,
      description:
        'URLs to use as research sources. This will restrict the agent to only visiting these URLs.',
      type: 'text/url',
      isOptional: true,
      value: [],
    },
    detail: {
      key: 'detail',
      name: 'Research Detail',
      listDepth: 0,
      description:
        'How detailed you want the research report to be. High detail may cause the agent to take longer, but the research with be more thorough.',
      metadata: {
        options: [
          {
            key: 'low',
            id: 'low',
            name: 'Low',
          },
          {
            key: 'medium',
            id: 'medium',
            name: 'Medium',
          },
          {
            key: 'high',
            id: 'high',
            name: 'High',
          },
        ],
      },
      type: 'text/plain',
      isOptional: false,
      value: 'medium',
    },
    length: {
      key: 'length',
      name: 'Research Report Length',
      listDepth: 0,
      description: 'How long you want the report to be in words.',
      type: 'number/integer',
      metadata: {
        min: 0,
        max: 2000,
      },
      isOptional: false,
      value: 750,
    },
  },
  outputs: {
    report: {
      key: 'report',
      name: 'Research Report',
      description: 'The research report generated by the agent.',
      type: 'text/plain',
      listDepth: 0,
      isOptional: false,
      value: null,
    },
  },
} satisfies StepDefinition;
