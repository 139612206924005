import { StepDefinition } from '../../types';

export default {
  key: 'generate_text',
  name: 'Generate Text with AI',
  description: 'Creates text content using AI based on your instructions.',
  icon: 'i-respell-generate-text',
  documentationLink:
    'https://docs.respell.ai/steps-reference/text-tools/generate-text',
  category: 'text',
  groups: {
    basic: {
      key: 'basic',
      name: 'Basic',
    },
    advanced: {
      key: 'advanced',
      name: 'Advanced',
    },
  },
  options: {
    model: {
      key: 'model',
      name: 'AI Model',
      description: 'Choose which AI model you want to use for generating text.',
      isOptional: false,
      type: 'text/plain',
      listDepth: 0,
      metadata: {
        group: 'basic',
        preventInject: true,
        options: {
          reference: {
            member: 'get_models',
          },
        },
      },
      value: 'openai/gpt_4',
    },
    prompt: {
      key: 'prompt',
      name: 'Instructions',
      description:
        'Write what you want the AI to generate. This could be writing a story, answering a question, or any other text-based task.',
      isOptional: false,
      type: 'text/plain',
      listDepth: 0,
      metadata: {
        group: 'basic',
        hint: 'Create an outline for my paper...\nWrite a poem in the style of...\nAnalyze the tone of this email...',
        max: 128000,
        isMultiline: true,
        isPrompt: true,
      },
      value: null,
    },
    temperature: {
      key: 'temperature',
      name: 'Temperature',
      description:
        "Controls how creative the AI's response will be. Higher values (closer to 2.0) make responses more varied and creative, while lower values (closer to 0.0) make responses more focused and predictable.",
      isOptional: true,
      type: 'number/decimal',
      conditions: [
        {
          reference: 'model',
          type: 'doesNotContain',
          value: 'o1',
        },
      ],
      metadata: {
        group: 'advanced',
        preventInject: true,
        min: 0.0,
        max: 2.0,
      },
      listDepth: 0,
      value: 0.7,
    },
    maxTokens: {
      key: 'maxTokens',
      name: 'Max Tokens',
      description:
        "Sets the maximum length (in tokens) of the AI's response. Higher numbers allow for longer responses.",
      isOptional: true,
      type: 'number/integer',
      listDepth: 0,
      conditions: [
        {
          reference: 'model',
          type: 'doesNotContain',
          value: 'o1',
        },
      ],
      metadata: {
        group: 'advanced',
        preventInject: true,
        min: 1,
        max: 4000,
      },
      value: 4000,
    },
    isJson: {
      key: 'isJson',
      name: 'Structured Data Mode',
      description:
        'Enable this if you need the AI to output structured data (JSON format) instead of regular text.',
      isOptional: true,
      type: 'boolean',
      listDepth: 0,
      value: false,
      conditions: [
        {
          reference: 'model',
          type: 'contains',
          value: 'azure',
          or: [
            {
              reference: 'model',
              type: 'contains',
              value: 'gemini',
            },
            {
              reference: 'model',
              type: 'contains',
              value: 'openai',
            },
          ],
        },
        {
          reference: 'model',
          type: 'doesNotContain',
          value: 'o1',
        },
      ],
      metadata: {
        group: 'advanced',
        preventInject: true,
      },
    },
    system_instruction: {
      key: 'system_instruction',
      name: 'System Instruction',
      description:
        'Define how the AI should behave when responding (e.g., You are a teacher, writer, or expert in a specific field).',
      isOptional: true,
      type: 'text/plain',
      listDepth: 0,
      conditions: [
        {
          reference: 'model',
          type: 'doesNotContain',
          value: 'o1',
        },
      ],
      metadata: {
        group: 'advanced',
        max: 128000,
        isMultiline: true,
        isPrompt: true,
      },
      value: 'You are a helpful assistant.',
    },
  },
  outputs: {
    response: {
      key: 'response',
      name: 'Response',
      description: 'The text generated by the AI based on your instructions.',
      type: 'text/plain',
      listDepth: 0,
      isOptional: false,
      value: null,
    },
  },
} satisfies StepDefinition;
