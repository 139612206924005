<script setup lang="ts">
import type { DataType } from '@respell/utils';
import { typeMap } from '@respell/utils';
import BulletList from '@tiptap/extension-bullet-list';
import Document from '@tiptap/extension-document';
import ListItem from '@tiptap/extension-list-item';
import Paragraph from '@tiptap/extension-paragraph';
import Placeholder from '@tiptap/extension-placeholder';
import Text from '@tiptap/extension-text';
import { EditorContent, useEditor } from '@tiptap/vue-3';
import { AsYouType } from 'libphonenumber-js';
import AppCopyButton from '~/components/app/AppCopyButton.vue';
import VariablePicker from '~/components/editor/config/VariablePicker.vue';
import PromptWizard from '~/components/modals/PromptWizard.vue';
import TextModal from '~/components/modals/TextModal.vue';
import {
  Numeric,
  PreventEnter,
  ShiftSpace,
  Variable,
  escapeHtml,
  textToHtml,
} from '~/util/tiptap';

export interface TextEditorProps {
  isMultiline?: boolean;
  placeholder?: string;
  type?: DataType;
  injectable?: boolean;
  isStreaming?: boolean;
  readonly?: boolean;
  hasError?: boolean;
  isSample?: boolean;
  isPrompt?: boolean;
  showWizard?: boolean;
  expanded?: boolean;
  title?: string;
  description?: string;
}

const modelValue = defineModel<string | number>();
const props = defineProps<TextEditorProps>();

const toast = useToast();
const modal = useModal();
const { emitFormChange } = useFormGroup();

const isFocused = ref(false);

// Initialize the editor
const editor = useEditor({
  editorProps: {
    attributes: {
      class: 'grow h-full focus:outline-none',
    },
  },
  editable: !props.readonly,
  extensions: [
    Document,
    Text,
    Paragraph,
    Placeholder.configure({
      placeholder: props.placeholder,
      emptyNodeClass: `${props.isMultiline ? '' : 'truncate'}`,
      showOnlyWhenEditable: false,
    }),
    ListItem,
    BulletList.extend({
      renderText({ node }) {
        const jsonList = node.content.toJSON();
        const stringList = jsonList
          .map((item) => {
            return `- ${item.content[0].content
              ?.map((item) => item.attrs?.id ?? item.text)
              .join(' ')}`;
          })
          .join('\n');
        return stringList;
      },
    }),
    Variable.configure({
      mode: props.isPrompt
        ? 'context'
        : props.isSample
          ? 'readonly'
          : 'editable',
    }),
    ShiftSpace,
    props.type?.startsWith('number')
      ? Numeric.configure({ allowDecimals: props.type === 'number/decimal' })
      : null!,
    PreventEnter.configure({ type: props.type }),
  ],
  onCreate: ({ editor }) => {
    // Replace variable placeholders with HTML spans to be rendered by variable node
    const content = modelValue.value?.toString() ?? '';

    const wrappedContent = textToHtml(escapeHtml(content));

    editor.commands.setContent(wrappedContent, false);
  },
  onUpdate: ({ editor }) => {
    const value = editor.getText({ blockSeparator: '\n' });

    let parsedValue: string | number = value;

    // Check if the value is an injected variable
    const isInjectedVariable = variableRegex.test(value);

    if (!isInjectedVariable) {
      if (props.type === 'number/integer') {
        parsedValue = parseInt(value, 10);
        if (isNaN(parsedValue)) parsedValue = null;
      } else if (props.type === 'number/decimal') {
        parsedValue = parseFloat(value);
        if (isNaN(parsedValue)) parsedValue = null;
      } else if (props.type === 'text/phone-number') {
        parsedValue = new AsYouType().input(value);
        editor.commands.setContent(parsedValue);
      }
    }

    modelValue.value = parsedValue;

    emitFormChange();
  },
  onFocus: ({ editor }) => {
    isFocused.value = true;
  },
  onBlur: ({ editor }) => {
    isFocused.value = false;
  },
});

// TODO: Use the editor ref's height here instead of text length
const isTextarea = computed(
  () =>
    props.isMultiline ||
    (typeof modelValue.value === 'string' &&
      (modelValue.value.length > 50 || modelValue.value.includes('\n'))),
);

// Function to inject a variable into the editor content
function injectVariable({ variable, step }) {
  const variableNode = {
    type: 'variable',
    attrs: {
      id: `{{${step.slug}.${variable.key}}}`,
    },
  };

  const transaction = editor.value
    ?.chain()
    .focus()
    .insertContent(variableNode)
    .insertContent(isTextarea.value ? ' ' : '')
    .run();

  if (!transaction) {
    console.error('Failed to insert variable');
  } else if (variable.type !== props.type) {
    toast.add({
      id: variable.key as string,
      title: 'Variable type mismatch',
      description:
        'We will do our best to convert this variable for you, or you can apply a transformation yourself',
    });
  }
}

function openTextModal() {
  modal.open(TextModal, {
    ...props,
    text: modelValue.value as string,
    onClose(text) {
      editor.value?.commands.setContent(textToHtml(text), true);
    },
  });
}

// FOR PROMPT WIZARD
function openPromptWizard() {
  modal.open(PromptWizard, {
    initialPrompt: modelValue.value as string,
    onAccept(prompt) {
      editor.value?.commands.setContent(textToHtml(prompt), true);
    },
  });
}

if (props.isSample || props.isPrompt) {
  watch(
    () => props.isStreaming,
    (isStreaming, wasStreaming) => {
      if (isStreaming && !wasStreaming) {
        watch(modelValue, (v) => editor.value?.commands.setContent(v, false));
      }
    },
  );

  watch(
    () => props.readonly,
    (readonly) => {
      if (readonly) {
        editor.value?.setEditable(false, false);
      } else {
        editor.value?.setEditable(true, false);
      }
    },
  );
}
</script>

<template>
  <div
    :class="[
      expanded
        ? 'h-full'
        : isPrompt || isSample
          ? 'h-96'
          : readonly || !isTextarea
            ? 'h-auto max-h-60'
            : 'h-60',
      expanded
        ? '!ring-0 !focus:ring-0 !p-7'
        : hasError
          ? 'ring-2 ring-red-500 dark:ring-red-400'
          : isFocused
            ? 'ring-2 ring-primary-500 dark:ring-primary-400'
            : 'ring-1 ring-gray-300 dark:ring-gray-700 ',
      'pe-12',
      'overflow-y-scroll flex gap-2.5 ring-inset !p-2.5 !pr-2.5 justify-start relative w-full disabled:cursor-not-allowed disabled:opacity-75 border-0 form-select rounded-md text-base shadow-sm bg-white dark:bg-gray-9000 text-gray-900 dark:text-white',
    ]"
    style="scrollbar-color: #c0c1d4 transparent; overflow-y: scroll"
  >
    <UIcon
      v-if="!isTextarea"
      :name="typeMap[type].icon"
      class="text-gray-500 text-2xl pt-0.5"
    />

    <ClientOnly>
      <EditorContent
        :editor="editor"
        class="prose grow h-full prose-p:my-0.5 max-w-full self-start prose-ul:my-0.5"
        :class="expanded ? 'prose-lg h-full' : 'prose-xs'"
      />
    </ClientOnly>
    <span
      class="flex flex-col items-end h-full gap-2 sticky top-0 right-0 z-10"
      :class="isTextarea ? 'justify-start mb-auto' : 'justify-center'"
    >
      <AppCopyButton v-if="props.readonly" :text="modelValue" />
      <span v-else class="contents">
        <VariablePicker
          v-if="injectable"
          :handle-click="injectVariable"
          :type="type ?? 'text/plain'"
          :is-active="isFocused"
        />
        <UTooltip v-if="showWizard" text="Prompt with AI">
          <UButton
            size="md"
            variant="ghost"
            :padded="false"
            color="primary"
            icon="i-ph-sparkle-duotone"
            :ui="{
              color: { gray: { ghost: 'text-gray-400' } },
              icon: {
                size: {
                  md: 'h-6 w-6',
                },
              },
            }"
            @click="openPromptWizard"
          />
        </UTooltip>
        <UTooltip
          v-if="isTextarea && !modal.isOpen.value"
          text="Expand to fullscreen"
          class="mt-auto"
        >
          <UButton
            size="md"
            variant="ghost"
            :padded="false"
            color="primary"
            icon="i-ph-frame-corners"
            :ui="{
              color: { gray: { ghost: 'text-gray-400' } },
              icon: {
                size: {
                  md: 'h-6 w-6',
                },
              },
            }"
            @click="openTextModal"
          />
        </UTooltip>
      </span>
    </span>
  </div>
</template>
<style lang="scss">
.tiptap p.is-editor-empty:first-child::before {
  content: attr(data-placeholder);
  float: left;
  color: #adb5bd;
  pointer-events: none;
  height: 0;
}
</style>
