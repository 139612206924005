import { StepDefinition } from '../../types';

export default {
  key: 'query_database',
  name: 'Query Database',
  description: 'Runs a query on a SQL database.',
  icon: 'i-respell-query-database',
  category: 'web',
  documentationLink:
    'https://docs.respell.ai/steps-reference/web-code-tools/query-database',
  options: {
    connectionString: {
      key: 'connectionString',
      name: 'Connection String',
      listDepth: 0,
      description: 'postgres://username:password@host:port/database',
      type: 'text/plain',
      isOptional: false,
      value: null,
    },
    query: {
      key: 'query',
      name: 'Query',
      listDepth: 0,
      metadata: {
        allowedLanguages: ['sql'],
      },
      description: 'The query you want to run.',
      type: 'code',
      isOptional: false,
      value: null,
    },
  },
  outputs: {
    result: {
      key: 'result',
      name: 'Result',
      description: 'Result of the query.',
      type: 'object',
      metadata: {
        schema: {
          reference: {
            member: 'query_outputs',
            arguments: [
              {
                namespace: 'options',
                member: 'connectionString',
                parameter: 'connectionString',
              },
              {
                namespace: 'options',
                member: 'query',
                parameter: 'query',
              },
            ],
          },
        },
      },
      listDepth: 0,
      isOptional: false,
      value: null,
    },
  },
} satisfies StepDefinition;
