import { StepDefinition } from '../../types';

export default {
  key: 'loop',
  name: 'Loop',
  description: 'Iterates a sequence of steps over a provided list.',
  icon: 'i-respell-loop',
  documentationLink: 'https://docs.respell.ai/steps-reference/flow-tools/loop',
  category: 'flow',
  options: {
    // This will be a variable reference. Options will be filtered in the frontend.
    iterable: {
      key: 'iterable',
      name: 'List',
      description:
        'Create a new list or reference an existing one to iterate over in this loop.',
      // TODO: Support 'any' types
      type: 'text/plain',
      isOptional: false,
      listDepth: 1,
      value: [],
    },
    sequence: {
      key: 'sequence',
      name: 'Sequence',
      description:
        'The slugs of the steps to execute for each item in the iterable.',
      type: 'text/plain',
      isOptional: false,
      listDepth: 1,
      value: null,
    },
  },
  outputs: {
    // This is the output that will be exposed within the loop
    element: {
      key: 'element',
      name: 'List Item',
      description: 'The current item in the list.',
      type: 'object',
      isOptional: false,
      listDepth: 0,
      value: null,
      // TODO: Build out get_iterable_schema to dynamically generate the element schema based on the iterable's schema
      metadata: {
        schema: {
          reference: {
            member: 'get_iterable_schema',
            arguments: [
              {
                namespace: 'options',
                member: 'iterable',
                parameter: 'iterable',
              },
            ],
          },
        },
      },
    },
  },
} satisfies StepDefinition;
