import { IssueModel } from '@respell/integrations/linear/models';
import { StepDefinition } from '../../types';

export default {
  key: 'get_assigned_issues',
  name: 'Get Assigned Issues',
  description: 'Gets the issues assigned to the user.',
  icon: 'i-respell-linear',
  //documentationLink: 'https://docs.respell.ai/linear',
  category: 'linear',
  services: ['linear'],
  options: {},
  outputs: {
    issues: {
      key: 'issues',
      name: 'Assigned Issues',
      type: 'object',
      isOptional: false,
      listDepth: 1,
      metadata: {
        icon: 'i-respell-t-box',
        schema: IssueModel.schema,
      },
      value: null,
    },
  },
} satisfies StepDefinition;
