import { Integration } from '../definition';

export default {
  key: 'apollo',
  name: 'Apollo',
  options: [
    {
      key: 'apiKey',
      name: 'API Key',
      isOptional: false,
      type: 'text/plain',
      listDepth: 0,
      metadata: {
        // mapping: ['credentials.apiKey', 'link.metadata.url'],
      },
      value: null,
    },
  ],
  postAuthOptions: [],
  preAuthCaption: `Create a master key at https://developer.apollo.io/keys/ to get started.`,
  icon: 'i-respell-apollo',
} satisfies Integration;
