import { StepDefinition } from '../../types';

export default {
  key: 'attio_update_user',
  name: 'Update user',
  description: 'Updates a user record in Attio',
  icon: 'i-respell-attio',
  category: 'integrations',
  services: ['attio'],
  options: {
    userId: {
      key: 'userId',
      name: 'User ID',
      type: 'text/plain',
      isOptional: false,
      listDepth: 0,
      value: null,
    },
    user: {
      key: 'user',
      name: 'User',
      type: 'object',
      metadata: {
        schema: {
          reference: {
            namespace: 'attio',
            member: 'attio_list_attributes_user',
          },
        },
      },
      isOptional: false,
      listDepth: 0,
      value: null,
    },
  },
  outputs: {},
} satisfies StepDefinition;
