import { StepDefinition } from '../../types';

export default {
  key: 'create_event',
  name: 'Create Calendar Event',
  description: 'Creates a Google Calendar event.',
  icon: 'i-respell-google',
  category: 'integrations',
  services: ['google-calendar'],
  options: {
    title: {
      key: 'title',
      name: 'Title',
      description: 'Title of the calendar event.',
      isOptional: false,
      type: 'text/plain',
      listDepth: 0,
      value: null,
    },
    description: {
      key: 'description',
      name: 'Description',
      description: 'Description of the calendar event.',
      isOptional: true,
      type: 'text/plain',
      listDepth: 0,
      value: null,
    },
    start_time: {
      key: 'start_time',
      name: 'Start Time',
      description: 'Start of the calendar event.',
      isOptional: false,
      type: 'datetime',
      listDepth: 0,
      value: null,
    },
    duration: {
      key: 'duration',
      name: 'Duration',
      description: 'The duration of the event in minutes.',
      isOptional: false,
      type: 'number/integer',
      listDepth: 0,
      value: null,
    },
    invitees: {
      key: 'invitees',
      name: 'Invitee',
      description: 'Email thats invited. Invalid emails will be ignored.',
      isOptional: false,
      type: 'text/email',
      listDepth: 1,
      value: [],
    },
  },
  outputs: {
    event: {
      key: 'event',
      name: 'Event Details',
      description: 'Details of the newly created event.',
      isOptional: false,
      type: 'object',
      listDepth: 0,
      value: null,
    },
  },
} satisfies StepDefinition;
