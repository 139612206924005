import { StepDefinition } from '../../types';

export default {
  key: 'create_comment_linear',
  name: 'Add Comment to Issue',
  description: 'Adds a comment to a Linear issue.',
  icon: 'i-respell-linear',
  //documentationLink: 'https://docs.respell.ai/linear',
  category: 'integrations',
  services: ['linear'],
  options: {
    comment: {
      key: 'comment',
      name: 'Comment',
      description: 'The text of the comment you want to add.',
      type: 'text/plain',
      isOptional: false,
      metadata: {
        icon: 'i-ph-subtitles-bold',
      },
      listDepth: 0,
      value: null,
    },
    issueId: {
      key: 'issueId',
      name: 'Issue ID',
      description: 'ID of the issue you want to retrieve.',
      isOptional: false,
      type: 'text/plain',
      listDepth: 0,
      metadata: {
        options: {
          reference: {
            namespace: 'linear',
            member: 'get_issues',
          },
        },
      },
      value: null,
    },
  },
  outputs: {},
} satisfies StepDefinition;
