import { TriggerDefinition } from '../../../definition';

export default {
  key: 'events',
  name: 'New Calendar Event',
  description: 'A new event is created in your calendar.',
  options: {},
  icon: 'i-respell-google',
  outputs: {
    event: {
      key: 'event',
      name: 'Event',
      isOptional: false,
      type: 'object',
      listDepth: 0,
      value: {},
      metadata: {
        schema: {
          id: {
            key: 'id',
            name: 'Event ID',
            isOptional: false,
            type: 'text/plain',
            listDepth: 0,
            value: '',
          },
          status: {
            key: 'status',
            name: 'Status',
            isOptional: false,
            type: 'text/plain',
            listDepth: 0,
            value: '',
          },
          htmlLink: {
            key: 'htmlLink',
            name: 'HTML Link',
            isOptional: false,
            type: 'text/url',
            listDepth: 0,
            value: '',
          },
          summary: {
            key: 'summary',
            name: 'Summary',
            isOptional: false,
            type: 'text/plain',
            listDepth: 0,
            value: '',
          },
          description: {
            key: 'description',
            name: 'Description',
            isOptional: false,
            type: 'text/plain',
            listDepth: 0,
            value: '',
          },
          location: {
            key: 'location',
            name: 'Location',
            isOptional: false,
            type: 'text/plain',
            listDepth: 0,
            value: '',
          },
          creator: {
            key: 'creator',
            name: 'Creator Email',
            isOptional: false,
            type: 'text/email',
            listDepth: 0,
            value: null,
          },
        },
      },
    },
  },
} satisfies TriggerDefinition;
