// Data wraps values passed through spell executions with a type and metadata.
// For example, passing an email for a new contact in HubSpot:
//
// {
//   key: 'email',
//   type: 'text/email',
//   listDepth: 0,
//   value: 'nicholas@respell.ai',
// }
export interface Data extends Keyable {
  // Type of data being passed.
  readonly type: DataType;

  // If zero, this data holds a single value.
  // 1 - Single dimension array of values.
  // n - N dimensional array of values.
  readonly listDepth: number;

  // Whether this data can be null.
  readonly isOptional: boolean;

  // The underlying data payload. This can be a String, Boolean, Number, JSON, Null, or Array of any the previous.
  value: Json | null;

  // Metadata for this option used to apply certain constraints on the value
  // and how it is displayed in the editor.
  //
  // Dynamic metadata:
  // For metadata like options for a select, there may be times when the options
  // are not static and dependent on a user's current integrations.
  // To access the dynamic state of a user's integration context, use strings
  // with $integration.
  // For example:
  // {
  //   options: '$integrations.bland.inbound_numbers',
  // }
  metadata?: {
    [key in MetadataKey]?: Metadata[key];
  };
}

export interface MetadataOptionEntry {
  reference?: TemplatedFunction;
  type?: string;
  key?: string;
  id?: string;
  name?: string;
  description?: string;
  icon?: string;
}

// Keys for metadata values that will be respected by the editor and
// modify the appearance or behavior of an option.
export type MetadataKey =
  // ConditionType[] - Conditions allowed to be applied to this field.
  | 'filters'

  // string - Placeholder text (hint) for text based options.
  | 'hint'

  // Icon to use to display for a variable pill.
  // This icon overrides the default value assigned to each data type.
  | 'icon'

  // <Json | string>[] - Options for a select.
  // Each entry has this schema.
  | 'options'

  // string[] - used for nango routing, can be credentials.X, params.X or link.X where X is a string like subdomain, apiKey, etc.
  | 'mapping'

  // Json - Schema for object data.
  // Each field is a key and the value is a Data interface with the typing.
  | 'schema'

  // string[] - Allowed languages for a code block.
  // Refer to https://github.com/wooorm/lowlight?tab=readme-ov-file#data
  | 'allowedLanguages'

  // boolean - Whether a textbox should be multiline.
  | 'isMultiline'

  // boolean - whether the options are searchable
  | 'isSearchable'

  // boolean - whether the options allow custom values
  | 'allowsCustom'

  // boolean - whether to prevent variable injection
  | 'preventInject'

  // boolean - whether to only permit variable injection
  | 'variableOnly'

  // boolean - whether this textbox should allow access to the prompt wizard
  | 'isPrompt'

  // boolean - whether this variable should be displayed in the review step
  | 'forReview'

  // string - The categorical group this option belongs to.
  | 'group'

  // number - Minimum value for a data option or minimum character length for a string.
  | 'min'

  // number - Maximum value for a numerical data option or maximum character length for a string.
  | 'max';

export type MetadataValue =
  | ConditionType[]
  | string
  | string[]
  | Json
  | boolean
  | number;

export type Metadata = {
  filters?: ConditionType[];
  hint?: string;
  icon?: string;
  options?: (Json | string)[] | Json;
  mapping?: string;
  schema?: Json;
  allowedLanguages?: string[];
  preventInject?: boolean;
  isMultiline?: boolean;
  isSearchable?: boolean;
  isPrompt?: boolean;
  allowsCustom?: boolean;
  forReview?: boolean;
  group?: string;
  min?: number;
  max?: number;
};

export type DataType =
  // Plain text with or without line breaks.
  | 'text/plain'

  // Email address.
  | 'text/email'

  // Phone number.
  | 'text/phone-number'

  // Website URL.
  | 'text/url'

  // Custom code block. Object with a language and content field.
  | 'code'

  // Valid JSON object,
  | 'object'

  // Whole number.
  | 'number/integer'

  // Real number with 0 or more decimal places.
  | 'number/decimal'

  // True or false.
  | 'boolean'
  | 'condition'
  | 'reference'

  // Date with time (year, month, date, hours, minutes, seconds).
  | 'datetime'

  // An array of two date objects signifying a start date and end date
  | 'daterange'

  // Date (year, month, date).
  | 'date'

  // Time of day (hours, minutes, seconds).
  | 'time'

  // Document file, stored as a URL:
  // 1. txt
  // 2. pdf
  // 3. docx
  // 2. ts, js, css, html, java, go, py
  | 'file/document'

  // Spreadsheet file, stored as a URL:
  // 1. csv
  // 2. xlsx
  | 'file/spreadsheet'

  // Audio file, stored as a URL:
  // 1. wav
  // 2. mp3
  // 3. mp4
  // 4. m4v
  // 5. ogg
  | 'file/audio'

  // Image file, stored as a URL:
  // 1. png
  // 2. jpg
  // 3. tiff
  // 4. webp
  // 5. bmp
  | 'file/image'

  // Video file, stored as a URL:
  // 1. mp4
  // 2. mov
  | 'file/video';

// Each data type may have macros defined to easily output in certain formats.
export type MacroType =
  //
  // --- text/phone-number ---
  // Output the international format with no plus or symbols: eg. 11234567890
  | 'plain'

  // --- text/url ---
  // Output the domain: e.g. google.com
  | 'domain'
  // Output the path: e.g. /register
  | 'path'

  // Email username
  | 'username'
  | 'date'
  | 'time'
  | 'utc'
  | 'year'
  | 'epoch'
  | 'national'
  | 'hour'
  | 'month'
  | 'day'
  | 'day_of_week'

  // File names
  | 'name'
  // --- object ---
  // Output an object as a JSON string: eg.
  // {
  //   a: b,
  //   c: {
  //     d: e,
  //     f: g
  //   }
  // }
  | 'json'

  // --- date ---
  // Output date as a ISO 8601 date: e.g. Mar 26, 2024, 4:16:26 PM -> 2024-03-26T23:16:26.821Z
  | 'iso'
  | EvaluableMacroType;

// Macro functions usually return strings.
// These macro types specifically are capable of the original data type
// with modifications.
// For example, the 'opposite' macro for a boolean is evaluable because it
// also returns a boolean, while the 'iso' macro for a date is not evaluable
// because it doesn't return a date; it returns a specific string format of the date.
export type EvaluableMacroType =
  // --- boolean ---
  // Output the opposite of the boolean: eg. true -> false, false -> true
  | 'opposite'

  // --- number ---
  // Output the absolute value of the number: eg. 12 -> 12, -12 -> 12
  | 'absolute_value'

  // Reverses the sign of the number: eg. 12 -> -12, -12 -> 12
  | 'reverse_sign'

  // First item in the list
  | 'first'

  // Converts the list to a string
  | 'toList'

  // Round the number to the nearest integer: eg. 12.345 -> 12, -12.345 -> -12, -12.678 -> -13
  | 'round'

  // Round up the number to the nearest integer (ceiling): eg. 12.345 -> 13, -12.345 -> -12, -12.678 -> -12
  | 'ceil'

  // Round down the number to the nearest integer (floor): eg. 12.345 -> 12, -12.345 -> -13, -12.678 -> -13
  | 'floor';

// An object displayed to the end user that has a name associated with it.
export interface Named {
  // Human-readable display name in title case (e.g. Send Email).
  name: string;

  // Brief description of what the behavior of this object is.
  description?: string;
}

// Gated represents an object that may be "gated" from users depending on their
// subscription tier.
export interface Gated {
  // The entitlement necessary to use this gated feature (e.g, dataSources).
  // If null, any logged-in user can use this feature.
  entitlement?: string;
}

// An element in a set that has the property of having an identifying key distinct
// from other elements in the set.
export interface Keyable {
  // The distinct identifier for this element. In a given set of Keyable objects
  // no two elements may have the same key.
  // This key must be alphanumeric and in snake case.
  readonly key: string;
}

// Conditional is an option or output that may or not be displayed
// depending on the value of configurations in other options.
export interface Conditional {
  // Conditions that must ALL evaluate to true for this object to appear.
  conditions?: ReferenceCondition[];
}

// Variable is a piece of data created by the execution of a step.
// e.g, when HubSpot Create Contact runs, it will output a variable
// called "email" to the next steps to use.
// Also, it can be a configurable input to a step shown on the sidebar in the editor.
export interface Variable extends Named, Gated, Conditional, Data, JsonObject {
  // For services where the key doesn't correlate with a valid key name
  id?: string;

  // Whether this variable should be included in the normal grouping display.
  // This is useful for cases like intermediary inputs, where the input
  // should not be automatically shown for graph inputs and can be manually
  // displayed through references by pages.
  isHidden?: boolean;

  // The index of the variable in an object of variables, such as in a graph's inputs.
  order?: number;
}

// Dictionary with key value pairs.
export interface Dictionary<T> {
  [key: string]: T;
}

// Contains file metadata (name and MIME type) as well as a stream of the file content.
export interface FileWithBuffer {
  // Name of the file e.g. profile_picture.png
  name: string;

  // MIME type of the file e.g. image/png
  mimeType: string;
  // Stream containing the file content.
  buffer: Buffer;
}

// Contains file metadata (name and MIME type) as well the identifier
// needed to access the file from the cloud.
export interface BucketFile {
  // Name of the file e.g. profile_picture.png
  name: string;

  // MIME type of the file e.g. image/png
  mimeType: string;

  // Source of the file, could be a provider like 'GOOGLE_DRIVE' | 'NOTION', or a file extension like 'PNG' | 'JPEG' for locally uploaded files
  // https://github.com/Carbon-for-Developers/carbon-sdks/blob/main/typescript/models/data-source-type.ts
  source: string;

  // Colon delimited identifier of the ownerType:ownerId:customerId:fileId
  id: BucketFileId;
}

// OwnerType represents the type of the object the asset _belongs_ to. Users can access the asset if they can access the object.
type OwnerType =
  | 'Workspace' // Accessible to all users in the Workspace. Uploaded via Data Sources page, appears in search results.
  | 'Spell' // Uploaded in Editor as part of creating a spell. Does not show up in search results.
  | 'GraphRun'; // Uploaded when running a spell or generated during the run of a spell. Does not show up in search results.

// Utility class to help with creating and parsing a bucketFileId.
// A bucketFileId is a colon delimited identifier of the ownerType:ownerId:customerId:fileId
export class BucketFileId {
  private _ownerType: OwnerType;
  private _ownerId: string;
  private _workspaceId: string;
  private _fileId: string;

  constructor(
    ownerType: string,
    ownerId: string,
    workspaceId: string,
    fileId: string,
  );
  constructor(bucketFileId: string);

  constructor(
    ownerTypeOrBucketFileId: string,
    ownerId?: string,
    workspaceId?: string,
    fileId?: string,
  ) {
    if (
      ownerId !== undefined &&
      workspaceId !== undefined &&
      fileId !== undefined
    ) {
      this._ownerType = ownerTypeOrBucketFileId as OwnerType;
      this._ownerId = ownerId;
      this._workspaceId = workspaceId;
      this._fileId = fileId;
    } else {
      const parts: string[] = ownerTypeOrBucketFileId.split(':');
      if (parts.length !== 4) {
        throw new Error(
          `Invalid format. Expected 'ownerType:ownerId:workspaceId:fileId', received ${parts}`,
        );
      }
      this._ownerType = parts[0] as OwnerType;
      this._ownerId = parts[1];
      this._workspaceId = parts[2];
      this._fileId = parts[3];
    }
  }

  get ownerType(): OwnerType {
    return this._ownerType;
  }

  get ownerId(): string {
    return this._ownerId;
  }

  get workspaceId(): string {
    return this._workspaceId;
  }

  get fileId(): string {
    return this._fileId;
  }

  toString(): string {
    return `${this._ownerType}:${this._ownerId}:${this._workspaceId}:${this._fileId}`;
  }

  toJSON(): string {
    return this.toString();
  }

  [Symbol.toPrimitive](hint: string): string | null {
    if (hint === 'string') {
      return this.toString();
    }
    return null;
  }
}

// A condition represents a single named "if" statement that leads to a certain path.
export interface Condition extends Combinator {
  path: string;
  elements: ConditionElement[];
}

// How to combine the if statement condition elements. Only "and" / "or" may be defined.
// If both are defined, only "and" will be evaluated.
export interface Combinator {
  and?: (number | Combinator)[];
  or?: (number | Combinator)[];
}

// An element in an "if" statement, with a value being tested, a test to compare it against, and a type
// of comparison test to use.
export interface ConditionElement {
  value: string;
  test: string;
  type: ConditionType;
}

export interface ConditionResult {
  path: string;
  value: boolean;
}

// Contains the metadata for the code (language) and the code content itself.
export interface Code {
  // Language the code is written in.
  language: string;

  // Code content in plain text.
  content: string;
}

// Condition is checked on a data value and evaluates to true or false.
export interface ReferenceCondition extends JsonObject {
  // Definition identifier for the option this condition is tested on.
  readonly reference: string;

  // What condition is being checked.
  readonly type: ConditionType;

  // Value to test against.
  readonly value: Json | null;

  // Since top level conditions are joined with an implicit AND,
  // this can be used to create a condition check where one of n
  // conditions is true.
  readonly or?: ReferenceCondition[];
}

// Types of conditions that can be checked on a data value.
export type ConditionType =
  | 'equals'
  | 'not'
  | 'in'
  | 'notIn'
  | 'lt'
  | 'lte'
  | 'gt'
  | 'gte'
  | 'contains'
  | 'doesNotContain'
  | 'ai'
  | 'startsWith'
  | 'endsWith';

export type EvaluatedDataType =
  | string
  | number
  | boolean
  | Date
  | BucketFile
  | BucketFileId
  | Code
  | ConditionResult
  | JsonObject;

// Typing for any valid Json serializable value.
export type Json =
  | EvaluatedDataType
  | Condition
  | JsonArray
  | Dictionary<Variable>
  | null;

// Typing for a Json serializable object.
export type JsonObject = {
  [Key in string]?: Json;
};

// Typing for a Json serializable array.
export interface JsonArray extends Array<Json> {}

// Represents a result from an operation with a potential cost that can be billed for.
export interface BillableResult<T> {
  // Value which is the result of the operation.
  readonly value: T;

  // Cost of the operation (is a non-negative decimal with 10 places of precision).
  readonly cost: number;

  readonly metadata?: JsonObject;
}

export interface TemplatedArgument {
  namespace: string;
  member: string;
  parameter: string;
}

export interface TemplatedFunction {
  namespace: string;
  member: string;
  arguments?: TemplatedArgument[];
}

// Runtime error that matches the schema definition RunError.
export abstract class BaseError extends Error {
  // Internal, distinct identifier for a type of error (e.g., invalid_api_url).
  readonly key: ErrorKey;

  // Whether this error is an internal error or an end-user validation error.
  // External (validation/user) errors - These errors can be intended behavior
  // and are not reported to Sentry.
  // Internal - Unexpected error caused by an internal service or integration. These errors are reported to Sentry and must be addressed.
  readonly isInternal: boolean;

  // Whether this error will occur if the spell is retried. If this is false, no matter how many times
  // the spell is retried, this error will occur again. This can be useful for retry and debugging logic.
  // An example of an non-retryable error is an malformed URL being passed. No matter how many times
  // you retry the run, it will always be malformed and cause an error.
  // On the flip side, a 503 error on an API call is potentially transient because the server might have been
  // temporarily unavailable for a set amount of time, so if you retry the spell later this error
  // might not occur and the spell may complete successfully.
  //
  // false = This error is guaranteed to occur if the spell run is retried.
  // true = This error may or may not occur if the spell run is retried.
  readonly isRetryable: boolean;

  // Optional code if the error originated from an HTTP or gRPC request (e.g, 503, 429).
  readonly code?: number;

  // Optional dictionary of useful context surrounding the error.
  // e.g., for an invalid url:
  //
  // {
  //   url: 'hts://google.com'
  // }
  readonly context: Dictionary<unknown>;

  protected constructor(options: {
    key: ErrorKey;
    message: string;
    isInternal: boolean;
    isRetryable?: boolean;
    source?: unknown;
    context?: Dictionary<unknown>;
  }) {
    super(options.message);

    this.key = options.key;
    this.isInternal = options.isInternal;
    this.isRetryable = options.isRetryable || false;
    this.context = JSON.parse(JSON.stringify(options.context ?? {}));

    if (options.source instanceof Error) {
      this.stack = options.source.stack;
      // TODO read the source option and fill out code.
      // if source instanceof X_ERROR
    }
  }

  // If an error is of an unknown type or is marked as internal, it is considered an internal error
  // which should be reported to Sentry.
  static isInternal(error: unknown): boolean {
    return !(error instanceof BaseError) || error.isInternal;
  }

  // If an error is of an unknown type or is marked as retryable, it is considered a retryable error
  // which should be retried if possible.
  static isRetryable(error: any): boolean {
    return error.isRetryable === undefined || error.isRetryable;
  }
}

type ClientErrorType = {
  key: ErrorKey;
  message: string;
  source?: Error;
  context?: JsonObject;
};

export class ClientError extends BaseError {
  constructor(options: ClientErrorType) {
    super({
      ...options,
      isInternal: false,
    });
  }
}

type InternalErrorType = {
  key: ErrorKey;
  message: string;
  isRetryable?: boolean;
  source?: unknown;
  context?: Dictionary<unknown>;
};

// Parent error type for all errors that occur during spell execution that
// are caused by internal services (unexpected).
export class InternalError extends BaseError {
  constructor(options: InternalErrorType) {
    super({
      ...options,
      isInternal: true,
    });
  }
}

type ValidationErrorType = {
  key: ErrorKey;
  message: string;
  source?: unknown;
  context?: Dictionary<unknown>;
};

// Parent error for all error types that occur during spell execution that
// are caused by validation errors from the end-user (expected and never retryable).
export class ValidationError extends BaseError {
  constructor(options: ValidationErrorType) {
    super({
      ...options,
      isInternal: false,
      isRetryable: false,
    });
  }
}

// Keys for all errors thrown.
export type ErrorKey =
  // All common error keys should be defined here.
  // Error types that are unique to certain integrations, etc.,
  // should not be defined here because the list would get extremely
  // long.
  | 'invalid_url'
  | 'invalid_email'
  | 'invalid_phone_number'
  | 'graph_not_found'
  | 'workspace_not_found'

  // This is thrown when a variable reference in an option tries to ref
  | 'invalid_data_path'

  // Catch-all for error keys specific to certain domains like integrations.
  | string;

export const typeMap: Record<
  string,
  { key: DataType; name: string; icon: string; accepts: DataType[] }
> = {
  'text/plain': {
    key: 'text/plain',
    name: 'Plain text',
    icon: 'i-ph-text-align-left',
    accepts: [
      'text/plain',
      'text/url',
      'text/email',
      'text/phone-number',
      'code',
      'object',
      'number/integer',
      'number/decimal',
      'boolean',
      'datetime',
      'date',
      'time',
      'file/document',
      'file/spreadsheet',
      'file/audio',
      'file/image',
      'file/video',
    ],
  },
  'text/url': {
    key: 'text/url',
    name: 'URL',
    icon: 'i-ph-link',
    accepts: ['text/plain', 'text/url', 'text/email'],
  },
  'text/email': {
    key: 'text/email',
    name: 'Email address',
    icon: 'i-ph-envelope',
    accepts: ['text/plain', 'text/email', 'text/url'],
  },
  'text/phone-number': {
    key: 'text/phone-number',
    name: 'Phone number',
    icon: 'i-ph-phone',
    accepts: ['text/plain', 'text/phone-number'],
  },
  code: {
    key: 'code',
    name: 'Code',
    icon: 'i-ph-code',
    accepts: [
      'text/plain',
      'text/url',
      'text/email',
      'text/phone-number',
      'code',
      'object',
      'number/integer',
      'number/decimal',
      'boolean',
      'datetime',
      'date',
      'time',
      'file/document',
      'file/spreadsheet',
      'file/audio',
      'file/image',
      'file/video',
    ],
  },
  object: {
    key: 'object',
    name: 'Object',
    icon: 'i-ph-brackets-curly',
    accepts: ['object'],
  },
  'number/integer': {
    key: 'number/integer',
    name: 'Integer',
    icon: 'i-ph-list-numbers',
    accepts: ['number/integer', 'number/decimal'],
  },
  'number/decimal': {
    key: 'number/decimal',
    name: 'Decimal',
    icon: 'i-ph-percent',
    accepts: ['number/integer', 'number/decimal'],
  },
  boolean: {
    key: 'boolean',
    name: 'Boolean',
    icon: 'i-ph-toggle-left',
    accepts: ['boolean', 'text/plain'],
  },
  datetime: {
    key: 'datetime',
    name: 'Datetime',
    icon: 'i-mdi-calendar-clock-outline',
    accepts: ['datetime', 'text/plain'],
  },
  daterange: {
    key: 'daterange',
    name: 'Date range',
    icon: 'i-ph-calendar-dots',
    accepts: ['daterange', 'text/plain'],
  },
  date: {
    key: 'date',
    name: 'Date',
    icon: 'i-ph-calendar',
    accepts: ['date', 'text/plain'],
  },
  time: {
    key: 'time',
    name: 'Time',
    icon: 'i-ph-clock',
    accepts: ['time', 'text/plain'],
  },
  'file/document': {
    key: 'file/document',
    name: 'Document file',
    icon: 'i-ph-file',
    accepts: [
      'file/document',
      'file/spreadsheet',
      'file/audio',
      'file/image',
      'file/video',
    ],
  },
  'file/spreadsheet': {
    key: 'file/spreadsheet',
    name: 'Spreadsheet file',
    icon: 'i-ph-table',
    accepts: ['file/document', 'file/spreadsheet'],
  },
  'file/audio': {
    key: 'file/audio',
    name: 'Audio file',
    icon: 'i-ph-music-note',
    accepts: ['file/audio', 'file/video'],
  },
  'file/image': {
    key: 'file/image',
    name: 'Image file',
    icon: 'i-ph-image',
    accepts: ['file/image'],
  },
  'file/video': {
    key: 'file/video',
    name: 'Video file',
    icon: 'i-ph-video',
    accepts: ['file/video'],
  },
};

export const macroOptions: Record<MacroType, { key: string; name: string }> = {
  plain: { key: 'plain', name: 'Plain Text' },
  domain: { key: 'domain', name: 'Domain' },
  username: { key: 'username', name: 'Username' },
  path: { key: 'path', name: 'Path' },
  json: { key: 'json', name: 'JSON String' },
  iso: { key: 'iso', name: 'ISO' },
  name: { key: 'name', name: 'File Name' },
  absolute_value: { key: 'absolute_value', name: 'Absolute' },
  reverse_sign: { key: 'reverse_sign', name: 'Reverse' },
  opposite: { key: 'opposite', name: 'Opposite' },
  round: { key: 'round', name: 'Round' },
  ceil: { key: 'ceil', name: 'Ceiling' },
  floor: { key: 'floor', name: 'Floor' },
  first: { key: 'first', name: 'First' },
  toList: { key: 'toList', name: 'To List' },
  date: { key: 'date', name: 'Date' },
  time: { key: 'time', name: 'Time' },
  utc: { key: 'utc', name: 'UTC' },
  year: { key: 'year', name: 'Year' },
  epoch: { key: 'epoch', name: 'Epoch' },
  hour: { key: 'hour', name: 'Hour' },
  national: { key: 'national', name: 'National Format' },
  month: { key: 'month', name: 'Month' },
  day: { key: 'day', name: 'Day' },
  day_of_week: { key: 'day_of_week', name: 'Day of Week' },
};

export const listDepthMacroMap: Record<
  number,
  { key: string; name: string }[]
> = {
  0: [],
  1: [macroOptions.first, macroOptions.toList],
  2: [],
};

export const typeMacroMap: Record<DataType, { key: string; name: string }[]> = {
  'text/plain': [],
  'text/url': [macroOptions.domain, macroOptions.path],
  'text/email': [macroOptions.username, macroOptions.domain],
  'text/phone-number': [macroOptions.plain, macroOptions.national],
  code: [],
  object: [macroOptions.json],
  'number/integer': [
    macroOptions.absolute_value,
    macroOptions.reverse_sign,
    macroOptions.round,
    macroOptions.ceil,
    macroOptions.floor,
  ],
  'number/decimal': [
    macroOptions.absolute_value,
    macroOptions.reverse_sign,
    macroOptions.round,
    macroOptions.ceil,
    macroOptions.floor,
  ],
  boolean: [macroOptions.opposite],
  datetime: [
    macroOptions.iso,
    macroOptions.date,
    macroOptions.time,
    macroOptions.utc,
    macroOptions.year,
    macroOptions.epoch,
    macroOptions.time,
    macroOptions.hour,
    macroOptions.day,
    macroOptions.month,
    macroOptions.day_of_week,
  ],
  condition: [],
  reference: [],
  daterange: [],
  date: [
    macroOptions.iso,
    macroOptions.utc,
    macroOptions.year,
    macroOptions.epoch,
    macroOptions.day,
    macroOptions.month,
    macroOptions.day_of_week,
  ],
  time: [],
  'file/document': [],
  'file/spreadsheet': [],
  'file/audio': [],
  'file/image': [],
  'file/video': [],
};

export const fileMap: Record<string, string[]> = {
  'file/document': [
    'text/*',
    'application/pdf',
    'application/json',
    'message/rfc822',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  ],
  'file/spreadsheet': [
    'text/csv',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-excel',
  ],
  'file/audio': ['audio/*'],
  'file/image': ['image/*'],
  'file/video': ['video/*'],
};

export const conditionTypeOptions: Record<
  ConditionType,
  { key: string; name: string }
> = {
  equals: { key: 'equals', name: 'equals' },
  not: { key: 'not', name: 'is not' },
  in: { key: 'in', name: 'is in' },
  notIn: { key: 'notIn', name: 'is not in' },
  lt: { key: 'lt', name: 'is less than' },
  ai: { key: 'ai', name: 'is true' },
  lte: { key: 'lte', name: 'is less than or equal to' },
  gt: { key: 'gt', name: 'is greater than' },
  gte: { key: 'gte', name: 'is greater than or equal to' },
  contains: { key: 'contains', name: 'contains' },
  doesNotContain: { key: 'doesNotContain', name: 'does not contain' },
  startsWith: { key: 'startsWith', name: 'starts with' },
  endsWith: { key: 'endsWith', name: 'ends with' },
};
