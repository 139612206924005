import { IssueModel } from '@respell/integrations/linear/models';
import { StepDefinition } from '../../types';

export default {
  key: 'search_issues',
  name: 'Search Issues',
  description: 'Searches Linear for issues matching your query.',
  icon: 'i-respell-linear',
  //documentationLink: 'https://docs.respell.ai/linear',
  category: 'integrations',
  services: ['linear'],
  options: {
    query: {
      key: 'query',
      name: 'Search Query',
      description:
        'The query to use for finding tickets. Searches across issue names, details, and more. Functions the same as the search bar in the Linear dashboard.',
      isOptional: false,
      type: 'text/plain',
      listDepth: 0,
      value: null,
    },
  },
  outputs: {
    issue: {
      key: 'issue',
      name: 'Issue',
      type: 'object',
      isOptional: false,
      listDepth: 0,
      metadata: {
        icon: 'i-respell-t-box',
        schema: IssueModel.schema,
      },
      value: null,
    },
  },
} satisfies StepDefinition;
