import { Integration } from '../definition';

export default {
  key: 'slack',
  name: 'Slack',
  icon: 'i-respell-slack',
  options: [],
  postAuthOptions: [],
  metadataOverride: { user_scope: ['search:read'] },
} satisfies Integration;
