import { StepDefinition } from '../../types';

export default {
  key: 'create_account',
  name: 'Create Account',
  description: 'Creates a Salesforce Account',
  icon: 'i-respell-salesforce',
  category: 'integrations',
  services: ['salesforce'],
  options: {
    account: {
      name: 'Account',
      type: 'object',
      listDepth: 0,
      isOptional: false,
      value: null,
      key: 'account',
      metadata: {
        schema: {
          reference: {
            namespace: 'salesforce',
            member: 'describe_account',
          },
        },
      },
    },
  },
  outputs: {},
} satisfies StepDefinition;
