import { StepDefinition } from '../../types';

export default {
  key: 'search_people_pdl',
  name: 'Search People',
  description: 'Searches people on PeopleDataLabs.',
  icon: 'i-respell-peopledatalabs',
  //documentationLink: 'https://docs.respell.ai/peopledatalabs',
  category: 'integrations',
  services: ['peopledatalabs'],
  options: {
    query: {
      key: 'query',
      name: 'Query',
      description: 'The search query.',
      type: 'text/plain',
      isOptional: false,
      metadata: {
        icon: 'i-ph-subtitles-bold',
      },
      listDepth: 0,
      value: null,
    },
  },
  outputs: {
    name: {
      key: 'name',
      name: 'Name',
      description: "The person's name.",
      type: 'text/plain',
      isOptional: false,
      metadata: {
        icon: 'i-ph-subtitles-bold',
      },
      listDepth: 0,
      value: null,
    },
    phone: {
      key: 'phone',
      name: 'Phone',
      description: "The person's phone number.",
      type: 'text/phone-number',
      isOptional: true,
      listDepth: 0,
      metadata: {
        icon: 'i-ph-phone',
      },
      value: null,
    },
    linkedin_url: {
      key: 'linkedin_url',
      name: 'LinkedIn URL',
      description: "The person's LinkedIn URL.",
      type: 'text/url',
      isOptional: true,
      listDepth: 0,
      metadata: {
        icon: 'i-ph-linkedin',
      },
      value: null,
    },
    title: {
      key: 'title',
      name: 'Title',
      description: "The person's title.",
      type: 'text/plain',
      isOptional: true,
      listDepth: 0,
      metadata: {
        icon: 'i-ph-badge',
      },
      value: null,
    },
    address: {
      key: 'address',
      name: 'Address',
      description: "The person's address.",
      type: 'text/plain',
      isOptional: true,
      listDepth: 0,
      metadata: {
        icon: 'i-ph-location',
      },
      value: null,
    },
    current_company: {
      key: 'current_company',
      name: 'Current Company',
      description: "The person's current company.",
      type: 'text/plain',
      isOptional: true,
      listDepth: 0,
      metadata: {
        icon: 'i-ph-building',
      },
      value: null,
    },
  },
} satisfies StepDefinition;
