import { StepDefinition } from '../../types';

export default {
  key: 'start',
  name: 'Start',
  description: 'This is always the first step to run in your spell.',
  icon: 'i-respell-start',
  //documentationLink: 'https://docs.respell.ai/start',
  category: 'flow',
  options: {},
  outputs: {},
} satisfies StepDefinition;
