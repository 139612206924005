import { StepDefinition } from '../../types';

export default {
  key: 'add_canididate',
  name: 'Add Candidate',
  description: 'Add a new candidate in Ashby.',
  icon: 'i-respell-ashby',
  category: 'integrations',
  services: ['ashby'],
  options: {
    name: {
      key: 'name',
      name: 'Name',
      description: 'The first and last name of the candidate to be created.',
      isOptional: false,
      type: 'text/plain',
      listDepth: 0,
      value: null,
    },
    email: {
      key: 'email',
      name: 'Email',
      description: 'Primary, personal email of the candidate to be created.',
      isOptional: true,
      type: 'text/email',
      listDepth: 0,
      value: null,
    },
    phoneNumber: {
      key: 'phoneNumber',
      name: 'Phone Number',
      description:
        'Primary, personal phone number of the candidate to be created.',
      isOptional: true,
      type: 'text/phone-number',
      listDepth: 0,
      value: null,
    },
    linkedInUrl: {
      key: 'linkedInUrl',
      name: 'LinkedIn URL',
      description:
        "Url to the candidate's LinkedIn profile. Must be a valid Url.",
      isOptional: true,
      type: 'text/url',
      listDepth: 0,
      value: null,
    },
    githubUrl: {
      key: 'githubUrl',
      name: 'Github URL',
      description:
        "Url to the candidate's Github profile. Must be a valid Url.",
      isOptional: true,
      type: 'text/url',
      listDepth: 0,
      value: null,
    },
    website: {
      key: 'website',
      name: 'Website',
      description: "Url of the candidate's website. Must be a valid Url.",
      isOptional: true,
      type: 'text/url',
      listDepth: 0,
      value: null,
    },
    resume: {
      key: 'resume',
      name: 'Resume',
      description: 'An optional resume to upload to the client',
      isOptional: true,
      type: 'file/document',
      listDepth: 0,
      value: null,
    },
  },
  outputs: {},
} satisfies StepDefinition;
