import { IssueModel } from '@respell/integrations/linear/models';
import { StepDefinition } from '../../types';

export default {
  key: 'update_issue',
  name: 'Update Linear Issue',
  description: 'Gets an issue on linear based on ID.',
  icon: 'i-respell-linear',
  //documentationLink: 'https://docs.respell.ai/linear',
  category: 'integrations',
  services: ['linear'],
  options: {
    issue_id: {
      key: 'issue_id',
      name: 'Issue ID',
      description: 'The id of the issue.',
      isOptional: false,
      type: 'text/plain',
      listDepth: 0,
      metadata: {
        options: {
          reference: {
            namespace: 'linear',
            member: 'get_issues',
          },
        },
      },
      value: null,
    },
    name: {
      key: 'name',
      name: 'Name',
      description: 'The new name of the issue.',
      isOptional: true,
      type: 'text/plain',
      listDepth: 0,
      value: null,
    },
    description: {
      key: 'description',
      name: 'Description',
      description: 'The new description of the issue.',
      isOptional: true,
      type: 'text/plain',
      listDepth: 0,
      value: null,
    },
  },
  outputs: {
    issue: {
      key: 'issue',
      name: 'Issue',
      type: 'object',
      isOptional: false,
      listDepth: 0,
      metadata: {
        icon: 'i-respell-t-box',
        schema: IssueModel.schema,
      },
      value: null,
    },
  },
} satisfies StepDefinition;
