import { TriggerDefinition } from '../../../definition';

export default {
  key: 'new-follower',
  name: 'New Follower',
  description: 'A new follower is added to your account.',
  icon: 'i-logos-instagram-icon',
  options: {},
  outputs: {
    follower: {
      name: 'Follower Username',
      type: 'text/plain',
      listDepth: 0,
      isOptional: false,
      value: null,
      key: 'follower',
    },
  },
} satisfies TriggerDefinition;
