import { StepDefinition } from '../../types';

export default {
  key: 'review',
  name: 'Human Review',
  description:
    'Pauses the spell run and gets confirmation or additional input from the user.',
  icon: 'i-respell-review',
  documentationLink:
    'https://docs.respell.ai/steps-reference/flow-tools/human-review',
  category: 'flow',
  options: {
    instruction: {
      key: 'instruction',
      name: 'Reviewer Instructions',
      description: "Instructions to guide the user's review.",
      listDepth: 0,
      type: 'text/plain',
      isOptional: true,
      metadata: {
        hint: 'Review the following content and confirm it is correct.',
        isMultiline: true,
      },
      value: null,
    },
    inputs: {
      key: 'inputs',
      name: 'Review inputs',
      description:
        'Create inputs for the user to submit during the review. These can use existing variables if edits are requested.',
      listDepth: 1,
      type: 'text/plain',
      isOptional: true,
      value: [],
    },
    outputs: {
      key: 'outputs',
      name: 'Approval outputs',
      description: 'Create display outputs to be approved during the review.',
      listDepth: 1,
      type: 'text/plain',
      isOptional: true,
      value: [],
    },
  },
  outputs: {},
} satisfies StepDefinition;
