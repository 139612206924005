import { StepDefinition } from '../../types';

export default {
  key: 'text_to_eml',
  name: 'Text to EML',
  description: 'Creates an EML file containing the specified data.',
  icon: 'file',
  category: 'no_category',
  options: {
    bodyHTML: {
      key: 'bodyHTML',
      name: 'Body HTML',
      description: 'The html body (message) of the email.',
      type: 'text/plain',
      isOptional: true,
      listDepth: 0,
      metadata: {
        icon: 'i-ph-text-align-left',
      },
      value: null,
    },
    body: {
      key: 'body',
      name: 'Body Text',
      description: 'The plaintext body (message) of the email.',
      type: 'text/plain',
      isOptional: true,
      listDepth: 0,
      metadata: {
        icon: 'i-ph-text-align-left',
      },
      value: null,
    },
    draft: {
      key: 'draft',
      name: 'Draft',
      description: 'Whether the email should be a draft rather then sent.',
      type: 'boolean',
      isOptional: false,
      listDepth: 0,
      metadata: {
        icon: 'i-ph-user',
      },
      value: true,
    },
    subject: {
      key: 'subject',
      name: 'Subject',
      description: 'The subject of the email.',
      type: 'text/plain',
      isOptional: false,
      listDepth: 0,
      metadata: {
        icon: 'i-ph-text-align-left',
      },
      value: null,
    },
    to: {
      key: 'to',
      name: 'Recipient Email',
      description: 'The email of the recipient you want to send the email to.',
      type: 'text/email',
      isOptional: false,
      listDepth: 0,
      metadata: {
        icon: 'i-ph-user',
      },
      value: null,
    },
    toName: {
      key: 'toName',
      name: 'Recipient Name',
      description: 'The name of the recipient you want to send the email to.',
      type: 'text/plain',
      isOptional: false,
      listDepth: 0,
      metadata: {
        icon: 'i-ph-user',
      },
      value: null,
    },
    from: {
      key: 'from',
      name: 'From',
      description: 'The email of the sender you want to send the email from.',
      type: 'text/email',
      isOptional: false,
      listDepth: 0,
      metadata: {
        icon: 'i-ph-user',
      },
      value: null,
    },
    filename: {
      key: 'filename',
      name: 'Filename',
      description:
        'The filename of the output eml file, .eml is appended automatically.',
      type: 'text/plain',
      isOptional: false,
      listDepth: 0,
      metadata: {
        icon: 'i-ph-user',
      },
      value: null,
    },
  },
  outputs: {
    file: {
      key: 'file',
      name: 'File',
      description: 'The newly created file.',
      type: 'file/document',
      listDepth: 0,
      isOptional: false,
      value: null,
    },
  },
} satisfies StepDefinition;
