import { StepDefinition } from '../../types';

export default {
  key: 'get_airtable_table',
  name: 'Fetch Table',
  description: 'Retrieves the full contents of a table in an Airtable base.',
  icon: 'i-respell-airtable',
  category: 'integrations',
  services: ['airtable'],
  options: {
    base_id: {
      key: 'base_id',
      name: 'Base',
      description: 'The base the table is contained in.',
      isOptional: false,
      type: 'text/plain',
      listDepth: 0,
      metadata: {
        options: {
          reference: {
            namespace: 'airtable',
            member: 'get_bases',
          },
        },
      },
      value: null,
    },
    table_id: {
      key: 'table_id',
      name: 'Table',
      description: 'The table you want to retrieve contents from.',
      isOptional: false,
      type: 'text/plain',
      conditions: [
        {
          reference: 'base_id',
          type: 'not',
          value: null,
        },
      ],
      metadata: {
        options: {
          reference: {
            namespace: 'airtable',
            member: 'get_tables',
            arguments: [
              {
                namespace: 'options',
                member: 'base_id',
                parameter: 'baseId',
              },
            ],
          },
        },
      },
      listDepth: 0,
      value: null,
    },
  },
  outputs: {
    output: {
      key: 'output',
      name: 'Output',
      isOptional: false,
      value: null,
      description: 'The table contents.',
      type: 'object',
      listDepth: 0,
      metadata: {
        schema: {
          id: {
            key: 'id',
            name: 'ID',
            description: 'The record ID.',
            type: 'text/plain',
            isOptional: false,
            metadata: {
              icon: 'i-ph-subtitles-bold',
            },
            listDepth: 0,
            value: null,
          },
          fields: {
            key: 'fields',
            name: 'Fields',
            description: 'The record fields.',
            type: 'object',
            isOptional: false,
            metadata: {
              schema: {
                reference: {
                  namespace: 'airtable',
                  member: 'get_headers',
                  arguments: [
                    {
                      namespace: 'options',
                      member: 'table_id',
                      parameter: 'tableId',
                    },
                    {
                      namespace: 'options',
                      member: 'base_id',
                      parameter: 'baseId',
                    },
                  ],
                },
              },
            },
            listDepth: 0,
            value: null,
          },
        },
      },
    },
  },
} satisfies StepDefinition;
