import { StepDefinition } from '../../types';

export default {
  key: 'create_contact_intercom',
  name: 'Create Contact',
  description: 'Creates a contact.',
  icon: 'i-logos-intercom-icon',
  category: 'integrations',
  services: ['intercom'],
  options: {
    name: {
      key: 'name',
      name: 'Name',
      description: "The contact's name.",
      type: 'text/plain',
      isOptional: true,
      metadata: {
        icon: 'i-ph-subtitles-bold',
      },
      listDepth: 0,
      value: null,
    },
    email: {
      key: 'email',
      name: 'Email',
      description: "The contact's email.",
      type: 'text/plain',
      isOptional: false,
      listDepth: 0,
      metadata: {
        icon: 'i-ph-text-align-left',
      },
      value: null,
    },
    phone: {
      key: 'phone',
      name: 'Phone',
      description: "The contact's phone number.",
      type: 'text/plain',
      isOptional: true,
      listDepth: 0,
      metadata: {
        icon: 'i-ph-text-align-left',
      },
      value: null,
    },
  },
  outputs: {
    contactId: {
      key: 'id',
      listDepth: 0,
      isOptional: false,
      value: null,
      name: 'ID',
      description: 'The ID of the created contact.',
      type: 'text/plain',
      metadata: {
        icon: 'i-ph-subtitles-bold',
      },
    },
  },
} satisfies StepDefinition;
