import { StepDefinition } from '../../types';

export default {
  key: 'create_upload_link',
  name: 'Create File Request',
  description: 'Will create a file request.',
  icon: 'i-respell-dropbox',
  category: 'integrations',
  services: ['dropbox'],
  options: {
    title: {
      type: 'text/plain',
      isOptional: false,
      description: 'The title of the file request.',
      name: 'Request Title',
      listDepth: 0,
      value: null,
      key: 'title',
    },
  },
  outputs: {
    link: {
      type: 'text/plain',
      isOptional: false,
      description: 'A link to the file request. ',
      name: 'Request Link',
      listDepth: 0,
      value: null,
      key: 'link',
    },
  },
} satisfies StepDefinition;
