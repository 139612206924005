import { StepDefinition } from '../../types';
import { OutlookTimeZones } from '../common';

export default {
  key: 'outlook_create_event',
  name: 'Create Event',
  description: 'Creates an event on your Outlook account.',
  icon: 'i-respell-outlook',
  category: 'integrations',
  services: ['outlook'],
  options: {
    body: {
      key: 'body',
      name: 'Body',
      description: 'A Description of the Event',
      type: 'text/plain',
      isOptional: true,
      listDepth: 0,
      metadata: {
        icon: 'i-ph-text-align-left',
      },
      value: null,
    },
    subject: {
      key: 'subject',
      name: 'Subject',
      description: 'The subject of the event.',
      type: 'text/plain',
      isOptional: false,
      listDepth: 0,
      metadata: {
        icon: 'i-ph-text-align-left',
      },
      value: null,
    },
    start: {
      key: 'start',
      name: 'Start Time',
      description: 'The start time of the meeting',
      type: 'datetime',
      isOptional: false,
      listDepth: 0,
      metadata: {
        icon: 'i-ph-user',
      },
      value: null,
    },
    end: {
      key: 'end',
      name: 'End Time',
      description: 'The end time of the meeting',
      type: 'datetime',
      isOptional: false,
      listDepth: 0,
      metadata: {
        icon: 'i-ph-user',
      },
      value: null,
    },
    attendees: {
      key: 'attendees',
      name: 'Attendees',
      description: 'People who are going to the event.',
      type: 'text/email',
      isOptional: true,
      listDepth: 1,
      metadata: {
        icon: 'i-ph-user',
      },
      value: [],
    },
    timezone: {
      key: 'timezone',
      name: 'Time Zone',
      description: 'The time zone for the events.',
      type: 'text/plain',
      isOptional: false,
      listDepth: 0,
      metadata: {
        preventInject: true,
        options: OutlookTimeZones.map((tz) => ({
          key: tz,
          name: tz,
        })),
      },
      value: [],
    },
  },
  outputs: {},
} satisfies StepDefinition;
