import { TriggerDefinition } from '../../../definition';
import { IssueModel, ProjectModel, UserModel } from '../../models';

export default {
  key: 'linear-issues',
  name: 'New Issue',
  description: 'An issue is created in Linear.',
  icon: 'i-respell-linear',
  options: {
    project: {
      key: 'project',
      name: 'Project',
      type: 'text/plain',
      isOptional: true,
      listDepth: 0,
      metadata: {
        options: {
          reference: {
            namespace: 'linear',
            member: 'get_projects',
          },
        },
        filters: ['equals'],
        hint: 'Any project...',
        icon: 'fingerprint',
      },
      value: null,
    },
  },
  outputs: {
    project: {
      key: 'project',
      name: 'Project',
      type: 'object',
      isOptional: false,
      listDepth: 0,
      metadata: {
        icon: 'fingerprint',
        schema: ProjectModel.schema, //JSON.stringify({} satisfies Issue) or something
      },
      value: null,
    },
    assignee: {
      key: 'assignee',
      name: 'Assignee',
      type: 'object',
      isOptional: false,
      listDepth: 0,
      metadata: {
        icon: 'fingerprint',
        schema: UserModel.schema, //JSON.stringify({} satisfies Issue) or something
      },
      value: null,
    },
    issue: {
      key: 'issue',
      name: 'Issue',
      type: 'object',
      isOptional: false,
      listDepth: 0,
      metadata: {
        icon: 't-box',
        schema: IssueModel.schema, //JSON.stringify({} satisfies Issue) or something
      },
      value: null,
    },
  },
  example: {
    project: {
      content:
        'This project includes all bugs identified in Acme, Inc. primary web platform. All issues should include a priority and estimate size.',
      description: 'All bugs on primary Acme, Inc. platform.',
      id: 'v2-backend-abc123456789',
      name: 'Acme Bugs',
      url: 'https://linear.app/respell/project/v2-backend-abc123456789',
    },
    assignee: {
      displayName: 'John Doe',
      email: 'john@acme.com',
      id: 'john',
    },
    issue: {
      branchName: 'john/eng-123-bugfix-email-special-characters',
      completedAt: null,
      createdAt: '2024-05-09',
      description:
        'Users are not able to login properly due to incorrect handling of special characters in emails.',
      dueDate: '2024-05-24',
      id: 'email-special-characters-improperly-parsed',
      identifier: 'ENG-123',
      priority: 'Urgent',
      startedAt: '2024-05-10',
      name: 'Email special characters are improperly parsed',
      url: 'https://linear.app/acme/issue/ENG-123/email-special-characters-improperly-parsed',
      team: 'Engineering',
    },
  },
} satisfies TriggerDefinition;
