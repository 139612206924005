import { StepDefinition } from '../../types';

export default {
  key: 'search_leads',
  name: 'Search Leads',
  description: 'Searches Salesforce Leads',
  icon: 'i-respell-salesforce',
  category: 'integrations',
  services: ['salesforce'],
  options: {
    query: {
      name: 'Query',
      type: 'text/plain',
      listDepth: 0,
      isOptional: false,
      value: null,
      key: 'query',
    },
  },
  outputs: {
    leads: {
      name: 'Leads',
      type: 'object',
      metadata: {
        schema: {
          reference: {
            namespace: 'salesforce',
            member: 'describe_lead_update',
          },
        },
      },
      listDepth: 1,
      isOptional: false,
      value: null,
      key: 'leads',
    },
  },
} satisfies StepDefinition;
