import { TriggerDefinition } from '../../../definition';

export default {
  key: 'emails',
  name: 'New Email',
  description: 'An email is received in Gmail.',
  icon: 'i-respell-google',
  options: {
    labelFilters: {
      key: 'labelFilters',
      name: 'Label Filters',
      isOptional: true,
      type: 'text/plain',
      listDepth: 1,
      metadata: {
        options: {
          reference: {
            namespace: 'gmail',
            member: 'list_labels',
          },
        },
      },
      value: null,
    },
  },
  outputs: {
    email: {
      key: 'email',
      name: 'Email',
      isOptional: false,
      type: 'object',
      listDepth: 0,
      value: {},
      metadata: {
        schema: {
          id: {
            key: 'id',
            name: 'Email ID',
            isOptional: false,
            type: 'text/plain',
            listDepth: 0,
            value: '',
          },
          sender: {
            key: 'sender',
            name: 'Sender',
            isOptional: false,
            type: 'text/plain',
            listDepth: 0,
            value: '',
          },
          labelIds: {
            key: 'labelIds',
            name: 'Label IDs',
            isOptional: false,
            type: 'text/plain',
            listDepth: 1,
            value: [],
          },
          recipients: {
            key: 'recipients',
            name: 'Recipients',
            isOptional: false,
            type: 'text/plain',
            listDepth: 0,
            value: '',
          },
          date: {
            key: 'date',
            name: 'Date',
            isOptional: false,
            type: 'date',
            listDepth: 0,
            value: null,
          },
          subject: {
            key: 'subject',
            name: 'Subject',
            isOptional: false,
            type: 'text/plain',
            listDepth: 0,
            value: '',
          },
          body: {
            key: 'body',
            name: 'Body',
            isOptional: false,
            type: 'text/plain',
            listDepth: 0,
            value: '',
          },
          threadId: {
            key: 'threadId',
            name: 'Thread ID',
            isOptional: false,
            type: 'text/plain',
            listDepth: 0,
            value: '',
          },
        },
      },
    },
  },
  example: {
    id: '1234567890',
    sender: 'jake@respell.ai',
    labelIds: ['INBOX'],
    recipients: 'matt@respell.ai',
    date: new Date(),
    subject: 'Hello, Matt!',
    body: 'Hello, Matt! I hope you are doing well.',
    threadId: '9876543210',
  },
} satisfies TriggerDefinition;
