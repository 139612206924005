import { StepDefinition } from '../../types';

export default {
  key: 'ashby_upload_resume',
  name: 'Upload Resume',
  description: 'Uploads a resume to a candidate in Ashby',
  icon: 'i-respell-ashby',
  category: 'integrations',
  services: ['ashby'],
  options: {
    candidate: {
      key: 'candidate',
      name: 'Candidate',
      type: 'text/plain',
      description: 'The candidate to which the resume should be uploaded to',
      isOptional: false,
      listDepth: 0,
      value: null,
      metadata: {
        options: {
          reference: {
            namespace: 'ashby',
            member: 'list_canididates',
          },
        },
      },
    },
    resume: {
      key: 'resume',
      name: 'Resume',
      isOptional: false,
      type: 'file/document',
      listDepth: 0,
      value: null,
    },
  },
  outputs: {},
} satisfies StepDefinition;
