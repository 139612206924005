import { StepDefinition } from '../../types';

export default {
  key: 'add_row',
  name: 'Add Row',
  description: 'Creates a row on a Google Sheets.',
  icon: 'i-respell-google',
  // documentationLink: 'https://docs.respell.ai/airtable',
  category: 'integrations',
  services: ['google-sheet'],
  options: {
    sheet_id: {
      key: 'sheet_id',
      name: 'Sheet',
      description:
        'The sheet to add a row to. Select the Sheet from Google Drive and we’ll load the columns in for you.',
      isOptional: false,
      type: 'text/plain',
      listDepth: 0,
      metadata: {
        options: {
          reference: {
            namespace: 'google-sheet',
            member: 'list_sheets',
          },
        },
      },
      value: null,
    },
    worksheet_id: {
      key: 'worksheet_id',
      name: 'Worksheet',
      description: 'The worksheet to insert into.',
      isOptional: false,
      type: 'text/plain',
      conditions: [
        {
          reference: 'sheet_id',
          type: 'not',
          value: null,
        },
      ],
      metadata: {
        options: {
          reference: {
            namespace: 'google-sheet',
            member: 'list_worksheets',
            arguments: [
              {
                namespace: 'options',
                member: 'sheet_id',
                parameter: 'sheetId',
              },
            ],
          },
        },
      },
      listDepth: 0,
      value: '0',
    },
    fields: {
      key: 'fields',
      name: 'Fields',
      description: 'The fields to insert into the table.',
      isOptional: false,
      type: 'object',
      listDepth: 0,
      conditions: [
        {
          reference: 'worksheet_id',
          type: 'not',
          value: null,
        },
        {
          reference: 'sheet_id',
          type: 'not',
          value: null,
        },
      ],
      metadata: {
        schema: {
          reference: {
            namespace: 'google-sheet',
            member: 'list_headers',
            arguments: [
              {
                namespace: 'options',
                member: 'sheet_id',
                parameter: 'sheetId',
              },
              {
                namespace: 'options',
                member: 'worksheet_id',
                parameter: 'worksheetId',
              },
            ],
          },
        },
      },
      value: {},
    },
  },
  outputs: {
    fields: {
      key: 'fields',
      name: 'New Row Details',
      description: 'Details of the newly created row.',
      isOptional: false,
      type: 'object',
      listDepth: 0,
      metadata: {
        schema: {
          reference: {
            namespace: 'google-sheet',
            member: 'list_headers',
            arguments: [
              {
                namespace: 'options',
                member: 'sheet_id',
                parameter: 'sheetId',
              },
              {
                namespace: 'options',
                member: 'worksheet_id',
                parameter: 'worksheetId',
              },
            ],
          },
        },
      },
      value: {},
    },
  },
} satisfies StepDefinition;
