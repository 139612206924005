import { TriggerDefinition } from '../../../definition';

export default {
  key: 'notion-page',
  name: 'New Page',
  description: 'A page is created in Notion.',
  icon: 'i-respell-notion',
  options: {},
  outputs: {
    pageContent: {
      key: 'pageContent',
      name: 'Page Content',
      type: 'text/plain',
      isOptional: false,
      listDepth: 0,
      metadata: {
        icon: 'fingerprint',
      },
      value: null,
    },
    id: {
      key: 'id',
      name: 'Page ID',
      type: 'text/plain',
      isOptional: false,
      listDepth: 0,
      metadata: {
        icon: 'fingerprint',
      },
      value: null,
    },
    url: {
      key: 'url',
      name: 'Page URL',
      type: 'text/url',
      isOptional: false,
      listDepth: 0,
      metadata: {
        icon: 'fingerprint',
      },
      value: null,
    },
  },
  example: {
    pageContent: `This page outlines our marketing campaigns and their corresponding results for May 2024. 
  ## Campaigns
  * Rocket Skate Hackathon: 1,848 impressions, 102 conversions
  * Giant Anvil Giveaway: 7,801 impressions, 214 conversions
  * Desert Meetup: 9,132 impressions, 23 conversions`,

    id: 'Marketing-Campaigns-May-2024-123c51d49cdc41u23a76043e5jz12680',

    url: 'https://www.notion.so/acme/Marketing-Campaigns-May-2024-123c51d49cdc41u23a76043e5jz12680',
  },
} satisfies TriggerDefinition;
