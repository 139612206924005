import { StepDefinition } from '../../types';

export default {
  key: 'generate_image',
  name: 'Generate Image',
  description: 'Generates an image from a prompt.',
  icon: 'i-respell-generate-image',
  documentationLink:
    'https://docs.respell.ai/steps-reference/file-tools/generate-image',
  category: 'file',
  options: {
    model: {
      key: 'model',
      name: 'Model',
      description: 'The base model you want to use.',
      isOptional: false,
      type: 'text/plain',
      listDepth: 0,
      metadata: {
        options: [
          {
            key: 'openai/dall-e-3',
            name: 'DALL·E 3',
            icon: 'i-ph-open-ai-logo-bold',
            tags: ['Realistic'],
          },
          // {
          //   key: 'replicate/huggingface/stable_diffusion',
          //   name: 'Stable Diffusion',
          //   icon: 'i-respell-replicate',
          // },
        ],
      },
      value: 'openai/dall-e-3',
    },
    prompt: {
      key: 'prompt',
      name: 'Prompt',
      description:
        'The prompt (image instruction) you want the AI to use to generate the image. Be as descriptive as you need to include the details you’re looking for.',
      isOptional: false,
      type: 'text/plain',
      listDepth: 0,
      metadata: {
        max: 128000,
        isMultiline: true,
        isPrompt: true,
      },
      value: null,
    },
    // temperature: {
    //   key: 'temperature',
    //   name: 'Temperature',
    //   description: 'How creative the AI should be with the image. Higher temperatures will make the AI more creative, but it may not follow the prompt entirely. Set temperature low for tasks where you want more consistent images.',
    //   isOptional: true,
    //   type: 'number/decimal',
    //   metadata: {
    //     min: 0.0,
    //     max: 2.0,
    //   },
    //   listDepth: 0,
    //   value: 1.0,
    // },
  },
  outputs: {
    generated_image: {
      key: 'generated_image',
      name: 'Generated Image',
      description: 'The image generated by the AI.',
      type: 'file/image',
      listDepth: 0,
      isOptional: false,
      value: null,
    },
  },
} satisfies StepDefinition;
