<script setup lang="ts">
import type { Variable } from '@respell/utils';
import { computed, ref } from 'vue';
import AppInput from '~/components/app/AppInput.vue';
import VariableNode from '~/components/app/VariableNode.vue';
import VariablePicker from '~/components/editor/config/VariablePicker.vue';

const modelValue = defineModel<any>();

const { option, hasContext, formKey } = defineProps({
  option: {
    type: Object as PropType<Variable>,
    required: true,
  },
  hasContext: {
    type: Boolean,
    default: false,
  },
  injectable: {
    type: Boolean,
    default: false,
  },
  size: {
    type: String,
    default: 'md',
  },
  formKey: {
    type: String,
    default: null,
  },
  readonly: {
    type: Boolean,
    default: false,
  },
});

const { emitFormChange } = useFormGroup();

watchDebounced(
  modelValue,
  () => {
    emitFormChange();
  },
  { debounce: 500, maxWait: 1000, deep: true },
);

const isVariable = computed(() => {
  if (typeof modelValue.value === 'string') {
    const matches = modelValue.value.match(variableRegex);
    return !!matches;
  }
  return false;
});

const inputValues = computed({
  get() {
    return isVariable.value
      ? []
      : Array.isArray(modelValue.value)
        ? modelValue.value
        : [modelValue.value];
  },
  set(value) {
    modelValue.value = value;
  },
});

const shallowerOption = computed(() => {
  return { ...option, listDepth: option.listDepth - 1 };
});

const uniqueKeys = ref(
  inputValues.value.map(() => Math.random().toString(36).substr(2, 9)),
);

function addInput() {
  inputValues.value = [
    ...inputValues.value,
    option.type === 'object' ? {} : null,
  ];
  uniqueKeys.value.push(Math.random().toString(36).substr(2, 9));
}

function removeInput(index: number) {
  inputValues.value.splice(index, 1);
  uniqueKeys.value.splice(index, 1);
}
</script>
<template>
  <div
    v-for="(key, index) in uniqueKeys"
    :key="key"
    class="flex w-full mb-m items-start flex-col gap-2"
  >
    <AppInput
      v-model="inputValues[index]"
      :option="shallowerOption"
      class="relative w-full"
      :index="index"
      :form-key="`${formKey ?? option.key}.${index}`"
      :has-context="hasContext"
      hide-label
      :injectable="injectable"
      :size="size"
      :readonly="readonly"
    />
    <UButton
      v-if="!readonly"
      variant="link"
      size="xs"
      :padded="false"
      icon="i-ph-trash-simple"
      color="gray"
      label="Remove"
      @click="removeInput(index)"
    />
  </div>

  <span v-if="!readonly" class="flex w-full gap-2 mt-m items-baseline">
    <UButton
      icon="i-ph-plus-bold"
      :disabled="isVariable"
      size="md"
      variant="ghost"
      class="border border-gray-300 border-dashed text-gray-400 shrink"
      label="Add Input"
      block
      :ui="{
        size: { lg: 'text-lg' },
        icon: { size: { lg: 'h-5 w-5' } },
      }"
      @click="addInput"
    />
    <VariableNode
      v-if="isVariable"
      v-model="modelValue"
      size="sm"
      class="shrink-0"
    />
    <VariablePicker
      v-else-if="injectable && !uniqueKeys.length"
      v-model="modelValue"
      :type="option.type"
      class="shrink-0 block"
    />
  </span>
</template>
