import { StepDefinition } from '../../types';

export default {
  key: 'add_note_greenhouse',
  name: 'Add Note',
  description: 'Adds a note to a candidate in Greenhouse.',
  icon: 'i-respell-greenhouse',
  category: 'integrations',
  services: ['greenhouse'],
  options: {
    candidateId: {
      key: 'candidateId',
      name: 'Candidate ID',
      description: 'The ID of the candidate you want to add a note to.',
      isOptional: false,
      type: 'number/decimal',
      listDepth: 0,
      metadata: {
        isSearchable: true,
        options: {
          reference: {
            namespace: 'greenhouse',
            member: 'list_canididates',
          },
        },
      },
      value: null,
    },
    visibility: {
      key: 'visibility',
      name: 'Visibility',
      description: 'The visibility of the note.',
      isOptional: false,
      type: 'text/plain',
      metadata: {
        options: [
          {
            key: 'private',
            name: 'Private',
          },
          {
            key: 'public',
            name: 'Public',
          },
          {
            key: 'admin_only',
            name: 'Admin Only',
          },
        ],
      },
      listDepth: 0,
      value: null,
    },
    note: {
      key: 'note',
      name: 'Note',
      description: 'The note you want to add to the candidate.',
      isOptional: false,
      type: 'text/plain',
      metadata: {
        isMultiline: true,
      },
      listDepth: 0,
      value: null,
    },
  },
  outputs: {},
} satisfies StepDefinition;
