import { StepDefinition } from '../../types';

export default {
  key: 'attio_update_person',
  name: 'Update person',
  description: 'Updates a person record in Attio',
  icon: 'i-respell-attio',
  category: 'integrations',
  services: ['attio'],
  options: {
    personId: {
      key: 'personId',
      name: 'Person ID',
      type: 'text/plain',
      isOptional: false,
      listDepth: 0,
      value: null,
    },
    person: {
      key: 'person',
      name: 'Person',
      type: 'object',
      metadata: {
        schema: {
          reference: {
            namespace: 'attio',
            member: 'attio_list_attributes_person',
          },
        },
      },
      isOptional: false,
      listDepth: 0,
      value: null,
    },
  },
  outputs: {},
} satisfies StepDefinition;
