import { DataSourceSearchResultModel } from '@respell/integrations/carbon/models';
import { StepDefinition } from '../../types';

export default {
  key: 'search_datasources',
  name: 'Search Workspace Files',
  description:
    'Searches your Data Sources for information. The agent will find relevant files to your query.',
  icon: 'i-respell-search-file',
  category: 'file',
  options: {
    query: {
      key: 'query',
      name: 'Query',
      listDepth: 0,
      description:
        'The information you want to research. This will be used to look for relevant/similar information, so you don’t need to search for the exact wording.',
      type: 'text/plain',
      isOptional: false,
      value: null,
    },
    limit: {
      type: 'number/integer',
      isOptional: true,
      description: 'The maximum amount of files to return.',
      name: 'Limit',
      listDepth: 0,
      value: 5,
      metadata: {
        min: 1,
        max: 50,
      },
      key: 'limit',
    },
    folderId: {
      key: 'folderId',
      name: 'Folder',
      listDepth: 0,
      description: 'Choose the folder to query from your Data Sources.',
      type: 'text/plain',
      metadata: {
        isSearchable: true,
        options: {
          reference: {
            member: 'get_folders',
            arguments: [
              {
                namespace: 'options',
                member: 'query',
                parameter: 'query',
              },
            ],
          },
        },
      },
      isOptional: false,
      value: 'all',
    },
  },
  outputs: {
    results: {
      key: 'results',
      name: 'Search Result',
      description: 'The document retrieved by the agent.',
      type: 'object',
      listDepth: 0,
      isOptional: false,
      metadata: {
        icon: 'i-respell-t-box',
        schema: DataSourceSearchResultModel.schema,
      },
      value: null,
    },
  },
} satisfies StepDefinition;
