import { StepDefinition } from '../../types';

export default {
  key: 'twilio_make_call',
  name: 'Make Phone Call',
  description:
    'Makes a phone call, waits "timeout" amount of seconds for ringing. When the call is picked up, speaks using the text in "body"',
  icon: 'i-logos-twilio-icon',
  category: 'integrations',
  services: ['twilio'],
  options: {
    from: {
      key: 'from',
      name: 'Sender',
      description: 'A phone number in the conversation',
      isOptional: false,
      type: 'text/phone-number',
      listDepth: 0,
      value: null,
    },
    to: {
      key: 'to',
      name: 'Recipient',
      description: 'Another phone number in the conversation',
      isOptional: false,
      type: 'text/phone-number',
      listDepth: 0,
      value: null,
    },
    body: {
      key: 'body',
      name: 'Body',
      description: 'Text for Twilio to speak when the user picks up the phone',
      isOptional: false,
      type: 'text/plain',
      listDepth: 0,
      value: null,
    },
    record: {
      key: 'record',
      name: 'Record',
      description: 'Whether or not the call should be recorded',
      isOptional: true,
      type: 'boolean',
      listDepth: 0,
      value: false,
    },
    timeout: {
      key: 'timeout',
      name: 'Timeout',
      description:
        'The integer number of seconds that we should allow the phone to ring before assuming there is no answer. The default is `60` seconds and the maximum is `600` seconds.',
      isOptional: true,
      type: 'number/integer',
      listDepth: 0,
      value: 60,
    },
  },
  outputs: {
    result: {
      key: 'result',
      name: 'Result',
      description: 'The metadata of the phone call',
      isOptional: false,
      type: 'object',
      listDepth: 0,
      value: null,
    },
  },
} satisfies StepDefinition;
